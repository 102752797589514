import { FC, FormEventHandler, useState } from 'react'
import cls from './index.module.scss'
import { TClassName } from '@/shared/types/shared'
import classNames from 'classnames'
import { Button } from '../Button/Button'
import { CodeVerificationInput } from '@/shared/ui/VerificationInput'
import { UiModal } from '@/shared/ui/Modal'
import { REGISTRATION_CONFIRMATION_MODAL } from '@/shared/constants'
import { useAuthControllerApproveSignUpMutation } from '@/shared/store/api/openApi'
import { useAppDispatch, useAppSelector } from '@/shared/hooks/redux-ts'
import { changeTokens } from '@/shared/store/slices/authSlice.persist'
import { hideModal } from '@/shared/store/slices/modalsSlice'
import { Link, useNavigate } from 'react-router-dom'
import { changeRegistrationUUID } from '@/shared/store/slices/authSlice'

interface Props extends TClassName {}

const RegistrationConfirmationModal: FC<Props> = ({ className }) => {
    const verifyUuid = useAppSelector((state) => state.auth.registrationUUID)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [verifyCode, setVerifyCode] = useState<string>('')
    const [formError, setFormError] = useState<string>('')
    const [approve] = useAuthControllerApproveSignUpMutation()

    const handleFormSubmit: FormEventHandler = async (e) => {
        e.preventDefault()

        if (verifyUuid) {
            const { data } = await approve({
                signUpApproveRequest: { verifyUuid, code: verifyCode },
            })

            if (data) {
                const { accessToken, refreshToken } = data
                dispatch(changeTokens({ accessToken, refreshToken }))
                dispatch(changeRegistrationUUID(''))
                dispatch(hideModal({ slug: REGISTRATION_CONFIRMATION_MODAL }))
                navigate('/', { replace: true })
            } else {
                setVerifyCode('')
                //@ts-expect-error: Не типизированный ответ ошибки
                setFormError(error?.data?.error || 'Непредвиденная ошибка')
            }
        } else {
            setVerifyCode('')
            setFormError('Ошибка сессии. Попробуйте позже')
        }
    }
    const CODE_LENGTH = 6

    return (
        <UiModal slug={REGISTRATION_CONFIRMATION_MODAL}>
            <form
                className={classNames(cls.wrapper, className)}
                onSubmit={handleFormSubmit}
            >
                <h2 className={cls.title}>Введите код</h2>
                <h3 className={cls.subtitle}>
                    Введите шестизначный код, отправленный на Вашу почту
                    <Link to="#">california@gta5rp.ru</Link>
                </h3>
                <h3 className={cls.subtitle}>
                    Если код не был получен, обратитесь пожалуйста в тех.раздел
                    на форуме <span>califronia-rp.ru/techrazdel</span>
                </h3>
                <div className={cls.content}>
                    <CodeVerificationInput
                        length={CODE_LENGTH}
                        value={verifyCode}
                        actions={{
                            onChange: (value) => {
                                setVerifyCode(value)
                            },
                        }}
                    />

                    {formError ? (
                        <p className={cls.formErrorMessage}>{formError}</p>
                    ) : null}
                    <Button
                        disabled={verifyCode.length !== CODE_LENGTH}
                        value="Подтвердить"
                        type="submit"
                        className={cls.button}
                    />
                </div>
            </form>
        </UiModal>
    )
}

export { RegistrationConfirmationModal }
