import styles from './HudInfo.module.scss'
import { HudInfoProps } from './HudInfo.typings'
import moment from 'moment'
import cn from 'classnames'

export const HudInfo = ({
    date,
    pos: { street, zone },
    money,
}: HudInfoProps) => {
    const time = moment(date).format('h:mm')
    const amPm = moment(date).format('A')

    const isLongTimeLength = (value: string) => {
        if (value.length === 5) return true
        else return false
    }

    return (
        <div className={styles.container}>
            <div>
                <div
                    className={cn(
                        styles.timeTitle,
                        isLongTimeLength(time)
                            ? styles.timeTitleLong
                            : styles.timeTitleShort
                    )}
                >
                    <div>{time}</div> <div className={styles.amPm}>{amPm}</div>
                </div>
                <div className={styles.subtitle}>
                    {moment(date).format('MM.DD.YYYY')}
                </div>
            </div>
            <div className={styles.line}/>
            <div>
                <div className={styles.title}>{zone}</div>
                <div className={styles.subtitle}>{street}</div>
            </div>
            <div className={styles.line}/>
            <div className={styles.title}>$ {money}</div>
        </div>
    )
}
