import { useState, useEffect } from 'react'
import styles from '../../PoliceRadar.module.scss'
import { Props } from './PatrolCarSpeedWindow.typings'
import cn from 'classnames'

const PatrolCarSpeedWindow = (props: Props) => {
    const [displayValue, setDisplayValue] = useState<string>('')
    const handleDisplayValue = () => {
        if (props.isTurningOn) {
            setDisplayValue('888')
        } else if (props.PWRon && props.radarData) {
            setDisplayValue(String(props.radarData))
        } else {
            setDisplayValue(props.contextParams)
        }
    }

    useEffect(() => {
        handleDisplayValue()
    }, [props])

    return (
        <div className={styles.speeds_container}>
            <div className={cn(styles.display, styles.fast)}>
                <p className={styles.patrol_ghost_speed}>888</p>
                <p className={styles.patrol_speed}>{displayValue}</p>
            </div>
        </div>
    )
}

export default PatrolCarSpeedWindow
