import { useRef, useEffect } from 'react'
import styles from './PlateReaderFrame.module.scss'
import { Props } from './PlateReaderFrame.typings'
import { moveAt } from '../../utils/moveAt'
import picture0 from '@/shared/assets/images/illustrations/Hud/PoliceRadar/plates/0.webp'
import cn from 'classnames'

const PlateReaderFrame = (props: Props) => {
    const plateReaderFrameRef = useRef<null | HTMLDivElement>(null)
    const scale = Number(localStorage.getItem('readerScale'))
    const wrapperRects = { x: 0, y: 0 }

    useEffect(() => {
        if (props.wrapperRef) {
            if (props.wrapperRef.current) {
                wrapperRects.x = props.wrapperRef.current.offsetWidth
                wrapperRects.y = props.wrapperRef.current.offsetHeight
            }
        }
    })

    useEffect(() => {
        const plateReaderFrame = plateReaderFrameRef.current
        if (plateReaderFrame) {
            const rectX = localStorage.getItem('plateReaderFrameRectX')
            const rectY = localStorage.getItem('plateReaderFrameRectY')
            if (rectX) {
                plateReaderFrame.style.left = rectX
            }
            if (rectY) {
                plateReaderFrame.style.top = rectY
            }
            plateReaderFrame.onmousedown = (event) => {
                const shiftX =
                    event.clientX -
                    plateReaderFrame.getBoundingClientRect().left
                const shiftY =
                    event.clientY - plateReaderFrame.getBoundingClientRect().top
                plateReaderFrame.style.zIndex = '100'

                const onMouseMove = (event: MouseEvent) => {
                    moveAt(
                        event.pageX,
                        event.pageY,
                        shiftX,
                        shiftY,
                        'plateReaderFrameRectX',
                        'plateReaderFrameRectY',
                        wrapperRects,
                        plateReaderFrameRef,
                        props.padding,
                        scale
                    )
                }

                moveAt(
                    event.pageX,
                    event.pageY,
                    shiftX,
                    shiftY,
                    'plateReaderFrameRectX',
                    'plateReaderFrameRectY',
                    wrapperRects,
                    plateReaderFrameRef,
                    props.padding,
                    scale
                )

                props.wrapperRef.current?.addEventListener(
                    'mousemove',
                    onMouseMove
                )

                window.onmouseup = () => {
                    props.wrapperRef.current?.removeEventListener(
                        'mousemove',
                        onMouseMove
                    )
                    plateReaderFrame.style.zIndex = '10'
                    window.onmouseup = null
                }
            }
            plateReaderFrame.ondragstart = () => false
        }
    }, [props.padding])

    return (
        <div
            ref={plateReaderFrameRef}
            style={{ transform: `scale(${props.plateReaderScale})` }}
            className={styles.plateReaderFrame}
        >
            <div className={styles.frame_border} />
            <div className={styles.plateReader}>
                <div className={styles.labels}>
                    <p className={styles.title}>FRONT</p>
                    <p className={styles.title}>REAR</p>
                </div>
                <div className={styles.plates}>
                    <div className={styles.plate_container}>
                        <img className={styles.plate} src={picture0} />
                        <div className={styles.text_container}>
                            <p className={styles.plate_blue}>
                                {props.lockFrontPlate || props.frontPlate}
                            </p>
                            <p className={styles.hilite}>
                                {props.lockFrontPlate || props.frontPlate}
                            </p>
                            <p className={styles.lolite}>
                                {props.lockFrontPlate || props.frontPlate}
                            </p>
                            <p className={styles.shadow}>
                                {props.lockFrontPlate || props.frontPlate}
                            </p>
                        </div>
                    </div>
                    <div className={styles.plate_container}>
                        <img className={styles.plate} src={picture0} />
                        <div className={styles.text_container}>
                            <p className={styles.plate_blue}>
                                {props.rearPlate || props.rearPlate}
                            </p>
                            <p className={styles.hilite}>
                                {props.rearPlate || props.rearPlate}
                            </p>
                            <p className={styles.lolite}>
                                {props.rearPlate || props.rearPlate}
                            </p>
                            <p className={styles.shadow}>
                                {props.rearPlate || props.rearPlate}
                            </p>
                        </div>
                    </div>
                </div>
                <div className={styles.labels}>
                    <p className={cn(props.lockFrontPlate && styles.active)}>
                        LOCKED
                    </p>
                    <p className={cn(props.lockRearPlate && styles.active)}>
                        LOCKED
                    </p>
                </div>
            </div>
        </div>
    )
}

export default PlateReaderFrame
