import headIcon from '@/shared/assets/images/vectors/TattooShop/_/headIcon.svg'
import backIcon from '@/shared/assets/images/vectors/TattooShop/_/backIcon.svg'
import bodyIcon from '@/shared/assets/images/vectors/TattooShop/_/bodyIcon.svg'
import leftArmIcon from '@/shared/assets/images/vectors/TattooShop/_/leftArmIcon.svg'
import rightArmIcon from '@/shared/assets/images/vectors/TattooShop/_/rightArmIcon.svg'
import leftLegIcon from '@/shared/assets/images/vectors/TattooShop/_/leftLegIcon.svg'
import rightLegIcon from '@/shared/assets/images/vectors/TattooShop/_/rightLegIcon.svg'
import tattooImage from '@/shared/assets/images/illustrations/TattooShop/_/tattooImage.webp'
import hamburgerPic from '@/shared/assets/images/illustrations/shared/hamburgerPic.webp'

export const previewTattooShop = {
    categories: [
        {
            key: 'head',
            title: 'Голова',
            icon: headIcon,
        },
        {
            key: 'back',
            title: 'Спина',
            icon: backIcon,
        },
        {
            key: 'body',
            title: 'Перед',
            icon: bodyIcon,
        },
        {
            key: 'leftArm',
            title: 'Левая рука',
            icon: leftArmIcon,
        },
        {
            key: 'rightArm',
            title: 'Правая рука',
            icon: rightArmIcon,
        },
        {
            key: 'leftLeg',
            title: 'Левая нога',
            icon: leftLegIcon,
        },
        {
            key: 'rightLeg',
            title: 'Правая нога',
            icon: rightLegIcon,
        },
    ],
    head: [
        {
            id: '1',
            price: 20000,
            image: tattooImage,
            description: 'Lorem Ipsum is simply dummy text of the',
            sale: { isSale: true, oldPrice: 54000 },
            color: '2',
        },
        {
            id: '2',
            price: 54000,
            image: tattooImage,
            description: 'Lorem Ipsum is simply dummy text of the',
            sale: { isSale: false },
            color: '2',
            unavailable: true,
        },
        {
            id: '3',
            price: 4000,
            image: tattooImage,
            description: 'Lorem Ipsum is simply dummy text of the',
            sale: { isSale: false },
            color: 'red',
            special: true,
        },
        {
            id: '4',
            price: 34000,
            image: tattooImage,
            description: 'Lorem Ipsum is simply dummy text of the',
            sale: { isSale: false },
            color: '2',
        },
        {
            id: '5',
            price: 54000,
            image: tattooImage,
            description: 'Lorem Ipsum is simply dummy text of the',
            sale: { isSale: false },
            color: '2',
            bought: true,
        },
        {
            id: '6',
            price: 54000,
            image: tattooImage,
            description: 'Lorem Ipsum is simply dummy text of the',
            sale: { isSale: false },
            color: '2',
        },
        {
            id: '7',
            price: 54000,
            image: tattooImage,
            description: 'test for search',
            sale: { isSale: false },
            color: '2',
        },
        {
            id: '8',
            price: 54000,
            image: tattooImage,
            description: 'Lorem Ipsum is simply dummy text of the',
            sale: { isSale: false },
            color: '2',
        },
        {
            id: '9',
            price: 54000,
            image: tattooImage,
            description: 'Lorem Ipsum is simply dummy text of the',
            sale: { isSale: false },
            color: '2',
        },
    ],
    back: [
        {
            id: '1',
            price: 20000,
            image: hamburgerPic,
            description: 'Lorem Ipsum is simply dummy text of the',
            sale: { isSale: true, oldPrice: 54000 },
            color: '2',
        },
        {
            id: '2',
            price: 54000,
            image: hamburgerPic,
            description: 'Lorem Ipsum is simply dummy text of the',
            sale: { isSale: false },
            color: '2',
        },
        {
            id: '3',
            price: 54000,
            image: hamburgerPic,
            description: 'Lorem Ipsum is simply dummy text of the',
            sale: { isSale: false },
            color: 'red',
        },
        {
            id: '4',
            price: 34000,
            image: hamburgerPic,
            description: 'Lorem Ipsum is simply dummy text of the',
            sale: { isSale: false },
            color: '2',
        },
        {
            id: '5',
            price: 54000,
            image: hamburgerPic,
            description: 'Lorem Ipsum is simply dummy text of the',
            sale: { isSale: false },
            color: '2',
        },
        {
            id: '6',
            price: 54000,
            image: hamburgerPic,
            description: 'Lorem Ipsum is simply dummy text of the',
            sale: { isSale: false },
            color: '2',
        },
        {
            id: '7',
            price: 54000,
            image: hamburgerPic,
            description: 'test for search',
            sale: { isSale: false },
            color: '2',
        },
        {
            id: '8',
            price: 54000,
            image: hamburgerPic,
            description: 'Lorem Ipsum is simply dummy text of the',
            sale: { isSale: false },
            color: '2',
        },
        {
            id: '9',
            price: 54000,
            image: hamburgerPic,
            description: 'Lorem Ipsum is simply dummy text of the',
            sale: { isSale: false },
            color: '2',
        },
    ],
    storeType: 'tattooShop',
    select: [
        { key: 'fromLowerToUpper', title: 'От дешевых к дорогим' },
        { key: 'fromUpperToLower', title: 'От дорогих к дешевым' },
    ],
    playerBalance: 1000000,
}
