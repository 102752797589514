import { MenuItemVariants } from './PoliceRadar.typings'
import away from '@/shared/assets/sounds/PoliceRadar/away.mp3'
import beep from '@/shared/assets/sounds/PoliceRadar/beep.mp3'
import closing from '@/shared/assets/sounds/PoliceRadar/closing.mp3'
import done from '@/shared/assets/sounds/PoliceRadar/done.mp3'
import front from '@/shared/assets/sounds/PoliceRadar/front.mp3'
import plateHit from '@/shared/assets/sounds/PoliceRadar/plate_hit.mp3'
import rear from '@/shared/assets/sounds/PoliceRadar/rear.mp3'
import speedAlert from '@/shared/assets/sounds/PoliceRadar/speed_alert.mp3'
import xMitOff from '@/shared/assets/sounds/PoliceRadar/xmit_off.mp3'
import xMitOn from '@/shared/assets/sounds/PoliceRadar/xmit_on.mp3'

const fastSpeedLockLimitPresets = []

for (let i = 5; i <= 200; i += 5) {
    fastSpeedLockLimitPresets.push(String(i))
}

export const menuItemVariants: MenuItemVariants = {
    fasterSpeedDisplay: ['OFF', 'On'],
    sameLaneSensitivityAdjustment: ['1', '2', '3', '4', '5'],
    oppositeLaneSensitivityAdjustment: ['1', '2', '3', '4', '5'],
    beepAudioVolume: ['1', '2', '3', '4', '5', 'OFF'],
    voiceEnunciatorVolume: ['1', '2', '3', '4', '5'],
    plateReaderVolume: ['1', '2', '3', '4', '5'],
    unitOfMeasurement: ['USA', 'INT'],
    fastSpeedLock: ['OFF', 'On'],
    fastSpeedLockLimit: fastSpeedLockLimitPresets,
}

export const audioLibrary = [
    away,
    beep,
    closing,
    done,
    front,
    plateHit,
    rear,
    speedAlert,
    xMitOff,
    xMitOn,
]

export enum Position {
    top,
    bottom,
}
