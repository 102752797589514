import { FC, useMemo } from 'react'
import { MessagesProps } from '../api/types'
import stylesBase from './Message.module.scss'
import cn from 'classnames'
import { getFormatName } from '@/shared/lib/getFormatMessageName'
import { useAppSelector } from '@/shared/hooks/redux-ts'
import { friendsSelector } from '@/shared/store/slices/friendsSlice'
import errorIcon from '@/shared/assets/images/vectors/Hud/Chat/errorIcon.svg'

const Message: FC<MessagesProps> = (props: MessagesProps) => {
    const friends = useAppSelector(friendsSelector)
    const name = friends.find(
        ({ staticId }: any) => staticId === props.staticId
    )?.name

    const toDoText = useMemo(() => {
        if (props.type.toUpperCase() === 'TODO') {
            const unformattedToDo = props.text.split('*')
            return {
                first: unformattedToDo[0] || '',
                second: unformattedToDo[1] || '',
            }
        }
        return { first: '', second: '' }
    }, [props.text, props.type])

    const getTextStyle = (type: string) => {
        switch (type) {
            case 'RP': {
                return stylesBase.rpText
            }
            case 'ME': {
                return stylesBase.meText
            }
            case 'DO': {
                return stylesBase.do
            }
            case 'TODO': {
                return stylesBase.toDoText
            }
            case 'B': {
                return stylesBase.b
            }
            case 'S': {
                return stylesBase.s
            }
            case 'W': {
                return stylesBase.w
            }
            case 'ERROR': {
                return stylesBase.error
            }
            default:
                break
        }
    }

    const getNameStyle = (type: string) => {
        switch (type) {
            case 'RP': {
                return stylesBase.rpName
                break
            }
            case 'ME': {
                return stylesBase.meName
                break
            }
            case 'DO': {
                return stylesBase.do
                break
            }
            case 'TODO': {
                return stylesBase.toDoName
                break
            }
            case 'B': {
                return stylesBase.b
                break
            }
            case 'S': {
                return stylesBase.s
                break
            }
            case 'W': {
                return stylesBase.w
                break
            }
            default:
                break
        }
    }

    const formatMessage = (
        text: string,
        type: string,
        staticId: string,
        gender: string
    ) => {
        return (
            <div className={stylesBase.message}>
                {type !== 'DO'
                    ? type !== 'TODO' &&
                      type !== 'ERROR' && (
                          <span
                              className={cn(getNameStyle(type.toUpperCase()))}
                          >
                              {getFormatName(name, gender)}&nbsp;
                          </span>
                      )
                    : null}
                {type !== 'DO' ? (
                    type !== 'TODO' && type !== 'ERROR' ? (
                        <span className={cn(getNameStyle(type.toUpperCase()))}>
                            #{staticId}&nbsp;
                        </span>
                    ) : null
                ) : null}
                {type === 'ME' ? (
                    <span className={cn(getNameStyle(type.toUpperCase()))}>
                        :&nbsp;
                    </span>
                ) : null}
                {type === 'RP' ? (
                    <span className={cn(getNameStyle(type.toUpperCase()))}>
                        говорит:&nbsp;
                    </span>
                ) : null}
                {type === 'S' ? (
                    <span className={cn(getNameStyle(type.toUpperCase()))}>
                        кричит:&nbsp;
                    </span>
                ) : null}
                {type === 'W' ? (
                    <span className={cn(getNameStyle(type.toUpperCase()))}>
                        шепчет:&nbsp;
                    </span>
                ) : null}
                {type === 'TODO' ? (
                    <span className={stylesBase.toDoText}>
                        {toDoText.first} -
                    </span>
                ) : null}
                <span className={cn(getTextStyle(type.toUpperCase()))}>
                    {type === 'B' ? '(( ' : null}
                    {type !== 'TODO'
                        ? type !== 'ERROR'
                            ? `${text}`
                            : null
                        : null}
                    {type === 'B' ? ' ))' : null}
                </span>
                {type === 'TODO' ? (
                    <span className={cn(getNameStyle(type.toUpperCase()))}>
                        &nbsp; сказал &nbsp;{getFormatName(name, gender)}&nbsp;
                    </span>
                ) : null}
                {type === 'TODO' ? (
                    <span className={cn(getNameStyle(type.toUpperCase()))}>
                        #{staticId}, &nbsp;
                    </span>
                ) : null}
                {type === 'TODO' ? (
                    <span className={cn(getNameStyle(type.toUpperCase()))}>
                        {toDoText.second}
                    </span>
                ) : null}
                {type === 'DO' ? (
                    <span className={cn(getNameStyle(type.toUpperCase()))}>
                        &nbsp;{getFormatName(name, gender)}&nbsp;
                    </span>
                ) : null}
                {type === 'DO' ? (
                    <>
                        &nbsp;
                        <span className={cn(getNameStyle(type.toUpperCase()))}>
                            #{staticId}&nbsp;
                        </span>
                    </>
                ) : null}
                {type === 'ERROR' ? (
                    <div className={stylesBase.errorMessage}>
                        <img
                            src={errorIcon}
                            alt="error icon"
                            className={stylesBase.errorIcon}
                        />
                        <span className={cn(getTextStyle(type.toUpperCase()))}>
                            {text}
                        </span>
                    </div>
                ) : null}
            </div>
        )
    }
    return (
        <span className={stylesBase.message}>
            {formatMessage(
                props.text,
                props.type.toLocaleUpperCase(),
                props.staticId,
                props.gender
            )}
        </span>
    )
}

export default Message
