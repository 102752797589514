import { ChangeEventHandler, FC } from 'react'
import cls from './index.module.scss'
import { TClassName } from '@/shared/types/shared'
import { CharacterPropertyContent } from '@/widgets/CharacterPropertyContent'
import classNames from 'classnames'
import { SliderInput } from '@/shared/ui/SliderInput'
import { useAppDispatch, useAppSelector } from '@/shared/hooks/redux-ts'
import { pxToRem } from '@/shared/lib/pxToRem'
import { changeFullLips } from '@/shared/store/slices/personalization/head/lipsSlice'

interface Props extends TClassName {}
const CharacterLipsContent: FC<Props> = ({ className }) => {
    const dispatch = useAppDispatch()
    const fullLips = useAppSelector(
        (state) => state.personalization.head.lips.fullLips
    )
    const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        dispatch(changeFullLips(+event.target.value / 100))
    }

    return (
        <CharacterPropertyContent
            items={[
                {
                    content: (
                        <SliderInput
                            thumbSize={pxToRem(20)}
                            min={0}
                            max={100}
                            step={10}
                            defaultValue={fullLips * 100}
                            onChange={handleChange}
                        />
                    ),
                    title: 'Толщина губ',
                },
            ]}
            title="Губы"
            className={classNames(cls.wrapper, className)}
        />
    )
}

export { CharacterLipsContent }
