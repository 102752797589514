import { ChangeEventHandler, FC } from 'react'
import cls from './index.module.scss'
import { TClassName } from '@/shared/types/shared'
import { CharacterPropertyContent } from '@/widgets/CharacterPropertyContent'
import classNames from 'classnames'
import { SliderInput } from '@/shared/ui/SliderInput'
import { useAppDispatch, useAppSelector } from '@/shared/hooks/redux-ts'
import { pxToRem } from '@/shared/lib/pxToRem'
import {
    changeCheekbonesHeight,
    changeCheekbonesWidth,
} from '@/shared/store/slices/personalization/head/cheekbonesSlice'

interface Props extends TClassName {}
const CharacterCheekbonesContent: FC<Props> = ({ className }) => {
    const dispatch = useAppDispatch()
    const cheekboneHeight = useAppSelector(
        (state) => state.personalization.head.cheekbones.cheekboneHeight
    )
    const cheekboneWidth = useAppSelector(
        (state) => state.personalization.head.cheekbones.cheekboneWidth
    )
    const handleCheekboneHeight: ChangeEventHandler<HTMLInputElement> = (
        event
    ) => {
        dispatch(changeCheekbonesHeight(+event.target.value / 100))
    }
    const handleCheekboneWidth: ChangeEventHandler<HTMLInputElement> = (
        event
    ) => {
        dispatch(changeCheekbonesWidth(+event.target.value / 100))
    }

    return (
        <CharacterPropertyContent
            items={[
                {
                    content: (
                        <SliderInput
                            thumbSize={pxToRem(20)}
                            min={0}
                            max={100}
                            step={10}
                            defaultValue={cheekboneHeight * 100}
                            onChange={handleCheekboneHeight}
                        />
                    ),
                    title: 'Высота скул',
                },
                {
                    content: (
                        <SliderInput
                            thumbSize={pxToRem(20)}
                            min={0}
                            max={100}
                            step={10}
                            defaultValue={cheekboneWidth * 100}
                            onChange={handleCheekboneWidth}
                        />
                    ),
                    title: 'Ширина скул',
                },
            ]}
            title="Скулы"
            className={classNames(cls.wrapper, className)}
        />
    )
}

export { CharacterCheekbonesContent }
