import { FC } from 'react'
import { FamilyRequestsTableProps } from './FamilyRequestsTable.typings'
import styles from './FamilyRequestsTable.module.scss'
import CheckBox from '@/widgets/CheckBox/CheckBox'

const FamilyRequestsTable: FC<FamilyRequestsTableProps> = ({
    familyMembers,
}) => {
    return (
        <div className={styles.container}>
            {familyMembers.map((member, index) => (
                <div key={index} className={styles.member}>
                    <div className={styles.fullName}>{member.fullName}</div>
                    <div className={styles.age}>{member.age}</div>
                    <div className={styles.role}>{member.role}</div>
                    <div className={styles.checkBox}>
                        <CheckBox
                            onClick={(): void => {
                                console.log('die')
                            }}
                        />{' '}
                    </div>
                </div>
            ))}
        </div>
    )
}

export default FamilyRequestsTable
