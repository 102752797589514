import React, { ChangeEvent, FC } from 'react'
import styles from './PlayerInfoForm.module.scss'
import Select from '@/shared/ui/Input/Select/Select'
import { InputButton } from '@/shared/ui/InputButton/InputButton'
import { Cubes } from '@/shared/icons/generated'
import classNames from 'classnames'
import Field from '@/shared/ui/Input/Field/Field'
import { GenderSelect } from '@/widgets/GenderSelect/GenderSelect'
import {
    weightRange,
    heightRange,
    monthArr,
    daysArr,
    nationalities,
} from './PlayerInfoForm.config'
import { OptionsType } from '@/shared/ui/Input/Select/Select.typings'
import GaitSelect from '@/pages/CharacterPersonalization/screens/About/components/GaitSelect/GaitSelect'
import { gaitVariants } from './PlayerInfoForm.config'
import LifeGoalWidget from '@/pages/CharacterPersonalization/screens/About/components/LifeGoalWidget/LifeGoalWidget'
import { getRandomFullName } from '@/pages/CharacterPersonalization/screens/About/utils/getRandomFullName'
import { createRange } from './utils/createRange'
import { useAppDispatch, useAppSelector } from '@/shared/hooks/redux-ts'
import {
    changeAboutSettings,
    EnGender,
    randomName,
} from '@/shared/store/slices/personalization/baseSlice'

interface Props {}
export const PlayerInfoForm: FC<Props> = () => {
    const dispatch = useAppDispatch()
    const aboutSettings = useAppSelector(
        (state) => state.personalization.base.aboutSettings
    )
    const nationalityArr =
        aboutSettings.gender === EnGender.male
            ? Array.from(nationalities, (item) => item.titles.male)
            : Array.from(nationalities, (item) => item.titles.female)

    const handleSelectDayOfBirth = (value: string) => {
        if (aboutSettings.monthOfBirth === 'Февраль') {
            if (+value <= 28)
                dispatch(changeAboutSettings({ key: 'dayOfBirth', value }))
        } else if (
            aboutSettings.monthOfBirth === 'Апрель' ||
            aboutSettings.monthOfBirth === 'Июнь' ||
            aboutSettings.monthOfBirth === 'Сентябрь' ||
            aboutSettings.monthOfBirth === 'Ноябрь'
        ) {
            if (+value <= 30)
                dispatch(changeAboutSettings({ key: 'dayOfBirth', value }))
        } else dispatch(changeAboutSettings({ key: 'dayOfBirth', value }))
    }

    const handleSelectMonthOfBirth = (value: string) => {
        dispatch(changeAboutSettings({ key: 'monthOfBirth', value }))
    }

    const handleFirstNameInput = (value: string) => {
        dispatch(
            changeAboutSettings({
                key: 'firstName',
                value:
                    value.length > 15
                        ? (value = value.substring(0, 14))
                        : value,
            })
        )
    }

    const handleLastNameInput = (value: string) => {
        dispatch(
            changeAboutSettings({
                key: 'lastName',
                value:
                    value.length > 15
                        ? (value = value.substring(0, 14))
                        : value,
            })
        )
    }

    const handleGenderSelect = (gender: EnGender) => {
        dispatch(changeAboutSettings({ key: 'gender', value: gender }))
    }

    const weight: OptionsType[] = createRange(weightRange, 1)
    const height: OptionsType[] = createRange(heightRange, 0.1)

    const handleSelectWeight = (value: number) => {
        dispatch(changeAboutSettings({ key: 'weight', value }))
    }

    const handleSelectedHeight = (value: number) => {
        dispatch(changeAboutSettings({ key: 'height', value }))
    }

    const handleGaitSelect = (id: number) => {
        const selectedGait = gaitVariants.find((item) => item.id === id)
        selectedGait &&
            dispatch(changeAboutSettings({ key: 'gait', value: selectedGait }))
    }

    const handleAgeSelect = (event: ChangeEvent<HTMLInputElement>) => {
        const input = event.target.value.replace(/[^\d]/g, '')
        if (input.length < 2)
            dispatch(changeAboutSettings({ key: 'age', value: input }))
        else {
            if (input.length > 0 && +input >= 18 && +input <= 65)
                dispatch(changeAboutSettings({ key: 'age', value: input }))
        }
    }

    const handleNationalitySelect = (value: string) => {
        dispatch(changeAboutSettings({ key: 'nationality', value }))
    }

    const getName = () => {
        const fullName = getRandomFullName(
            aboutSettings.gender,
            aboutSettings.nationality
        )
        dispatch(
            randomName({
                firstName: fullName.firstName,
                lastName: fullName.lastName,
            })
        )
    }

    return (
        <div className={styles.container}>
            <div className={styles.block}>
                <div className={classNames(styles.column, styles.nameBlock)}>
                    <div className={styles.title}>Имя</div>
                    <Field
                        onChange={(value) => {
                            handleFirstNameInput(value)
                        }}
                        value={aboutSettings.firstName}
                        placeholder="Имя (на английском)"
                    />
                </div>
                <div className={styles.column}>
                    <div className={styles.title}>Фамилия</div>
                    <div className={styles.row}>
                        <div className={styles.name}>
                            <Field
                                onChange={(value) => {
                                    handleLastNameInput(value)
                                }}
                                value={aboutSettings.lastName}
                                placeholder="Фамилия (на английском)"
                            />
                        </div>
                        <InputButton Icon={Cubes} onClick={getName} />
                    </div>
                </div>
            </div>
            <div className={styles.block}>
                <GenderSelect
                    value={aboutSettings.gender}
                    onSelect={handleGenderSelect}
                />
                <div>
                    <GaitSelect
                        selectedGait={aboutSettings.gait}
                        handleGaitSelect={handleGaitSelect}
                    />
                </div>
                <div className={styles.column}>
                    <div className={styles.title}>Антропометрия</div>
                    <div className={styles.row}>
                        <div>
                            <Select
                                options={weight}
                                onChange={handleSelectWeight}
                                placeholder="Вес"
                                value={aboutSettings.weight}
                            />
                        </div>
                        <div>
                            <Select
                                options={height}
                                placeholder="Рост"
                                onChange={handleSelectedHeight}
                                value={aboutSettings.height}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.block}>
                <div className={classNames(styles.column, styles.ageBlock)}>
                    <div className={styles.title}>Возраст</div>
                    <input
                        type="text"
                        placeholder="Возраст"
                        className={styles.age}
                        maxLength={2}
                        onChange={handleAgeSelect}
                        value={aboutSettings.age}
                    />
                </div>
                <div className={styles.column}>
                    <div className={styles.title}>Дата рождения</div>
                    <div className={styles.row}>
                        <Select
                            options={monthArr.map((item) => {
                                return { label: item, value: item }
                            })}
                            placeholder="Месяц"
                            onChange={handleSelectMonthOfBirth}
                            value={aboutSettings.monthOfBirth}
                            className={styles.month}
                        />
                        <Select
                            options={daysArr.map((item) => {
                                return {
                                    label: String(item),
                                    value: String(item),
                                }
                            })}
                            placeholder="День"
                            onChange={handleSelectDayOfBirth}
                            value={aboutSettings.dayOfBirth}
                            className={styles.day}
                        />
                    </div>
                </div>
                <div className={styles.column}>
                    <div className={styles.title}>Национальность</div>
                    <div className={styles.nationalityBlock}>
                        <Select
                            options={nationalityArr.map((item) => {
                                return { label: item, value: item }
                            })}
                            placeholder="Национальность"
                            onChange={handleNationalitySelect}
                            value={aboutSettings.nationality}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.block}>
                <div className={styles.column}>
                    <div className={styles.title}>Место рождения</div>
                    <div className={classNames(styles.row, styles.birthPlace)}>
                        <div className={styles.fullFlex}>
                            <Field
                                onChange={(value) => {
                                    dispatch(
                                        changeAboutSettings({
                                            key: 'country',
                                            value,
                                        })
                                    )
                                }}
                                value={aboutSettings.country}
                                placeholder="Страна (на английском)"
                            />
                        </div>
                        <div className={styles.fullFlex}>
                            <Field
                                onChange={(value) => {
                                    dispatch(
                                        changeAboutSettings({
                                            key: 'city',
                                            value,
                                        })
                                    )
                                }}
                                value={aboutSettings.city}
                                placeholder="Город (на английском)"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <LifeGoalWidget isTitle={true} />
        </div>
    )
}
