import { useState, useEffect } from 'react'
import styles from './HudPenaltyWidget.module.scss'
import { useHudPenaltyWidgetData } from './model/useHudPenaltyWidgetData'
import cn from 'classnames'

const HudPenaltyWidget = () => {
    const rpcData = useHudPenaltyWidgetData()

    const [time, setTime] = useState<Date>(rpcData.penaltyTime)

    useEffect(() => {
        setTime(rpcData.penaltyTime)
    }, [rpcData])

    useEffect(() => {
        const timerId = setInterval(() => {
            setTime((prev) => new Date(prev.getTime() - 1000))
        }, 1000)
        return () => clearInterval(timerId)
    }, [])

    const formatNumber = (number: number) => {
        if (number < 10) return `0${number}`
        else return number
    }

    return (
        <div className={cn(styles.wrapper, !rpcData.show && styles.hideWidget)}>
            <div className={styles.penaltyBlock}>
                <div className={styles.title}>№ наказания:</div>
                <div className={styles.data}>{rpcData.penaltyNumber}</div>
            </div>
            <div className={styles.penaltyBlock}>
                <div className={styles.title}>Осталось:</div>
                <div className={cn(styles.data, styles.time)}>
                    {formatNumber(time.getHours())}:
                    {formatNumber(time.getMinutes())}:
                    {formatNumber(time.getSeconds())}
                </div>
            </div>
        </div>
    )
}

export default HudPenaltyWidget
