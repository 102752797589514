import {
    ChangeEventHandler,
    CSSProperties,
    FC,
    InputHTMLAttributes,
    useState,
} from 'react'
import cls from './index.module.scss'
import classNames from 'classnames'

interface Props
    extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type' | 'value'> {
    leftText?: string
    rightText?: string
    min: number
    max: number
    thumbSize?: number
}
const SliderInput: FC<Props> = ({
    className,
    defaultValue,
    thumbSize = 24,
    onChange,
    leftText,
    rightText,
    ...inpProps
}) => {
    const [step, setStep] = useState<number>(+(defaultValue || 0))

    const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        onChange && onChange(event)
        setStep(+event.target.value)
    }

    return (
        <div
            className={classNames(cls.wrapper, className)}
            style={
                {
                    '--thumb-size': `${thumbSize}rem`,
                } as CSSProperties
            }
        >
            <div className={classNames(cls.progress_wrapper)}>
                <div
                    className={classNames(cls.progress)}
                    style={{
                        right: `calc(${
                            100 - (step / inpProps.max) * 100
                        }% - ${thumbSize}rem)`,
                    }}
                />
            </div>
            {leftText ? (
                <p className={classNames(cls.info, cls.leftText)}>{leftText}</p>
            ) : null}
            <input
                onChange={handleChange}
                type="range"
                {...inpProps}
                className={cls.input}
                value={step}
            />
            <div
                className={cls.step_wrapper}
                style={{
                    width: `calc(100% - ${thumbSize}rem)`,
                }}
            >
                <p
                    className={cls.step}
                    style={{
                        left: `calc(${(100 / inpProps.max) * step}%)`,
                    }}
                >
                    {step}
                </p>
            </div>
            {rightText ? (
                <p className={classNames(cls.info, cls.rightText)}>
                    {rightText}
                </p>
            ) : null}
        </div>
    )
}

export { SliderInput }
