import { FC } from 'react'
import { Stepper } from '@/shared/ui/Stepper/Stepper'
import ActionButtons from './ui/ActionButtons'
import { useAppSelector } from '@/shared/hooks/redux-ts'
import cls from './index.module.scss'
import classNames from 'classnames'
import {
    currentStepSelector,
    MAX_STEPS,
} from '@/shared/store/slices/personalization/personalizationSlice'

interface Props {
    actionsPos: 'center' | 'left'
}
const CharacterPersonalizationSteps: FC<Props> = ({ actionsPos }) => {
    const currentStep = useAppSelector(currentStepSelector)

    return (
        <>
            <div className={classNames(cls.stepper)}>
                <Stepper steps={MAX_STEPS} currentStep={currentStep} />
            </div>
            <div
                className={classNames(cls.actionButtonsBlock, {
                    [cls.centerPos]: actionsPos === 'center',
                    [cls.leftPos]: actionsPos === 'left',
                })}
            >
                <ActionButtons currentStep={currentStep} />
            </div>
        </>
    )
}

export { CharacterPersonalizationSteps }
