import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IInitialState {
    noseWidth: number
    noseHeight: number
    noseLength: number
    bridgeNose: number
    tipNose: number
    displacementBridgeNose: number
}
const initialState: IInitialState = {
    noseWidth: 0.5,
    noseHeight: 0.5,
    noseLength: 0.5,
    bridgeNose: 0.5,
    tipNose: 0.5,
    displacementBridgeNose: 0.5,
}

const personalizationNoseSlice = createSlice({
    name: 'personalizationNose',
    initialState,
    reducers: {
        changeNoseWidth(state, { payload }: PayloadAction<number>) {
            state.noseWidth = payload
        },
        changeNoseHeight(state, { payload }: PayloadAction<number>) {
            state.noseHeight = payload
        },
        changeNoseLength(state, { payload }: PayloadAction<number>) {
            state.noseLength = payload
        },
        changeBridgeNose(state, { payload }: PayloadAction<number>) {
            state.bridgeNose = payload
        },
        changeTipNose(state, { payload }: PayloadAction<number>) {
            state.tipNose = payload
        },
        changeDisplacementBridgeNose(
            state,
            { payload }: PayloadAction<number>
        ) {
            state.displacementBridgeNose = payload
        },
    },
})

export const {
    changeBridgeNose,
    changeDisplacementBridgeNose,
    changeNoseHeight,
    changeNoseLength,
    changeNoseWidth,
    changeTipNose,
} = personalizationNoseSlice.actions
export default personalizationNoseSlice.reducer
