import styles from './BeforePaymentScreen.module.scss'
import nfcIcon from '@/shared/assets/images/vectors/PaymentSystem/PaymentScreen/nfcIcon.svg'
import { formatNumbers } from '@/pages/PaymentSystem/utils/formatNumbers'

const BeforePaymentScreen = ({ summ }: { summ: number }) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.toPay}>к оплате</div>
            <div className={styles.summ}>$ {formatNumbers(summ)}</div>
            <img src={nfcIcon} className={styles.nfc} />
            <div className={styles.directions}>
                Приложите к терминалу устройство
                <br />
                или банковскую карту
            </div>
        </div>
    )
}
export default BeforePaymentScreen
