import * as React from 'react'
import { SVGProps } from 'react'

export const UploadingUnloading = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="116"
        height="116"
        viewBox="0 0 116 116"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M52.5851 0.249218C47.6234 0.702343 41.4156 2.15234 36.7031 4.01015C19.9375 10.6031 7.22732 24.5594 2.12966 42.0273C0.679663 47.0117 0.203882 49.7984 0.0679443 54.0805L-0.0679932 58H5.70935H11.4867L11.6226 54.7602C12.0531 43.4773 17.8078 31.4016 26.3719 23.8344C30.6539 20.0281 36.2726 16.6977 41.8008 14.6133C49.5265 11.7359 58.8609 11.0109 67.0625 12.6875C73.7687 14.0469 80.339 16.9242 85.9125 20.9117C88.314 22.6336 90.8289 24.718 90.8289 25.0352C90.8515 25.1484 88.6765 27.4141 86.0484 30.0648L81.2226 34.8906H95.6773H110.109V20.4586V6.00391L104.627 11.4867L99.1211 16.9695L97.3086 15.2477C85.7992 4.39531 69.0562 -1.20078 52.5851 0.249218Z"
            fill={props.color}
        />
        <path
            d="M104.355 58.4984C104.287 58.7929 104.196 60.1976 104.128 61.6249C103.834 68.2632 101.568 75.6945 97.9203 82.0156C93.3891 89.8773 86.1164 96.3116 77.3938 100.209C63.3695 106.462 47.5102 105.646 34.3016 97.9882C31.6055 96.4249 28.0258 93.8421 26.168 92.1429L25.0352 91.1007L29.9062 86.2296L34.7773 81.3359H20.3227H5.89062V95.7679V110.223L11.3961 104.74L16.8789 99.257L18.2383 100.526C26.3945 108.07 36.2953 113.032 47.125 114.98C64.2984 118.062 81.2906 113.893 94.4766 103.358C97.2406 101.16 100.934 97.4445 103.131 94.7031C110.834 85.0515 115.456 72.5452 115.819 60.4921L115.887 58.1132L110.177 58.0452L104.445 57.9999L104.355 58.4984Z"
            fill={props.color}
        />
        <path
            d="M72.5028 40.4759L67.3028 37.68C62.7372 35.2275 60.4544 34 58 34C55.5456 34 53.2628 35.2249 48.6972 37.68L47.8626 38.13L71.0624 51.7075L81.504 46.3553C79.8244 44.4061 77.1152 42.9496 72.5028 40.4706V40.4759ZM83.3448 49.8808L72.95 55.2064V63.2906C72.95 63.8203 72.7446 64.3282 72.3789 64.7027C72.0132 65.0773 71.5172 65.2877 71 65.2877C70.4828 65.2877 69.9868 65.0773 69.6211 64.7027C69.2554 64.3282 69.05 63.8203 69.05 63.2906V57.2008L59.95 61.8607V87C61.8168 86.5234 63.941 85.3837 67.3028 83.5757L72.5028 80.7797C78.0954 77.7735 80.893 76.2717 82.4478 73.5689C84 70.8689 84 67.5058 84 60.7876V60.476C84 55.4354 84 52.2827 83.3448 49.8808ZM56.05 87V61.8633L32.6552 49.8808C32 52.2827 32 55.4354 32 60.4707V60.7823C32 67.5058 32 70.8689 33.5522 73.5689C35.107 76.2717 37.9046 77.7761 43.4972 80.7824L48.6972 83.5757C52.059 85.3837 54.1832 86.5234 56.05 87ZM34.496 46.358L58 58.3937L66.8686 53.8537L43.765 40.3321L43.4972 40.4759C38.8874 42.9523 36.1756 44.4088 34.496 46.3606V46.358Z"
            fill={props.color}
        />
    </svg>
)
