import styles from './AfterPaymentScreen.module.scss'
import paymentSuccess from '@/shared/assets/images/vectors/PaymentSystem/PaymentScreen/paymentSuccess.svg'
import { formatNumbers } from '@/pages/PaymentSystem/utils/formatNumbers'

const AfterPaymentScreen = ({ summ }: { summ: number }) => {
    return (
        <div className={styles.wrapper}>
            <img src={paymentSuccess} className={styles.paymentSuccess} />
            <div className={styles.text}>Платеж прошел успeшно</div>
            <div className={styles.value}>$ {formatNumbers(summ)}</div>
        </div>
    )
}

export default AfterPaymentScreen
