import { MutableRefObject } from 'react'

export const zoom = (
    value: number,
    zoomRef: MutableRefObject<HTMLElement | null>,
    zoomArrowRef: MutableRefObject<HTMLElement | null>
) => {
    if (zoomRef.current && zoomArrowRef.current) {
        const rangeWidth = zoomRef.current.offsetWidth
        const arrowWidth = zoomArrowRef.current.offsetWidth
        const margin = 0.04
        const left =
            rangeWidth * margin +
            (rangeWidth - rangeWidth * margin * 2 - arrowWidth) * (value / 100)
        return left
    }
}
