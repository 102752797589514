import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IInitialState {
    variant?: number
    color?: string
}
const initialState: IInitialState = {}

const hairSlice = createSlice({
    name: 'hair',
    initialState,
    reducers: {
        changeVariant: (state, { payload }: PayloadAction<number>) => {
            state.variant = payload
        },
        changeColor: (state, { payload }: PayloadAction<string>) => {
            state.color = payload
        },
    },
})

export const { changeColor, changeVariant } = hairSlice.actions
export default hairSlice.reducer
