import { baseApi as api } from './baseApi'
const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        authControllerSignUp: build.mutation<
            AuthControllerSignUpApiResponse,
            AuthControllerSignUpApiArg
        >({
            query: (queryArg) => ({
                url: `/auth/register`,
                method: 'POST',
                body: queryArg.authSignupRequest,
            }),
        }),
        authControllerApproveSignUp: build.mutation<
            AuthControllerApproveSignUpApiResponse,
            AuthControllerApproveSignUpApiArg
        >({
            query: (queryArg) => ({
                url: `/auth/approve/register`,
                method: 'POST',
                body: queryArg.signUpApproveRequest,
            }),
        }),
        authControllerLogin: build.mutation<
            AuthControllerLoginApiResponse,
            AuthControllerLoginApiArg
        >({
            query: (queryArg) => ({
                url: `/auth/login`,
                method: 'POST',
                body: queryArg.authLoginRequest,
            }),
        }),
        authControllerPasswordRecovery: build.mutation<
            AuthControllerPasswordRecoveryApiResponse,
            AuthControllerPasswordRecoveryApiArg
        >({
            query: (queryArg) => ({
                url: `/auth/recovery`,
                method: 'POST',
                body: queryArg.passwordRecoveryRequest,
            }),
        }),
        authControllerPasswordRecoveryApprove: build.mutation<
            AuthControllerPasswordRecoveryApproveApiResponse,
            AuthControllerPasswordRecoveryApproveApiArg
        >({
            query: (queryArg) => ({
                url: `/auth/approve/recovery`,
                method: 'POST',
                body: queryArg.passwordRecoveryApproveRequest,
            }),
        }),
        authControllerPasswordRecoveryUpdate: build.mutation<
            AuthControllerPasswordRecoveryUpdateApiResponse,
            AuthControllerPasswordRecoveryUpdateApiArg
        >({
            query: (queryArg) => ({
                url: `/auth/approve/update`,
                method: 'PATCH',
                body: queryArg.passwordRecoveryUpdateRequest,
            }),
        }),
        authControllerLogout: build.mutation<
            AuthControllerLogoutApiResponse,
            AuthControllerLogoutApiArg
        >({
            query: () => ({ url: `/auth/logout`, method: 'DELETE' }),
        }),
        authControllerRefreshToken: build.mutation<
            AuthControllerRefreshTokenApiResponse,
            AuthControllerRefreshTokenApiArg
        >({
            query: (queryArg) => ({
                url: `/auth/${queryArg.uuid}`,
                method: 'POST',
            }),
        }),
        clothesControllerCreateMany: build.mutation<
            ClothesControllerCreateManyApiResponse,
            ClothesControllerCreateManyApiArg
        >({
            query: (queryArg) => ({
                url: `/many`,
                method: 'POST',
                body: queryArg.createManyClothesRequest,
            }),
        }),
        clothesControllerCreateOne: build.mutation<
            ClothesControllerCreateOneApiResponse,
            ClothesControllerCreateOneApiArg
        >({
            query: (queryArg) => ({
                url: `/one`,
                method: 'POST',
                body: queryArg.createClothesRequest,
            }),
        }),
        restCharacterControllerFindPlayerCharacters: build.query<
            RestCharacterControllerFindPlayerCharactersApiResponse,
            RestCharacterControllerFindPlayerCharactersApiArg
        >({
            query: () => ({ url: `/character` }),
        }),
        restCharacterControllerSelectCharacter: build.mutation<
            RestCharacterControllerSelectCharacterApiResponse,
            RestCharacterControllerSelectCharacterApiArg
        >({
            query: (queryArg) => ({
                url: `/character`,
                method: 'PATCH',
                body: queryArg.characterSelectRequest,
            }),
        }),
        restCharacterControllerCreateCharacter: build.mutation<
            RestCharacterControllerCreateCharacterApiResponse,
            RestCharacterControllerCreateCharacterApiArg
        >({
            query: (queryArg) => ({
                url: `/character`,
                method: 'POST',
                body: queryArg.characterCreateRequest,
            }),
        }),
        restCharacterControllerDeleteCharacter: build.mutation<
            RestCharacterControllerDeleteCharacterApiResponse,
            RestCharacterControllerDeleteCharacterApiArg
        >({
            query: (queryArg) => ({
                url: `/character/${queryArg.id}`,
                method: 'DELETE',
            }),
        }),
        restCharacterControllerRecoverCharacter: build.mutation<
            RestCharacterControllerRecoverCharacterApiResponse,
            RestCharacterControllerRecoverCharacterApiArg
        >({
            query: (queryArg) => ({
                url: `/character/${queryArg.uuid}/recovery`,
                method: 'POST',
            }),
        }),
        vehicleModelControllerCreateVehicleModel: build.mutation<
            VehicleModelControllerCreateVehicleModelApiResponse,
            VehicleModelControllerCreateVehicleModelApiArg
        >({
            query: (queryArg) => ({
                url: `/vehicleModel`,
                method: 'POST',
                body: queryArg.createVehicleModelRequest,
            }),
        }),
        vehicleControllerCreateVehicle: build.mutation<
            VehicleControllerCreateVehicleApiResponse,
            VehicleControllerCreateVehicleApiArg
        >({
            query: (queryArg) => ({
                url: `/vehicle`,
                method: 'POST',
                body: queryArg.createVehicleRequest,
            }),
        }),
        restShopControllerFindShop: build.query<
            RestShopControllerFindShopApiResponse,
            RestShopControllerFindShopApiArg
        >({
            query: () => ({ url: `/shop/uuid` }),
        }),
        restBarberShopControllerFindBarberShop: build.query<
            RestBarberShopControllerFindBarberShopApiResponse,
            RestBarberShopControllerFindBarberShopApiArg
        >({
            query: () => ({ url: `/Barbershop/uuid` }),
        }),
        mediaControllerUploadFile: build.mutation<
            MediaControllerUploadFileApiResponse,
            MediaControllerUploadFileApiArg
        >({
            query: (queryArg) => ({
                url: `/media/upload`,
                method: 'POST',
                body: queryArg.body,
            }),
        }),
        mediaControllerGetFile: build.query<
            MediaControllerGetFileApiResponse,
            MediaControllerGetFileApiArg
        >({
            query: (queryArg) => ({ url: `/media/${queryArg.filename}` }),
        }),
    }),
    overrideExisting: false,
})
export { injectedRtkApi as openApi }
export type AuthControllerSignUpApiResponse =
    /** status 200 Аутентификация */ AuthSignUpResponse
export type AuthControllerSignUpApiArg = {
    authSignupRequest: AuthSignupRequest
}
export type AuthControllerApproveSignUpApiResponse =
    /** status 200 Аутентификация */ AuthSignUpApproveResponse
export type AuthControllerApproveSignUpApiArg = {
    signUpApproveRequest: SignUpApproveRequest
}
export type AuthControllerLoginApiResponse =
    /** status 200 Аутентификация */ AuthSignInTokenResponse
export type AuthControllerLoginApiArg = {
    authLoginRequest: AuthLoginRequest
}
export type AuthControllerPasswordRecoveryApiResponse =
    /** status 200 Аутентификация */ PasswordRecoveryResponse
export type AuthControllerPasswordRecoveryApiArg = {
    passwordRecoveryRequest: PasswordRecoveryRequest
}
export type AuthControllerPasswordRecoveryApproveApiResponse = unknown
export type AuthControllerPasswordRecoveryApproveApiArg = {
    passwordRecoveryApproveRequest: PasswordRecoveryApproveRequest
}
export type AuthControllerPasswordRecoveryUpdateApiResponse = unknown
export type AuthControllerPasswordRecoveryUpdateApiArg = {
    passwordRecoveryUpdateRequest: PasswordRecoveryUpdateRequest
}
export type AuthControllerLogoutApiResponse = unknown
export type AuthControllerLogoutApiArg = void
export type AuthControllerRefreshTokenApiResponse = unknown
export type AuthControllerRefreshTokenApiArg = {
    uuid: string
}
export type ClothesControllerCreateManyApiResponse = unknown
export type ClothesControllerCreateManyApiArg = {
    createManyClothesRequest: CreateManyClothesRequest
}
export type ClothesControllerCreateOneApiResponse =
    /** status 200 Список персонажей */ CreateClothesResponse[]
export type ClothesControllerCreateOneApiArg = {
    createClothesRequest: CreateClothesRequest
}
export type RestCharacterControllerFindPlayerCharactersApiResponse =
    /** status 200 Список персонажей */ CharacterFindOneResponseDto[]
export type RestCharacterControllerFindPlayerCharactersApiArg = void
export type RestCharacterControllerSelectCharacterApiResponse =
    /** status 200 Выбор персонажа */ CharacterFindOneResponseDto[]
export type RestCharacterControllerSelectCharacterApiArg = {
    characterSelectRequest: CharacterSelectRequest
}
export type RestCharacterControllerCreateCharacterApiResponse =
    /** status 200 Создание персонажа */ CharacterCreateResponse[]
export type RestCharacterControllerCreateCharacterApiArg = {
    characterCreateRequest: CharacterCreateRequest
}
export type RestCharacterControllerDeleteCharacterApiResponse = unknown
export type RestCharacterControllerDeleteCharacterApiArg = {
    id: string
}
export type RestCharacterControllerRecoverCharacterApiResponse = unknown
export type RestCharacterControllerRecoverCharacterApiArg = {
    id: string
    /** UUID персонажа для восстановления */
    uuid: string
}
export type VehicleModelControllerCreateVehicleModelApiResponse =
    /** status 200 Создание модели транспорта */ CreateVehicleModelRespones
export type VehicleModelControllerCreateVehicleModelApiArg = {
    createVehicleModelRequest: CreateVehicleModelRequest
}
export type VehicleControllerCreateVehicleApiResponse = unknown
export type VehicleControllerCreateVehicleApiArg = {
    createVehicleRequest: CreateVehicleRequest
}
export type RestShopControllerFindShopApiResponse =
    /** status 200 Магазин */ ShopFindOneDto[]
export type RestShopControllerFindShopApiArg = {
    uuid: string
}
export type RestBarberShopControllerFindBarberShopApiResponse =
    /** status 200 Барбершоп */ FindOneBarberShopResponse[]
export type RestBarberShopControllerFindBarberShopApiArg = {
    uuid: string
}
export type MediaControllerUploadFileApiResponse = unknown
export type MediaControllerUploadFileApiArg = {
    /** File to upload */
    body: {
        file?: Blob
    }
}
export type MediaControllerGetFileApiResponse = unknown
export type MediaControllerGetFileApiArg = {
    filename: string
}
export type AuthSignUpResponse = {
    verifyUuid: string
}
export type AuthSignupRequest = {
    username: string
    email: string
    password: string
    promocode?: string
    newsletter: boolean
}
export type AuthSignUpApproveResponse = {
    accessToken: string
    refreshToken: string
}
export type SignUpApproveRequest = {
    verifyUuid: string
    code: string
}
export type AuthSignInTokenResponse = {
    accessToken: string
    refreshToken: string
    lifetime: string
}
export type AuthLoginRequest = {
    login: string
    password: string
}
export type PasswordRecoveryResponse = {
    passwordResetUuid: string
}
export type PasswordRecoveryRequest = {
    emailOrUsername: string
}
export type PasswordRecoveryApproveRequest = {
    passwordResetUuid: string
    code: string
}
export type PasswordRecoveryUpdateRequest = {
    password: string
    passwordResetUuid: string
}
export type CreateManyClothesRequest = {
    clothesRequest: any[]
}
export type CreateClothesResponse = {
    texture: number
    typeId: string
}
export type CreateClothesRequest = {
    gender: number
    slotsCount: number
    overlayId:
        | 'hat'
        | 'glasses'
        | 'ears'
        | 'watches'
        | 'bracelets'
        | 'torsos'
        | 'bags'
        | 'shoes'
        | 'accessories'
        | 'undershirts'
        | 'armor'
        | 'decals'
        | 'tops'
    textures: any[]
    dlcpackname: string
    body: string
    season: number
    part:
        | 'hat'
        | 'glasses'
        | 'ears'
        | 'watches'
        | 'bracelets'
        | 'torsos'
        | 'bags'
        | 'shoes'
        | 'accessories'
        | 'undershirts'
        | 'armor'
        | 'decals'
        | 'tops'
    sizeX: number
    sizeY: number
    stackable: boolean
    maxStackSize: number
    weight: number
    name: string
    texture: number
}
export type CharacterFindOneResponseDto = {
    playerId: string
    staticId: string
    name: string
    lastname: string
    gender: 0 | 1
    age: number
    gait: string
    birthday: number
    month: number
    country: string
    city: string
    nationality: string
    clothes: string
    job: boolean
    spawn: string[]
    height: number
    weight: number
    drunkLvl: number
    stateDick: boolean
    hairId: string
    beardId: string
    temperature: number
}
export type CharacterSelectRequest = {
    uuid: string
    characterUuid: string
}
export type CharacterNewResponse = {
    id: string
    name: string
    lastname: string
    gender: 0 | 1
    age: number
    gait:
        | 'Aggressive'
        | 'Gangster'
        | 'Scared'
        | 'Sexy'
        | 'BroadShouldered'
        | 'Brave'
        | 'Calm'
    birthday: number
    month: number
    country: string
    city: string
    nationality:
        | 'Chinese'
        | 'Arabs'
        | 'Americans'
        | 'Chechens'
        | 'Jews'
        | 'Brazilians'
        | 'Mexicans'
        | 'Russians'
        | 'Japanese'
        | 'Azerbaijanis'
        | 'Armenians'
        | 'Czechs'
        | 'Spaniards'
        | 'Swedes'
        | 'Hungarians'
        | 'Koreans'
        | 'English'
        | 'Thais'
        | 'Vietnamese'
        | 'Germans'
        | 'French'
        | 'Turks'
        | 'Italians'
        | 'Kazakhs'
        | 'Irish'
        | 'Greeks'
        | 'Indians'
        | 'NativeAmericans'
    mainPurpose:
        | 'Athlete'
        | 'Bodybuilder'
        | 'SuperParent'
        | 'GoodHeritage'
        | 'HappyFamily'
        | 'BestBartender'
        | 'Gourmet'
        | 'Chef'
        | 'FabulousWealth'
        | 'Baron'
        | 'Collector'
        | 'Gangster'
        | 'EnemyOfTheState'
        | 'OnTwoWheels'
        | 'LawEnforcer'
        | 'Hippocrates'
        | 'Politician'
        | 'Engineer'
        | 'Vegetarian'
        | 'Nutritionist'
        | 'MarathonRunner'
        | 'Voyager'
        | 'Jumanji'
        | 'ThrillSeeker'
        | 'PersonOfTheEra'
        | 'SerialRomantic'
        | 'Soulmate'
        | 'Fisherman'
        | 'Gardener'
        | 'Hunter'
    secondaryPurpose:
        | 'Athlete'
        | 'Bodybuilder'
        | 'SuperParent'
        | 'GoodHeritage'
        | 'HappyFamily'
        | 'BestBartender'
        | 'Gourmet'
        | 'Chef'
        | 'FabulousWealth'
        | 'Baron'
        | 'Collector'
        | 'Gangster'
        | 'EnemyOfTheState'
        | 'OnTwoWheels'
        | 'LawEnforcer'
        | 'Hippocrates'
        | 'Politician'
        | 'Engineer'
        | 'Vegetarian'
        | 'Nutritionist'
        | 'MarathonRunner'
        | 'Voyager'
        | 'Jumanji'
        | 'ThrillSeeker'
        | 'PersonOfTheEra'
        | 'SerialRomantic'
        | 'Soulmate'
        | 'Fisherman'
        | 'Gardener'
        | 'Hunter'
    mainTrait:
        | 'Active'
        | 'Cheerful'
        | 'HighMaintenance'
        | 'Genius'
        | 'Touchy'
        | 'Butterfingers'
        | 'Romantic'
        | 'Confident'
        | 'Creator'
        | 'Moody'
        | 'Choleric'
        | 'Gourmet'
        | 'ArtConnoisseur'
        | 'Bookworm'
        | 'MusicLover'
        | 'Perfectionist'
        | 'Handy'
        | 'Eccentric'
        | 'Vegetarian'
        | 'EternalChild'
        | 'Careerist'
        | 'Kleptomaniac'
        | 'CouchPotato'
        | 'CatLover'
        | 'NatureLover'
        | 'DogLover'
        | 'AnimalLover'
        | 'LactoseIntolerant'
        | 'Slob'
        | 'Clumsy'
        | 'Glutton'
        | 'Brave'
        | 'Practical'
        | 'Picky'
        | 'Skeptic'
        | 'DanceMachine'
        | 'Freegan'
        | 'NeatFreak'
        | 'Quirky'
        | 'EcoEnthusiast'
        | 'Kind'
        | 'Friendly'
        | 'Bully'
        | 'Evil'
        | 'Insider'
        | 'Fickle'
        | 'Loner'
        | 'Paranoid'
        | 'AntiKids'
        | 'Jealous'
        | 'Easygoing'
        | 'FamilyOriented'
        | 'Respectful'
        | 'Egotistical'
    secondaryTrait:
        | 'Active'
        | 'Cheerful'
        | 'HighMaintenance'
        | 'Genius'
        | 'Touchy'
        | 'Butterfingers'
        | 'Romantic'
        | 'Confident'
        | 'Creator'
        | 'Moody'
        | 'Choleric'
        | 'Gourmet'
        | 'ArtConnoisseur'
        | 'Bookworm'
        | 'MusicLover'
        | 'Perfectionist'
        | 'Handy'
        | 'Eccentric'
        | 'Vegetarian'
        | 'EternalChild'
        | 'Careerist'
        | 'Kleptomaniac'
        | 'CouchPotato'
        | 'CatLover'
        | 'NatureLover'
        | 'DogLover'
        | 'AnimalLover'
        | 'LactoseIntolerant'
        | 'Slob'
        | 'Clumsy'
        | 'Glutton'
        | 'Brave'
        | 'Practical'
        | 'Picky'
        | 'Skeptic'
        | 'DanceMachine'
        | 'Freegan'
        | 'NeatFreak'
        | 'Quirky'
        | 'EcoEnthusiast'
        | 'Kind'
        | 'Friendly'
        | 'Bully'
        | 'Evil'
        | 'Insider'
        | 'Fickle'
        | 'Loner'
        | 'Paranoid'
        | 'AntiKids'
        | 'Jealous'
        | 'Easygoing'
        | 'FamilyOriented'
        | 'Respectful'
        | 'Egotistical'
    thirdTrait:
        | 'Active'
        | 'Cheerful'
        | 'HighMaintenance'
        | 'Genius'
        | 'Touchy'
        | 'Butterfingers'
        | 'Romantic'
        | 'Confident'
        | 'Creator'
        | 'Moody'
        | 'Choleric'
        | 'Gourmet'
        | 'ArtConnoisseur'
        | 'Bookworm'
        | 'MusicLover'
        | 'Perfectionist'
        | 'Handy'
        | 'Eccentric'
        | 'Vegetarian'
        | 'EternalChild'
        | 'Careerist'
        | 'Kleptomaniac'
        | 'CouchPotato'
        | 'CatLover'
        | 'NatureLover'
        | 'DogLover'
        | 'AnimalLover'
        | 'LactoseIntolerant'
        | 'Slob'
        | 'Clumsy'
        | 'Glutton'
        | 'Brave'
        | 'Practical'
        | 'Picky'
        | 'Skeptic'
        | 'DanceMachine'
        | 'Freegan'
        | 'NeatFreak'
        | 'Quirky'
        | 'EcoEnthusiast'
        | 'Kind'
        | 'Friendly'
        | 'Bully'
        | 'Evil'
        | 'Insider'
        | 'Fickle'
        | 'Loner'
        | 'Paranoid'
        | 'AntiKids'
        | 'Jealous'
        | 'Easygoing'
        | 'FamilyOriented'
        | 'Respectful'
        | 'Egotistical'
    clothes: string
    height: number
    weight: number
    hairId: string
    beardId: string
    temperature: number
}
export type FaceFeatureDto = {
    NoseWidth: number
    NoseHeight: number
    NoseLength: number
    BridgeNose: number
    TipNose: number
    DisplacementBridgeNose: number
    EyebrowHeight: number
    EyebrowWidth: number
    CheekboneHeight: number
    CheekboneWidth: number
    CheekWidth: number
    EyeOpenness: number
    FullLips: number
    JawWidth: number
    JawHeight: number
    ChinLength: number
    Chin: number
    ChinWidth: number
    FullChin: number
    NeckWidth: number
}
export type AppearanceExampleDto = {
    index: number | null
    color?:
        | (
              | 'color_1_1'
              | 'color_1_10'
              | 'color_1_15'
              | 'color_1_20'
              | 'color_1_25'
              | 'color_1_30'
              | 'color_1_32'
              | 'color_1_33'
              | 'color_1_34'
              | 'color_1_35'
              | 'color_1_36'
              | 'color_1_37'
              | 'color_1_38'
              | 'color_1_39'
              | 'color_1_41'
              | 'color_1_42'
              | 'color_1_46'
              | 'color_1_47'
              | 'color_1_48'
              | 'color_1_52'
              | 'color_1_53'
              | 'color_2_1'
              | 'color_2_3'
              | 'color_2_18'
              | 'color_2_22'
              | 'color_2_23'
              | 'color_2_25'
              | 'color_2_26'
              | 'color_2_27'
              | 'color_2_28'
              | 'color_2_35'
              | 'color_2_38'
              | 'color_2_40'
              | 'color_2_44'
              | 'color_2_48'
              | 'color_2_52'
              | 'color_2_53'
              | 'color_2_57'
              | 'color_2_59'
              | 'color_4_2'
              | 'color_5_20'
              | 'color_5_35'
              | 'color_5_40'
              | 'color_5_43'
              | 'color_5_48'
              | 'color_5_53'
              | 'color_6_1'
              | 'color_6_25'
          )
        | null
}
export type AppearanceFeaturesDto = {
    skinMarking: AppearanceExampleDto
    eyeBrows: AppearanceExampleDto
    skinAging: AppearanceExampleDto
    makeup: AppearanceExampleDto
    blush: AppearanceExampleDto
    skinColor: AppearanceExampleDto
    bruns: AppearanceExampleDto
    lipstick: AppearanceExampleDto
    freckles: AppearanceExampleDto
    chestHair: AppearanceExampleDto
    skinDefects: AppearanceExampleDto
}
export type CharacterAppearanceCreateResponse = {
    uuid: string
    faceFather:
        | 0
        | 1
        | 2
        | 3
        | 4
        | 5
        | 6
        | 7
        | 8
        | 9
        | 10
        | 11
        | 12
        | 13
        | 14
        | 15
        | 16
        | 17
        | 18
        | 19
        | 20
        | 21
        | 22
        | 23
        | 24
        | 25
        | 26
        | 27
        | 28
        | 29
        | 30
        | 31
        | 32
        | 33
        | 34
        | 35
        | 36
        | 37
        | 38
        | 39
        | 40
        | 41
        | 42
        | 43
        | 44
        | 45
    faceMother:
        | 0
        | 1
        | 2
        | 3
        | 4
        | 5
        | 6
        | 7
        | 8
        | 9
        | 10
        | 11
        | 12
        | 13
        | 14
        | 15
        | 16
        | 17
        | 18
        | 19
        | 20
        | 21
        | 22
        | 23
        | 24
        | 25
        | 26
        | 27
        | 28
        | 29
        | 30
        | 31
        | 32
        | 33
        | 34
        | 35
        | 36
        | 37
        | 38
        | 39
        | 40
        | 41
        | 42
        | 43
        | 44
        | 45
    skinFather:
        | 0
        | 1
        | 2
        | 3
        | 4
        | 5
        | 6
        | 7
        | 8
        | 9
        | 10
        | 11
        | 12
        | 13
        | 14
        | 15
        | 16
        | 17
        | 18
        | 19
        | 20
        | 21
        | 22
        | 23
        | 24
        | 25
        | 26
        | 27
        | 28
        | 29
        | 30
        | 31
        | 32
        | 33
        | 34
        | 35
        | 36
        | 37
        | 38
        | 39
        | 40
        | 41
        | 42
        | 43
        | 44
        | 45
    skinMother:
        | 0
        | 1
        | 2
        | 3
        | 4
        | 5
        | 6
        | 7
        | 8
        | 9
        | 10
        | 11
        | 12
        | 13
        | 14
        | 15
        | 16
        | 17
        | 18
        | 19
        | 20
        | 21
        | 22
        | 23
        | 24
        | 25
        | 26
        | 27
        | 28
        | 29
        | 30
        | 31
        | 32
        | 33
        | 34
        | 35
        | 36
        | 37
        | 38
        | 39
        | 40
        | 41
        | 42
        | 43
        | 44
        | 45
    faceMix: number
    skinMix: number
    eye: number
    faceFeatures: FaceFeatureDto
    head: AppearanceFeaturesDto
}
export type CharacterStatsCreateResponse = {
    uuid: string
    agility: number
    strength: number
    intelligence: number
    perception: number
    stamina: number
}
export type CharacterCreateResponse = {
    character: CharacterNewResponse
    appearance: CharacterAppearanceCreateResponse
    stats: CharacterStatsCreateResponse
}
export type CharacterNewRequest = {
    name: string
    lastname: string
    gender: 0 | 1
    age: number
    gait:
        | 'Aggressive'
        | 'Gangster'
        | 'Scared'
        | 'Sexy'
        | 'BroadShouldered'
        | 'Brave'
        | 'Calm'
    birthday: number
    month: number
    country: string
    city: string
    nationality:
        | 'Chinese'
        | 'Arabs'
        | 'Americans'
        | 'Chechens'
        | 'Jews'
        | 'Brazilians'
        | 'Mexicans'
        | 'Russians'
        | 'Japanese'
        | 'Azerbaijanis'
        | 'Armenians'
        | 'Czechs'
        | 'Spaniards'
        | 'Swedes'
        | 'Hungarians'
        | 'Koreans'
        | 'English'
        | 'Thais'
        | 'Vietnamese'
        | 'Germans'
        | 'French'
        | 'Turks'
        | 'Italians'
        | 'Kazakhs'
        | 'Irish'
        | 'Greeks'
        | 'Indians'
        | 'NativeAmericans'
    mainPurpose:
        | 'Athlete'
        | 'Bodybuilder'
        | 'SuperParent'
        | 'GoodHeritage'
        | 'HappyFamily'
        | 'BestBartender'
        | 'Gourmet'
        | 'Chef'
        | 'FabulousWealth'
        | 'Baron'
        | 'Collector'
        | 'Gangster'
        | 'EnemyOfTheState'
        | 'OnTwoWheels'
        | 'LawEnforcer'
        | 'Hippocrates'
        | 'Politician'
        | 'Engineer'
        | 'Vegetarian'
        | 'Nutritionist'
        | 'MarathonRunner'
        | 'Voyager'
        | 'Jumanji'
        | 'ThrillSeeker'
        | 'PersonOfTheEra'
        | 'SerialRomantic'
        | 'Soulmate'
        | 'Fisherman'
        | 'Gardener'
        | 'Hunter'
    secondaryPurpose:
        | 'Athlete'
        | 'Bodybuilder'
        | 'SuperParent'
        | 'GoodHeritage'
        | 'HappyFamily'
        | 'BestBartender'
        | 'Gourmet'
        | 'Chef'
        | 'FabulousWealth'
        | 'Baron'
        | 'Collector'
        | 'Gangster'
        | 'EnemyOfTheState'
        | 'OnTwoWheels'
        | 'LawEnforcer'
        | 'Hippocrates'
        | 'Politician'
        | 'Engineer'
        | 'Vegetarian'
        | 'Nutritionist'
        | 'MarathonRunner'
        | 'Voyager'
        | 'Jumanji'
        | 'ThrillSeeker'
        | 'PersonOfTheEra'
        | 'SerialRomantic'
        | 'Soulmate'
        | 'Fisherman'
        | 'Gardener'
        | 'Hunter'
    mainTrait:
        | 'Active'
        | 'Cheerful'
        | 'HighMaintenance'
        | 'Genius'
        | 'Touchy'
        | 'Butterfingers'
        | 'Romantic'
        | 'Confident'
        | 'Creator'
        | 'Moody'
        | 'Choleric'
        | 'Gourmet'
        | 'ArtConnoisseur'
        | 'Bookworm'
        | 'MusicLover'
        | 'Perfectionist'
        | 'Handy'
        | 'Eccentric'
        | 'Vegetarian'
        | 'EternalChild'
        | 'Careerist'
        | 'Kleptomaniac'
        | 'CouchPotato'
        | 'CatLover'
        | 'NatureLover'
        | 'DogLover'
        | 'AnimalLover'
        | 'LactoseIntolerant'
        | 'Slob'
        | 'Clumsy'
        | 'Glutton'
        | 'Brave'
        | 'Practical'
        | 'Picky'
        | 'Skeptic'
        | 'DanceMachine'
        | 'Freegan'
        | 'NeatFreak'
        | 'Quirky'
        | 'EcoEnthusiast'
        | 'Kind'
        | 'Friendly'
        | 'Bully'
        | 'Evil'
        | 'Insider'
        | 'Fickle'
        | 'Loner'
        | 'Paranoid'
        | 'AntiKids'
        | 'Jealous'
        | 'Easygoing'
        | 'FamilyOriented'
        | 'Respectful'
        | 'Egotistical'
    secondaryTrait:
        | 'Active'
        | 'Cheerful'
        | 'HighMaintenance'
        | 'Genius'
        | 'Touchy'
        | 'Butterfingers'
        | 'Romantic'
        | 'Confident'
        | 'Creator'
        | 'Moody'
        | 'Choleric'
        | 'Gourmet'
        | 'ArtConnoisseur'
        | 'Bookworm'
        | 'MusicLover'
        | 'Perfectionist'
        | 'Handy'
        | 'Eccentric'
        | 'Vegetarian'
        | 'EternalChild'
        | 'Careerist'
        | 'Kleptomaniac'
        | 'CouchPotato'
        | 'CatLover'
        | 'NatureLover'
        | 'DogLover'
        | 'AnimalLover'
        | 'LactoseIntolerant'
        | 'Slob'
        | 'Clumsy'
        | 'Glutton'
        | 'Brave'
        | 'Practical'
        | 'Picky'
        | 'Skeptic'
        | 'DanceMachine'
        | 'Freegan'
        | 'NeatFreak'
        | 'Quirky'
        | 'EcoEnthusiast'
        | 'Kind'
        | 'Friendly'
        | 'Bully'
        | 'Evil'
        | 'Insider'
        | 'Fickle'
        | 'Loner'
        | 'Paranoid'
        | 'AntiKids'
        | 'Jealous'
        | 'Easygoing'
        | 'FamilyOriented'
        | 'Respectful'
        | 'Egotistical'
    thirdTrait:
        | 'Active'
        | 'Cheerful'
        | 'HighMaintenance'
        | 'Genius'
        | 'Touchy'
        | 'Butterfingers'
        | 'Romantic'
        | 'Confident'
        | 'Creator'
        | 'Moody'
        | 'Choleric'
        | 'Gourmet'
        | 'ArtConnoisseur'
        | 'Bookworm'
        | 'MusicLover'
        | 'Perfectionist'
        | 'Handy'
        | 'Eccentric'
        | 'Vegetarian'
        | 'EternalChild'
        | 'Careerist'
        | 'Kleptomaniac'
        | 'CouchPotato'
        | 'CatLover'
        | 'NatureLover'
        | 'DogLover'
        | 'AnimalLover'
        | 'LactoseIntolerant'
        | 'Slob'
        | 'Clumsy'
        | 'Glutton'
        | 'Brave'
        | 'Practical'
        | 'Picky'
        | 'Skeptic'
        | 'DanceMachine'
        | 'Freegan'
        | 'NeatFreak'
        | 'Quirky'
        | 'EcoEnthusiast'
        | 'Kind'
        | 'Friendly'
        | 'Bully'
        | 'Evil'
        | 'Insider'
        | 'Fickle'
        | 'Loner'
        | 'Paranoid'
        | 'AntiKids'
        | 'Jealous'
        | 'Easygoing'
        | 'FamilyOriented'
        | 'Respectful'
        | 'Egotistical'
    clothes: string
    height: number
    weight: number
    hairId: string
    beardId: string
    temperature: number
}
export type CharacterAppearanceCreateRequest = {
    faceFather:
        | 0
        | 1
        | 2
        | 3
        | 4
        | 5
        | 6
        | 7
        | 8
        | 9
        | 10
        | 11
        | 12
        | 13
        | 14
        | 15
        | 16
        | 17
        | 18
        | 19
        | 20
        | 21
        | 22
        | 23
        | 24
        | 25
        | 26
        | 27
        | 28
        | 29
        | 30
        | 31
        | 32
        | 33
        | 34
        | 35
        | 36
        | 37
        | 38
        | 39
        | 40
        | 41
        | 42
        | 43
        | 44
        | 45
    faceMother:
        | 0
        | 1
        | 2
        | 3
        | 4
        | 5
        | 6
        | 7
        | 8
        | 9
        | 10
        | 11
        | 12
        | 13
        | 14
        | 15
        | 16
        | 17
        | 18
        | 19
        | 20
        | 21
        | 22
        | 23
        | 24
        | 25
        | 26
        | 27
        | 28
        | 29
        | 30
        | 31
        | 32
        | 33
        | 34
        | 35
        | 36
        | 37
        | 38
        | 39
        | 40
        | 41
        | 42
        | 43
        | 44
        | 45
    skinFather:
        | 0
        | 1
        | 2
        | 3
        | 4
        | 5
        | 6
        | 7
        | 8
        | 9
        | 10
        | 11
        | 12
        | 13
        | 14
        | 15
        | 16
        | 17
        | 18
        | 19
        | 20
        | 21
        | 22
        | 23
        | 24
        | 25
        | 26
        | 27
        | 28
        | 29
        | 30
        | 31
        | 32
        | 33
        | 34
        | 35
        | 36
        | 37
        | 38
        | 39
        | 40
        | 41
        | 42
        | 43
        | 44
        | 45
    skinMother:
        | 0
        | 1
        | 2
        | 3
        | 4
        | 5
        | 6
        | 7
        | 8
        | 9
        | 10
        | 11
        | 12
        | 13
        | 14
        | 15
        | 16
        | 17
        | 18
        | 19
        | 20
        | 21
        | 22
        | 23
        | 24
        | 25
        | 26
        | 27
        | 28
        | 29
        | 30
        | 31
        | 32
        | 33
        | 34
        | 35
        | 36
        | 37
        | 38
        | 39
        | 40
        | 41
        | 42
        | 43
        | 44
        | 45
    faceMix: number
    skinMix: number
    eye: number
    faceFeatures: FaceFeatureDto
    head: AppearanceFeaturesDto
}
export type CharacterStatsCreateRequest = {
    agility: number
    strength: number
    intelligence: number
    perception: number
    stamina: number
}
export type CharacterCreateRequest = {
    characterRequest: CharacterNewRequest
    appearanceRequest: CharacterAppearanceCreateRequest
    statsRequest: CharacterStatsCreateRequest
}
export type VehicleHandling = {
    maxSpeed: number
    steerWheel: number
    acceleration: number
    frontBrakes: number
    rearBrakes: number
    power: number
    tractionCurveMax: number
    tractionCurveMin: number
    tractionLossMult: number
}
export type SinkingValues = {
    h: number
    tr: number
}
export type Sinking = {
    max: SinkingValues
    def: SinkingValues
}
export type CreateVehicleModelRespones = {
    brand: string
    model: string
    type:
        | 0
        | 1
        | 2
        | 3
        | 4
        | 5
        | 6
        | 7
        | 8
        | 9
        | 10
        | 11
        | 12
        | 13
        | 14
        | 15
        | 16
        | 17
        | 18
        | 19
        | 20
        | 21
        | 22
        | 23
        | 24
        | 25
        | 26
        | 27
        | 28
        | 29
        | 30
        | 31
        | 32
        | 33
        | 34
        | 35
    modelName: string
    drive: number
    transmissionType: 0 | 1
    fuelUsage: number
    fuelType: string
    vClass: string
    nitro: boolean
    fuelCapacity: number
    trunkSlots: number
    trunkCapacity: number
    numberOfSeats: number
    price: number
    retailingPlace: string
    asr: boolean
    abs: boolean
    esp: boolean
    autoPilot: boolean
    sportMode: boolean
    launchControl: boolean
    handling: VehicleHandling
    gasTank: any[]
    putedToTrunkPlayerPos: any[]
    pushedVehiclePos: any[]
    sinking: Sinking
    mass: number
}
export type CreateVehicleModelRequest = {
    brand: string
    model: string
    type:
        | 0
        | 1
        | 2
        | 3
        | 4
        | 5
        | 6
        | 7
        | 8
        | 9
        | 10
        | 11
        | 12
        | 13
        | 14
        | 15
        | 16
        | 17
        | 18
        | 19
        | 20
        | 21
        | 22
        | 23
        | 24
        | 25
        | 26
        | 27
        | 28
        | 29
        | 30
        | 31
        | 32
        | 33
        | 34
        | 35
    modelName: string
    drive: number
    transmissionType: 0 | 1
    fuelUsage: number
    fuelType: 'Diesel' | 'Regular' | 'Plus' | 'Premium' | 'Electro'
    vClass: string
    nitro: boolean
    fuelCapacity: number
    trunkSlots: number
    trunkCapacity: number
    numberOfSeats: number
    price: number
    retailingPlace: string
    asr: boolean
    abs: boolean
    esp: boolean
    autoPilot: boolean
    sportMode: boolean
    launchControl: boolean
    handling: VehicleHandling
    gasTank: any[]
    putedToTrunkPlayerPos: any[]
    pushedVehiclePos: any[]
    sinking: Sinking
    mass: number
}
export type Pos = {
    x: number
    y: number
    z: number
    rx: number
    ry: number
    rz: number
}
export type CreateVehicleStateRequest = {
    fuel: number
    fuelType: 'Diesel' | 'Regular' | 'Plus' | 'Premium' | 'Electro'
    asrOn: boolean
    absOn: boolean
    espOn: boolean
    blockOpenHoodOn: boolean
    gear: number
    pos: Pos
}
export type Rgb = {
    r: number
    g: number
    b: number
}
export type VehicleTuningDepartureWheel = {
    front: number
    rear: number
}
export type VehicleTuningCamberWheel = {
    front: number
    rear: number
}
export type CreateVehicleTuningDto = {
    primaryStockColor:
        | 0
        | 1
        | 2
        | 3
        | 4
        | 5
        | 6
        | 7
        | 8
        | 9
        | 10
        | 11
        | 12
        | 13
        | 14
        | 15
        | 16
        | 17
        | 18
        | 19
        | 20
        | 21
        | 22
        | 23
        | 24
        | 25
        | 26
        | 27
        | 28
        | 29
        | 30
        | 31
        | 32
        | 33
        | 34
        | 35
        | 36
        | 37
        | 38
        | 39
        | 40
        | 41
        | 42
        | 43
        | 44
        | 45
        | 46
        | 47
        | 48
        | 49
        | 50
        | 51
        | 52
        | 53
        | 54
        | 55
        | 56
        | 57
        | 58
        | 59
        | 60
        | 61
        | 62
        | 63
        | 64
        | 65
        | 66
        | 67
        | 68
        | 69
        | 70
        | 71
        | 72
        | 73
        | 74
        | 75
        | 76
        | 77
        | 78
        | 79
        | 80
        | 81
        | 82
        | 83
        | 84
        | 85
        | 86
        | 87
        | 88
        | 89
        | 90
        | 91
        | 92
        | 93
        | 94
        | 95
        | 96
        | 97
        | 98
        | 99
        | 100
        | 101
        | 102
        | 103
        | 104
        | 105
        | 106
        | 107
        | 108
        | 109
        | 110
        | 111
        | 112
        | 113
        | 114
        | 115
        | 116
        | 117
        | 118
        | 119
        | 120
        | 121
        | 122
        | 123
        | 124
        | 125
        | 126
        | 127
        | 128
        | 129
        | 130
        | 131
        | 132
        | 133
        | 134
        | 135
        | 136
        | 137
        | 138
        | 139
        | 140
        | 141
        | 142
        | 143
        | 144
        | 145
        | 146
        | 147
        | 148
        | 149
        | 150
        | 151
        | 152
        | 153
        | 154
        | 155
        | 156
        | 157
        | 158
        | 159
    primaryCustomColor: Rgb
    secondaryStockColor:
        | 0
        | 1
        | 2
        | 3
        | 4
        | 5
        | 6
        | 7
        | 8
        | 9
        | 10
        | 11
        | 12
        | 13
        | 14
        | 15
        | 16
        | 17
        | 18
        | 19
        | 20
        | 21
        | 22
        | 23
        | 24
        | 25
        | 26
        | 27
        | 28
        | 29
        | 30
        | 31
        | 32
        | 33
        | 34
        | 35
        | 36
        | 37
        | 38
        | 39
        | 40
        | 41
        | 42
        | 43
        | 44
        | 45
        | 46
        | 47
        | 48
        | 49
        | 50
        | 51
        | 52
        | 53
        | 54
        | 55
        | 56
        | 57
        | 58
        | 59
        | 60
        | 61
        | 62
        | 63
        | 64
        | 65
        | 66
        | 67
        | 68
        | 69
        | 70
        | 71
        | 72
        | 73
        | 74
        | 75
        | 76
        | 77
        | 78
        | 79
        | 80
        | 81
        | 82
        | 83
        | 84
        | 85
        | 86
        | 87
        | 88
        | 89
        | 90
        | 91
        | 92
        | 93
        | 94
        | 95
        | 96
        | 97
        | 98
        | 99
        | 100
        | 101
        | 102
        | 103
        | 104
        | 105
        | 106
        | 107
        | 108
        | 109
        | 110
        | 111
        | 112
        | 113
        | 114
        | 115
        | 116
        | 117
        | 118
        | 119
        | 120
        | 121
        | 122
        | 123
        | 124
        | 125
        | 126
        | 127
        | 128
        | 129
        | 130
        | 131
        | 132
        | 133
        | 134
        | 135
        | 136
        | 137
        | 138
        | 139
        | 140
        | 141
        | 142
        | 143
        | 144
        | 145
        | 146
        | 147
        | 148
        | 149
        | 150
        | 151
        | 152
        | 153
        | 154
        | 155
        | 156
        | 157
        | 158
        | 159
    secondaryCustomColor: Rgb
    tiresType: number
    smokeColor: Rgb
    departureWheel: VehicleTuningDepartureWheel
    camberWheel: VehicleTuningCamberWheel
}
export type VehicleDeformationOffset = {
    x: number
    y: number
    z: number
}
export type VehicleDeformationCreateRequest = {
    offset: VehicleDeformationOffset
    dmg: number
}
export type CreateVehicleRequest = {
    dmvID: string
    equipment: 'minimum' | 'standart' | 'maximum'
    vinNumber: string
    licensePlate: string
    modelId: string
    state: CreateVehicleStateRequest
    tuning: CreateVehicleTuningDto
    deformation: VehicleDeformationCreateRequest
}
export type ShopFindOneDto = {
    uuid: string
    name: string
    description: string
    ownerId: string
    propertyType: string
    img: string
}
export type FindOneBarberShopResponse = {
    img: string
    name: string
    owner: string
}
export const {
    useAuthControllerSignUpMutation,
    useAuthControllerApproveSignUpMutation,
    useAuthControllerLoginMutation,
    useAuthControllerPasswordRecoveryMutation,
    useAuthControllerPasswordRecoveryApproveMutation,
    useAuthControllerPasswordRecoveryUpdateMutation,
    useAuthControllerLogoutMutation,
    useAuthControllerRefreshTokenMutation,
    useClothesControllerCreateManyMutation,
    useClothesControllerCreateOneMutation,
    useRestCharacterControllerFindPlayerCharactersQuery,
    useRestCharacterControllerSelectCharacterMutation,
    useRestCharacterControllerCreateCharacterMutation,
    useRestCharacterControllerDeleteCharacterMutation,
    useRestCharacterControllerRecoverCharacterMutation,
    useVehicleModelControllerCreateVehicleModelMutation,
    useVehicleControllerCreateVehicleMutation,
    useRestShopControllerFindShopQuery,
    useRestBarberShopControllerFindBarberShopQuery,
    useMediaControllerUploadFileMutation,
    useMediaControllerGetFileQuery,
} = injectedRtkApi
