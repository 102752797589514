import { countriesArr } from '@/pages/CharacterPersonalization/screens/About/index.config'
import {
    nationalities,
    weightRange,
    heightRange,
    gaitVariants,
    monthArr,
} from '@/features/PlayerInfoForm/PlayerInfoForm.config'
import { getDayOfBirth } from '@/pages/CharacterPersonalization/screens/About/utils/getDayOfBirth'
import { getRandom } from '@/pages/CharacterPersonalization/screens/About/utils/getRandom'
import { getRandomFullName } from '@/pages/CharacterPersonalization/screens/About/utils/getRandomFullName'
import { createRange } from '@/app/layouts/CharacterPersonalizationSteps/lib/createRange'
import {
    EnGender,
    IAboutSettings,
} from '@/shared/store/slices/personalization/baseSlice'

type Params = {
    aboutSettings: IAboutSettings
}

export const getRandomAboutSettings = ({
    aboutSettings,
}: Params): IAboutSettings => {
    const prevState = { ...aboutSettings }

    prevState.gender = Object.keys(EnGender)[
        Math.floor(Math.random() * Object.keys(EnGender).length)
    ] as EnGender
    prevState.nationality =
        prevState.gender === EnGender.male
            ? nationalities[Math.floor(Math.random() * nationalities.length)]
                  .titles.male
            : nationalities[Math.floor(Math.random() * nationalities.length)]
                  .titles.female
    prevState.country =
        countriesArr[Math.floor(Math.random() * countriesArr.length)].name
    prevState.city = getRandom(
        countriesArr[
            countriesArr.findIndex((item) => item.name === prevState.country)
        ].cities
    )
    prevState.weight = Number(getRandom(createRange(weightRange, 1)))
    prevState.height = Number(getRandom(createRange(heightRange, 0.1)))
    prevState.gait =
        gaitVariants[Math.floor(Math.random() * gaitVariants.length)]
    prevState.age = getRandom(Array.from(createRange([18, 60], 1)))
    prevState.monthOfBirth = getRandom(monthArr)
    prevState.dayOfBirth = getDayOfBirth(prevState.monthOfBirth)
    prevState.firstName = getRandomFullName(
        prevState.gender,
        prevState.nationality
    ).firstName
    prevState.lastName = getRandomFullName(
        prevState.gender,
        prevState.nationality
    ).lastName

    return prevState
}
