import React, { useEffect } from 'react'
import styles from './InteractionSuggestion.module.scss'
import bellIcon from '@/shared/assets/images/vectors/Hud/InteractionSuggestion/bellIcon.svg'
import cn from 'classnames'
import { Request } from './api/types'
import {
    useResponseMutation,
    useInteractionRequestQuery,
    useKeycodeNQuery,
    useKeycodeYQuery,
} from './api/InteractionSuggestionApi'

const InterractionSuggestion = () => {
    const [isVisible, setVisible] = React.useState<boolean>(false)

    const { data: requestData } = useInteractionRequestQuery()
    const [requestState, setRequestState] = React.useState<Request | null>(null)

    useEffect(() => {
        let timer: ReturnType<typeof setTimeout> | null = null
        if (requestData) {
            setRequestState(requestData)
            setVisible(true)
            timer = setTimeout(() => {
                setVisible(false)
                setRequestState(null)
            }, 10000)
        }
        return () => {
            if (timer) clearTimeout(timer)
        }
    }, [requestData])

    const { data: keycodeNData } = useKeycodeNQuery()
    const { data: keycodeYData } = useKeycodeYQuery()
    const [sendResponse] = useResponseMutation()

    useEffect(() => {
        if (keycodeNData || keycodeYData) {
            if (requestState) {
                sendResponse({
                    requestId: requestState?.requestId,
                    isSubmit: !!keycodeYData,
                })
                setVisible(false)
                setRequestState(null)
            }
        }
    }, [keycodeNData, keycodeYData, sendResponse])

    return (
        <div className={cn(styles.wrapper, isVisible && styles.wrapperVisible)}>
            <div className={styles.info}>
                <img src={bellIcon} alt="bellicon" className={styles.bell} />
                <div className={styles.suggestion}>
                    Игрок <span>{requestData?.staticId}</span> отправил вам
                    предложение {requestData?.type}
                </div>
            </div>
            <div className={styles.yes}>Y</div>
            <div className={styles.no}>N</div>
        </div>
    )
}

export default InterractionSuggestion
