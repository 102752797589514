import styles from './PaymentsAndTransfersScreen.module.scss'
import NavButton from '../components/NavButton'
import transfersPic from '@/shared/assets/images/vectors/ATM/shared/transfersPic.svg'
import paymentsPic from '@/shared/assets/images/vectors/ATM/PaymentsAndTransfersScreen/paymentsPic.svg'
import { Screen } from '../ATM.config'
import { FC } from 'react'

interface Props {
    onSelect: (activeScreen: Screen) => void
}

const PaymentsAndTransfers: FC<Props> = ({ onSelect }) => {
    const getTransfers = () => {
        onSelect(Screen.Transfer)
    }

    return (
        <div className={styles.cardShape}>
            <div className={styles.mainInfo}>
                <NavButton
                    title="Переводы"
                    onSelect={getTransfers}
                    pic={transfersPic}
                />
                <NavButton title="Платежи" pic={paymentsPic} />
            </div>
        </div>
    )
}

export default PaymentsAndTransfers
