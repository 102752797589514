import headIcon from '@/shared/assets/images/vectors/TattooShop/_/headIcon.svg'
import backIcon from '@/shared/assets/images/vectors/TattooShop/_/backIcon.svg'
import bodyIcon from '@/shared/assets/images/vectors/TattooShop/_/bodyIcon.svg'
import leftArmIcon from '@/shared/assets/images/vectors/TattooShop/_/leftArmIcon.svg'
import rightArmIcon from '@/shared/assets/images/vectors/TattooShop/_/rightArmIcon.svg'
import leftLegIcon from '@/shared/assets/images/vectors/TattooShop/_/leftLegIcon.svg'
import rightLegIcon from '@/shared/assets/images/vectors/TattooShop/_/rightLegIcon.svg'

export const categories = [
    {
        key: 'head',
        title: 'Голова',
        icon: headIcon,
    },
    {
        key: 'back',
        title: 'Спина',
        icon: backIcon,
    },
    {
        key: 'body',
        title: 'Перед',
        icon: bodyIcon,
    },
    {
        key: 'leftArm',
        title: 'Левая рука',
        icon: leftArmIcon,
    },
    {
        key: 'rightArm',
        title: 'Правая рука',
        icon: rightArmIcon,
    },
    {
        key: 'leftLeg',
        title: 'Левая нога',
        icon: leftLegIcon,
    },
    {
        key: 'rightLeg',
        title: 'Правая нога',
        icon: rightLegIcon,
    },
]
