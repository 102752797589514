import { FC } from 'react'
import styles from './SocialBlock.module.scss'
import { Discord, Facebook, Google, Vk } from '@/shared/icons/generated'

interface SocialBlockProps {
    type?: 'login' | 'registration'
}

export const SocialBlock: FC<SocialBlockProps> = ({ type = 'login' }) => {
    return (
        <div className={styles.container}>
            <div className={styles.title}>
                {type === 'login' ? (
                    'Войти с помощью'
                ) : (
                    <div>
                        Зарегистрироваться <br />с помощью
                    </div>
                )}
            </div>
            <Discord className={styles.icon} />
            <Vk className={styles.icon} />
            <Facebook className={styles.icon} />
            <Google className={styles.icon} />
        </div>
    )
}
