import sportIcon from './assets/sport.svg'
import familyIcon from './assets/family.svg'
import travelerIcon from './assets/traveler.svg'
import stateIcon from './assets/condition.svg'
import foodIcon from './assets/food.svg'
import banditIcon from './assets/bandit.svg'
import loveIcon from './assets/love.svg'
import hobbyIcon from './assets/hobby.svg'
import horizonIcon from './assets/horizon.svg'
import athleteIcon from './assets/athlete.svg'
import baronIcon from './assets/baron.svg'
import bestBartenderIcon from './assets/bestBartender.svg'
import bodybuilderIcon from './assets/bodybuilder.svg'
import careristIcon from './assets/carerist.svg'
import chefIcon from './assets/chef.svg'
import collectorIcon from './assets/collector.svg'
import enemyOfTheStateIcon from './assets/enemyOfTheState.svg'
import engineerIcon from './assets/engineer.svg'
import fabulousWealthIcon from './assets/fabulousWealth.svg'
import fishermanIcon from './assets/fisherman.svg'
import gangsterIcon from './assets/gangster.svg'
import gardenerIcon from './assets/gardener.svg'
import goodHeritageIcon from './assets/goodHeritage.svg'
import gourmetIcon from './assets/gourmet.svg'
import happyFamilyIcon from './assets/happyFamily.svg'
import hippocratesIcon from './assets/hippocrates.svg'
import hunterIcon from './assets/hunter.svg'
import jumanjiIcon from './assets/jumanji.svg'
import lawEnforcerIcon from './assets/lawEnforcer.svg'
import marathonRunnerIcon from './assets/marathonRunner.svg'
import nutritionistIcon from './assets/nutritionist.svg'
import onTwoWheelsIcon from './assets/onTwoWheels.svg'
import personOfTheEraIcon from './assets/personOfTheEra.svg'
import politicianIcon from './assets/politician.svg'
import serialRomanticIcon from './assets/serialRomantic.svg'
import soulmateIcon from './assets/soulmate.svg'
import superParentIcon from './assets/superParent.svg'
import thrillSeekerIcon from './assets/thrillSeeker.svg'
import vegetarianIcon from './assets/vegetarian.svg'
import voyagerIcon from './assets/voyager.svg'
import adventurerIcon from './assets/adventurer.svg'
import healthyLifestyle from './assets/healthyLifestyle.svg'
import { EnCharacterPurpose } from './LifeGoalSelect.typings'

export const lifeGoalArr = [
    {
        id: 1,
        icon: sportIcon,
        title: 'Спорт',
        description: 'lorem ipsum dolor sit amet',
        selected: false,
        subGoals: [
            {
                id: EnCharacterPurpose.Athlete,
                icon: athleteIcon,
                description: 'lorem ipsum dolor sit amet',
            },
            {
                id: EnCharacterPurpose.Bodybuilder,
                icon: bodybuilderIcon,
                description: 'lorem ipsum dolor sit amet',
            },
        ],
    },
    {
        id: 2,
        icon: familyIcon,
        title: 'Создать семью',
        description: 'lorem ipsum dolor sit amet',
        selected: false,
        subGoals: [
            {
                id: EnCharacterPurpose.SuperParent,
                icon: superParentIcon,
                description: 'lorem ipsum dolor sit amet',
            },
            {
                id: EnCharacterPurpose.GoodHeritage,
                icon: goodHeritageIcon,
                description: 'lorem ipsum dolor sit amet',
            },
            {
                id: EnCharacterPurpose.HappyFamily,
                icon: happyFamilyIcon,
                description: 'lorem ipsum dolor sit amet',
            },
        ],
    },
    {
        id: 3,
        icon: foodIcon,
        title: 'Еда',
        description: 'lorem ipsum dolor sit amet',
        selected: false,
        subGoals: [
            {
                id: EnCharacterPurpose.BestBartender,
                icon: bestBartenderIcon,
                description: 'lorem ipsum dolor sit amet',
            },
            {
                id: EnCharacterPurpose.Chef,
                icon: chefIcon,
                description: 'lorem ipsum dolor sit amet',
            },
            {
                id: EnCharacterPurpose.Gourmet,
                icon: gourmetIcon,
                description: 'lorem ipsum dolor sit amet',
            },
        ],
    },
    {
        id: 4,
        icon: stateIcon,
        title: 'Состояние',
        description: 'lorem ipsum dolor sit amet',
        selected: false,
        subGoals: [
            {
                id: EnCharacterPurpose.Collector,
                icon: collectorIcon,
                description: 'lorem ipsum dolor sit amet',
            },
            {
                id: EnCharacterPurpose.FabulousWealth,
                icon: fabulousWealthIcon,
                description: 'lorem ipsum dolor sit amet',
            },
            {
                id: EnCharacterPurpose.Baron,
                icon: baronIcon,
                description: 'lorem ipsum dolor sit amet',
            },
        ],
    },

    {
        id: 5,
        icon: banditIcon,
        title: 'Бандит',
        description: 'lorem ipsum dolor sit amet',
        selected: false,
        subGoals: [
            {
                id: EnCharacterPurpose.EnemyOfTheState,
                icon: enemyOfTheStateIcon,
                description: 'lorem ipsum dolor sit amet',
            },
            {
                id: EnCharacterPurpose.OnTwoWheels,
                icon: onTwoWheelsIcon,
                description: 'lorem ipsum dolor sit amet',
            },
            {
                id: EnCharacterPurpose.Gangster,
                icon: gangsterIcon,
                description: 'lorem ipsum dolor sit amet',
            },
        ],
    },
    {
        id: 6,
        icon: loveIcon,
        title: 'Любовь',
        description: 'lorem ipsum dolor sit amet',
        selected: false,
        subGoals: [
            {
                id: EnCharacterPurpose.Soulmate,
                icon: soulmateIcon,
                description: 'lorem ipsum dolor sit amet',
            },
            {
                id: EnCharacterPurpose.SerialRomantic,
                icon: serialRomanticIcon,
                description: 'lorem ipsum dolor sit amet',
            },
        ],
    },
    {
        id: 7,
        icon: hobbyIcon,
        title: 'Хобби',
        description: 'lorem ipsum dolor sit amet',
        selected: false,
        subGoals: [
            {
                id: EnCharacterPurpose.Fisherman,
                icon: fishermanIcon,
                description: 'lorem ipsum dolor sit amet',
            },
            {
                id: EnCharacterPurpose.Gardener,
                icon: gardenerIcon,
                description: 'lorem ipsum dolor sit amet',
            },
            {
                id: EnCharacterPurpose.Hunter,
                icon: hunterIcon,
                description: 'lorem ipsum dolor sit amet',
            },
        ],
    },
    {
        id: 8,
        icon: horizonIcon,
        title: 'Кругозор',
        description: 'lorem ipsum dolor sit amet',
        selected: true,
        subGoals: [
            {
                id: EnCharacterPurpose.PersonOfTheEra,
                icon: personOfTheEraIcon,
                description: 'lorem ipsum dolor sit amet',
            },
        ],
    },
    {
        id: 9,
        icon: travelerIcon,
        title: 'Путешественник',
        description: 'lorem ipsum dolor sit amet',
        selected: true,
        subGoals: [
            {
                id: EnCharacterPurpose.Voyager,
                icon: voyagerIcon,
                description: 'lorem ipsum dolor sit amet',
            },
        ],
    },
    {
        id: 10,
        icon: careristIcon,
        title: 'Карьерист',
        description: 'lorem ipsum dolor sit amet',
        selected: true,
        subGoals: [
            {
                id: EnCharacterPurpose.Hippocrates,
                icon: hippocratesIcon,
                description: 'lorem ipsum dolor sit amet',
            },
            {
                id: EnCharacterPurpose.Politician,
                icon: politicianIcon,
                description: 'lorem ipsum dolor sit amet',
            },
            {
                id: EnCharacterPurpose.Engineer,
                icon: engineerIcon,
                description: 'lorem ipsum dolor sit amet',
            },
            {
                id: EnCharacterPurpose.LawEnforcer,
                icon: lawEnforcerIcon,
                description: 'lorem ipsum dolor sit amet',
            },
        ],
    },
    {
        id: 12,
        icon: adventurerIcon,
        title: 'Авантюрист',
        description: 'lorem ipsum dolor sit amet',
        selected: true,
        subGoals: [
            {
                id: EnCharacterPurpose.ThrillSeeker,
                icon: thrillSeekerIcon,
                description: 'lorem ipsum dolor sit amet',
            },
            {
                id: EnCharacterPurpose.Jumanji,
                icon: jumanjiIcon,
                description: 'lorem ipsum dolor sit amet',
            },
        ],
    },
    {
        id: 13,
        icon: healthyLifestyle,
        title: 'Здоровый образ',
        description: 'lorem ipsum dolor sit amet',
        selected: true,
        subGoals: [
            {
                id: EnCharacterPurpose.Vegetarian,
                icon: vegetarianIcon,
                description: 'lorem ipsum dolor sit amet',
            },
            {
                id: EnCharacterPurpose.Nutritionist,
                icon: nutritionistIcon,
                description: 'lorem ipsum dolor sit amet',
            },
            {
                id: EnCharacterPurpose.MarathonRunner,
                icon: marathonRunnerIcon,
                description: 'lorem ipsum dolor sit amet',
            },
        ],
    },
]
