import { FC } from 'react'
import { HintsCarousel } from '@/shared/ui/HintsCarousel/HintsCarousel'
import { PageLayout } from '@/shared/ui/PageLayout/PageLayout'
import { AuthForm } from '@/widgets/AuthForm/AuthForm'
import styles from './Auth.module.scss'
import { RegistrationConfirmationModal } from '@/widgets/AuthForm/components/RegistrationConfirmationModal'
import { ForgotPasswordModal } from '@/widgets/AuthForm/components/ForgotPasswordModal'

export const Auth: FC = () => {
    return (
        <PageLayout className={styles.container}>
            <div className={styles.content}>
                <div className={styles.col}>
                    <HintsCarousel />
                </div>
                <div className={styles.col}>
                    <AuthForm />
                </div>
                <RegistrationConfirmationModal />
                <ForgotPasswordModal />
            </div>
        </PageLayout>
    )
}
