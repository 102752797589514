export const RandomIcon = ({
    fill,
    width,
    onSelect,
    action,
}: {
    fill: string
    width: number
    onSelect: (action: string) => void
    action: string
}) => {
    return (
        <svg
            style={{ width: `${width}rem`, aspectRatio: 1 / 1 }}
            width="59"
            height="59"
            viewBox="0 0 59 59"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => onSelect(action)}
        >
            <g clipPath="url(#clip0_715_265)">
                <circle cx="29.5" cy="29.5" r="29.5" fill={fill} />
                <path
                    d="M28.4014 42.2718L36.3674 45.0001L43.3417 38.0261L35.3206 35.3525L28.4014 42.2718ZM31.9884 41.8459C31.5665 42.2678 30.8789 42.4505 30.4522 42.2537C30.026 42.0569 30.022 41.5556 30.4442 41.1336C30.8662 40.7117 31.5537 40.529 31.9804 40.7258C32.407 40.9226 32.4103 41.4239 31.9884 41.8459ZM34.5608 42.747C34.1389 43.1689 33.4513 43.3516 33.0247 43.1547C32.5984 42.9579 32.5944 42.4566 33.0167 42.0347C33.4389 41.6128 34.1262 41.4301 34.5528 41.6269C34.9792 41.8237 34.9828 42.325 34.5608 42.747ZM39.7474 38.7123C40.1694 38.2903 40.857 38.1077 41.2836 38.3045C41.7099 38.5013 41.7139 39.0026 41.292 39.4245C40.8701 39.8465 40.1825 40.0291 39.7558 39.8323C39.3291 39.6355 39.3256 39.1342 39.7474 38.7123ZM37.1826 37.8692C37.6045 37.4472 38.2921 37.2646 38.7188 37.4614C39.1451 37.6582 39.149 38.1595 38.7268 38.5814C38.3049 39.0034 37.6173 39.186 37.1906 38.9892C36.7643 38.7924 36.7607 38.2911 37.1826 37.8692ZM37.1178 42.47C37.544 42.6668 37.548 43.1681 37.1258 43.5901C36.7038 44.012 36.0163 44.1947 35.5896 43.9978C35.1629 43.801 35.1593 43.2997 35.5812 42.8778C36.0035 42.4563 36.6911 42.2736 37.1178 42.47ZM36.1463 36.56C36.5726 36.7568 36.5765 37.2581 36.1543 37.68C35.7324 38.1019 35.0448 38.2846 34.6181 38.0878C34.1919 37.891 34.1879 37.3897 34.6101 36.9677C35.0321 36.5458 35.7197 36.3635 36.1463 36.56Z"
                    fill="black"
                />
                <path
                    d="M43.4713 37.062L40.8376 29.1604L32.936 26.5264L35.5698 34.4275L43.4713 37.062ZM37.604 30.8663C37.8991 30.5712 38.492 30.6854 38.9284 31.1218C39.3648 31.5583 39.4794 32.1513 39.184 32.4463C38.8889 32.7413 38.2959 32.6272 37.8596 32.1907C37.4231 31.7543 37.3086 31.1613 37.604 30.8663Z"
                    fill="black"
                />
                <path
                    d="M24.5696 15L17.1196 18.7248L24.5695 22.4495L32.019 18.7248L24.5696 15ZM24.5696 19.4805C23.9527 19.4805 23.4521 19.1419 23.4521 18.7248C23.4521 18.3072 23.9523 17.969 24.5696 17.969C25.1868 17.969 25.6871 18.3076 25.6871 18.7248C25.6866 19.1423 25.1865 19.4805 24.5696 19.4805Z"
                    fill="black"
                />
                <path
                    d="M26.4845 29.2814C26.8405 29.1502 27.138 29.3579 27.241 29.7559L31.7026 25.2942L32.6092 25.5965V19.4976L25.0469 23.2789V31.95L25.8113 31.1855C25.7334 31.0474 25.6867 30.8683 25.6867 30.6563C25.6867 30.0593 26.0441 29.4438 26.4845 29.2814ZM31.3507 21.1497C31.7915 20.9873 32.1485 21.3393 32.1485 21.9359C32.1485 22.5325 31.7915 23.148 31.3507 23.3104C30.9099 23.4728 30.5529 23.1208 30.5529 22.5242C30.5528 21.9275 30.9099 21.3121 31.3507 21.1497ZM29.0773 25.0531C29.518 24.8907 29.875 25.2427 29.875 25.8393C29.875 26.4359 29.518 27.0514 29.0773 27.2138C28.6365 27.3762 28.2795 27.0242 28.2795 26.4276C28.2795 25.831 28.6368 25.2156 29.0773 25.0531ZM25.6867 24.9237C25.6867 24.3271 26.0437 23.7116 26.4844 23.5492C26.9252 23.3868 27.2822 23.7388 27.2822 24.3354C27.2822 24.9321 26.9252 25.5475 26.4844 25.7099C26.0437 25.8723 25.6867 25.5204 25.6867 24.9237Z"
                    fill="black"
                />
                <path
                    d="M16.5293 29.3601L23.985 33.0116L24.0919 32.9047V23.2789L16.5293 19.4976V29.3601ZM22.6358 29.2814C23.0766 29.4438 23.4336 30.0593 23.4336 30.6559C23.4336 31.2525 23.0766 31.6045 22.6358 31.4421C22.195 31.2797 21.838 30.6642 21.838 30.0676C21.838 29.471 22.1955 29.119 22.6358 29.2814ZM20.3106 24.9324C20.7514 25.0948 21.1084 25.7103 21.1084 26.3069C21.1084 26.9035 20.7514 27.2555 20.3106 27.0931C19.8698 26.9307 19.5128 26.3152 19.5128 25.7186C19.5128 25.122 19.8702 24.77 20.3106 24.9324ZM17.9175 21.1497C18.3583 21.3121 18.7153 21.9275 18.7153 22.5242C18.7153 23.1208 18.3583 23.4728 17.9175 23.3104C17.4768 23.148 17.1197 22.5326 17.1197 21.9359C17.1197 21.3393 17.4768 20.9873 17.9175 21.1497Z"
                    fill="black"
                />
                <path
                    d="M31.9719 26.6558L24.9976 33.6294L27.7262 41.5962L34.6455 34.6762L31.9719 26.6558ZM28.9935 38.9689C28.5715 39.3908 28.0699 39.3872 27.8734 38.9605C27.6769 38.5339 27.8592 37.8463 28.2812 37.4244C28.7031 37.0024 29.2048 37.006 29.4012 37.4324C29.5977 37.8587 29.4154 38.547 28.9935 38.9689ZM31.3205 30.7006C30.8986 31.1225 30.3969 31.1188 30.2004 30.6926C30.0036 30.2663 30.1863 29.5783 30.6082 29.1564C31.0302 28.7345 31.5318 28.7381 31.7283 29.1644C31.9252 29.591 31.7424 30.2786 31.3205 30.7006Z"
                    fill="black"
                />
            </g>
            <defs>
                <clipPath id="clip0_715_265">
                    <rect width="59" height="59" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
