import { FC, useState } from 'react'
import { TClassName } from '@/shared/types/shared'
import classNames from 'classnames'
import { Input } from '../Input'
import { СheckMark } from '@/shared/icons/СheckMark'
import { Button } from '../../Button/Button'
import cls from './index.module.scss'
import { useKeyboard } from '@/shared/hooks/useKeyboard'
import { useFormik } from 'formik'
import { passwordConfirmationSchema } from './validateSchema'
import { useAppDispatch, useAppSelector } from '@/shared/hooks/redux-ts'
import { useAuthControllerPasswordRecoveryUpdateMutation } from '@/shared/store/api/openApi'
import { changeRecoveryUUID } from '@/shared/store/slices/authSlice'
import { hideModal } from '@/shared/store/slices/modalsSlice'
import { FORGOT_PASSWORD_MODAL } from '@/shared/constants'

interface TInputsValues {
    password: string
    confirmationPassword: string
}

interface Props extends TClassName {}
const PasswordChange: FC<Props> = ({ className }) => {
    const dispatch = useAppDispatch()
    const [formError, setFormError] = useState<string>('')
    const recoveryUUID = useAppSelector((state) => state.auth.recoveryUUID)
    const [approvePasswordRecovery] =
        useAuthControllerPasswordRecoveryUpdateMutation()
    const {
        values: { confirmationPassword, password },
        handleChange,
        handleSubmit,
        setFieldValue,
        errors,
    } = useFormik<TInputsValues>({
        initialValues: {
            confirmationPassword: '',
            password: '',
        },
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async ({ password }) => {
            try {
                await approvePasswordRecovery({
                    passwordRecoveryUpdateRequest: {
                        password,
                        passwordResetUuid: recoveryUUID || '',
                    },
                })
                dispatch(changeRecoveryUUID(''))
                dispatch(hideModal({ slug: FORGOT_PASSWORD_MODAL }))
            } catch (error) {
                setFieldValue('password', '')
                setFieldValue('confirmationPassword', '')
                //@ts-expect-error: Не типизированный ответ ошибки
                setFormError(error?.data?.error || 'Непредвиденная ошибка')
            }
        },
        validationSchema: passwordConfirmationSchema,
    })

    const { capsLockEnabled } = useKeyboard()

    return (
        <form
            className={classNames(cls.wrapper, className)}
            onSubmit={handleSubmit}
        >
            <h2 className={cls.title}>Новый пароль</h2>
            <h3 className={cls.subtitle}>
                Укажите новый пароль для Вашего аккаунта
            </h3>
            <div className={cls.password_validate_content}>
                <Input
                    errorMessage={errors.password}
                    value={password}
                    name="password"
                    onChange={handleChange}
                    type="password"
                    className={cls.inp_wrapper}
                    placeholder="Введите пароль"
                    icon={<СheckMark color="#16974A" />}
                />
                <h3
                    className={classNames(cls.caps_lock_state, {
                        [cls.active]: capsLockEnabled,
                    })}
                >
                    Caps Lock
                </h3>
            </div>
            <Input
                errorMessage={errors.confirmationPassword}
                name="confirmationPassword"
                onChange={handleChange}
                value={confirmationPassword}
                type="password"
                className={cls.inp_wrapper}
                placeholder="Подтвердите пароль"
            />
            {formError ? (
                <p className={cls.formErrorMessage}>{formError}</p>
            ) : null}
            <Button
                disabled={
                    password !== confirmationPassword || password.length === 0
                }
                value="Подтвердить"
                type="submit"
                className={cls.button}
            />
        </form>
    )
}

export { PasswordChange }
// Заглавная
// Спец символ
// Длина
