import classNames from 'classnames'
import { FC, FormEventHandler, useState } from 'react'
import { TClassName, TState } from '@/shared/types/shared'
import { Button } from '../../Button/Button'
import { CodeVerificationInput } from '@/shared/ui/VerificationInput'
import { EnModalsSteps } from '..'
import { useAuthControllerPasswordRecoveryApproveMutation } from '@/shared/store/api/openApi'
import cls from './index.module.scss'
import { useAppSelector } from '@/shared/hooks/redux-ts'

const CODE_LENGTH = 6

interface Props extends TClassName {
    setStep: TState<EnModalsSteps>
}
const CodeVerification: FC<Props> = ({ className, setStep }) => {
    const [formError, setFormError] = useState<string>('')
    const [approveRecovery] = useAuthControllerPasswordRecoveryApproveMutation()
    const [verifyCode, setVerifyCode] = useState<string>('')
    const recoveryUUID = useAppSelector((state) => state.auth.recoveryUUID)

    const handleFormSubmit: FormEventHandler = async (e) => {
        e.preventDefault()

        try {
            await approveRecovery({
                passwordRecoveryApproveRequest: {
                    code: verifyCode,
                    passwordResetUuid: recoveryUUID || '',
                },
            })
            setStep(EnModalsSteps.PasswordChanged)
        } catch (error) {
            //@ts-expect-error: Не типизированный ответ ошибки
            setFormError(error?.data?.error || 'Непредвиденная ошибка')
            setVerifyCode('')
        }
    }
    return (
        <form
            className={classNames(cls.wrapper, className)}
            onSubmit={handleFormSubmit}
        >
            <h2 className={cls.title}>Забыли пароль?</h2>
            <h3 className={cls.subtitle}>
                На Ваш почтовый адрес <span>california@gta5rp.ru</span>{' '}
                отправлен код подтверждения для восстановления пароля
            </h3>
            <h3 className={cls.subtitle}>
                Если код не был получен, обратитесь пожалуйста в тех.раздел на
                форуме <span>califronia-rp.ru/techrazdel</span>
            </h3>
            <div className={cls.content}>
                <CodeVerificationInput
                    value={verifyCode}
                    length={CODE_LENGTH}
                    actions={{
                        onChange: (value) => {
                            setVerifyCode(value)
                        },
                    }}
                />
                {formError ? (
                    <p className={cls.formErrorMessage}>{formError}</p>
                ) : null}
                <Button
                    disabled={verifyCode.length !== CODE_LENGTH}
                    type="submit"
                    className={cls.button}
                    value="Подтвердить"
                />
            </div>
        </form>
    )
}

export { CodeVerification }
