import { DeclineIcon } from './Icons/declineIcon'
import { RandomIcon } from './Icons/randomIcon'
import { ConfirmIcon } from './Icons/ConfirmIcon'

export const actionButtons = [
    {
        action: 'random',
        icon: RandomIcon,
    },
    {
        action: 'confirm',
        icon: ConfirmIcon,
    },
    {
        action: 'decline',
        icon: DeclineIcon,
    },
]
