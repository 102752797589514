import { FC } from 'react'
import styles from './CheckBox.module.scss'
import { CheckBoxProps } from './CheckBox.typings'
import { Check, Cross2 } from '@/shared/icons/generated'

const CheckBox: FC<CheckBoxProps> = ({ onClick }) => {
    return (
        <div className={styles.container}>
            <button onClick={onClick} 
                type="submit">
                {' '}
                <Check className={styles.check} />
            </button>
            <button onClick={onClick} 
                type="submit">
                {' '}
                <Cross2 className={styles.cross} />{' '}
            </button>
        </div>
    )
}
export default CheckBox
