import { FC, MouseEventHandler, useState } from 'react'
import { Input } from '../Input/Input'
import Login from '@/shared/icons/generated/Login'
import { PasswordInput } from '../PasswordInput/PasswordInput'
import { useFormik } from 'formik'
import styles from './LoginForm.module.scss'
import { CheckBox } from '../CheckBox/CheckBox'
import { Button } from '../Button/Button'
import { SocialBlock } from '../SocialBlock/SocialBlock'
import { Gap } from '@/shared/ui/Gap/Gap'
import { FORGOT_PASSWORD_MODAL } from '@/shared/constants'
import { showModal } from '@/shared/store/slices/modalsSlice'
import { useAppDispatch } from '@/shared/hooks/redux-ts'
import { useAuthControllerLoginMutation } from '@/shared/store/api/openApi'
import { changeTokens } from '@/shared/store/slices/authSlice.persist'

interface IInputsValues {
    email: string
    password: string
    rememberMe: boolean
}

export const LoginForm: FC = () => {
    const dispatch = useAppDispatch()
    const [formError, setFormError] = useState<string>('')
    const [login] = useAuthControllerLoginMutation()
    const { handleChange, values, handleSubmit, setFieldValue } =
        useFormik<IInputsValues>({
            initialValues: {
                email: '',
                password: '',
                rememberMe: false,
            },

            onSubmit: async ({ email, password }) => {
                try {
                    const { data } = await login({
                        authLoginRequest: { login: email, password },
                    }) // Вызываем мутацию

                    if (data) {
                        const { accessToken, refreshToken } = data
                        dispatch(
                            changeTokens({
                                accessToken,
                                refreshToken,
                            })
                        )
                    } else {
                        setFormError(
                            //@ts-expect-error: Не типизированный ответ ошибки
                            error?.data?.error || 'Непредвиденная ошибка'
                        )
                    }
                } catch (e) {
                    console.error('Error during registration', e)
                }
            },
        })

    const handleRecovery: MouseEventHandler = () => {
        dispatch(showModal({ slug: FORGOT_PASSWORD_MODAL }))
    }

    return (
        <form className={styles.container} onSubmit={handleSubmit}>
            <Input
                Icon={Login}
                placeholder="Логин или пароль"
                onChange={handleChange}
                value={values.email}
                name="email"
            />
            <Gap size={10} />
            <PasswordInput
                onChange={handleChange}
                value={values.password}
                name="password"
            />
            <Gap size={10} />
            <CheckBox
                label="Запомнить меня"
                checked={values.rememberMe}
                onChange={(checked) => setFieldValue('rememberMe', checked)}
            />
            {formError ? (
                <p className={styles.formErrorMessage}>{formError}</p>
            ) : null}
            <Button value="Войти" type="submit" className={styles.button} />
            <div className={styles.forgotPassword}>
                Забыли пароль?{' '}
                <button type="button" onClick={handleRecovery}>
                    Восстановить
                </button>
            </div>
            <Gap size={20} />
            <SocialBlock />
        </form>
    )
}
