import { useState } from 'react'
import styles from './UiSettingsBox.module.scss'
import { Props } from './UiSettingsBox.typings'
import cn from 'classnames'

const UiSettingsBox = (props: Props) => {
    const [radarScale, setRadarScale] = useState<number>(
        Number(localStorage.getItem('radarScale')) ?? 1
    )
    const [remoteScale, setRemoteScale] = useState<number>(
        Number(localStorage.getItem('remoteScale')) ?? 1
    )
    const [plateReaderScale, setPlateReaderScale] = useState<number>(
        Number(localStorage.getItem('plateReaderScale')) ?? 1
    )

    const handleScale = (item: string, action: string) => {
        let x = Number(localStorage.getItem(item))
        if (action === 'increase') {
            if (x < 2) x = Math.round((x + 0.05) * 100) / 100
        } else if (action === 'decrease') {
            if (x > 0.25) x = Math.round((x - 0.05) * 100) / 100
        }
        localStorage.setItem(item, `${String(x)}`)
        if (item === 'radarScale') {
            props.onRadarScale(x)
            setRadarScale(x)
        }
        if (item === 'remoteScale') {
            props.onRemoteScale(x)
            setRemoteScale(x)
        }
        if (item === 'plateReaderScale') {
            props.onPlateReaderScale(x)
            setPlateReaderScale(x)
        }
    }

    const handleSafeZoneChange = (event: React.FormEvent<HTMLInputElement>) => {
        props.onSafeZone(Number(event.currentTarget.value))
        localStorage.setItem('safeZone', String(event.currentTarget.value))
    }

    return (
        <div className={styles.uiSettingsBox}>
            <div className={styles.header}>
                <p className={styles.title}>UI Settings</p>
            </div>
            <div className={styles.scaling_container}>
                <div className={styles.scaling}>
                    <div
                        className={cn(styles.symbol, styles.minus)}
                        onClick={() => handleScale('radarScale', 'decrease')}
                    />
                    <div className={styles.info}>
                        <p>Radar Scale</p>
                        <p className={styles.multiplier}>
                            {radarScale.toFixed(2)}x
                        </p>
                    </div>
                    <div
                        className={cn(styles.symbol, styles.plus)}
                        onClick={() => handleScale('radarScale', 'increase')}
                    />
                </div>
                <div className={styles.scaling}>
                    <div
                        className={cn(styles.symbol, styles.minus)}
                        onClick={() => handleScale('remoteScale', 'decrease')}
                    />
                    <div className={styles.info}>
                        <p>Remote Scale</p>
                        <p className={styles.multiplier}>
                            {remoteScale.toFixed(2)}x
                        </p>
                    </div>
                    <div
                        className={cn(styles.symbol, styles.plus)}
                        onClick={() => handleScale('remoteScale', 'increase')}
                    />
                </div>
                <div className={styles.scaling}>
                    <div
                        className={cn(styles.symbol, styles.minus)}
                        onClick={() =>
                            handleScale('plateReaderScale', 'decrease')
                        }
                    />
                    <div className={styles.info}>
                        <p>Reader Scale</p>
                        <p className={styles.multiplier}>
                            {plateReaderScale.toFixed(2)}x
                        </p>
                    </div>
                    <div
                        className={cn(styles.symbol, styles.plus)}
                        onClick={() =>
                            handleScale('plateReaderScale', 'increase')
                        }
                    />
                </div>
            </div>
            <div className={styles.safezone_container}>
                <p>
                    Safezone: <span>{props.safeZone}px</span>
                </p>
                <input
                    type="range"
                    min="0"
                    max="50"
                    value={props.safeZone}
                    step="5"
                    className={styles.slider}
                    onChange={handleSafeZoneChange}
                />
            </div>
            <button
                type="submit"
                className={styles.close}
                onClick={() => props.onClose(false)}
            >
                CLOSE
            </button>
        </div>
    )
}

export default UiSettingsBox
