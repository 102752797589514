import { FC, forwardRef, InputHTMLAttributes, ReactNode } from 'react'
import cls from './index.module.scss'
import classNames from 'classnames'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    icon?: ReactNode
    inputClassName?: string
    errorMessage?: string
}
const Input: FC<Props> = forwardRef<HTMLInputElement, Props>(
    ({ icon, className, inputClassName, errorMessage, ...inpProps }, ref) => {
        return (
            <div className={classNames(cls.wrapper, className)}>
                <div className={classNames(cls.content)}>
                    <input
                        {...inpProps}
                        ref={ref}
                        className={classNames(cls.input, inputClassName)}
                    />
                    {icon ? icon : null}
                </div>
                {errorMessage ? (
                    <h2 className={cls.error}>{errorMessage}</h2>
                ) : null}
            </div>
        )
    }
)

Input.displayName = 'Input'

export { Input }
