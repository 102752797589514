import { ChangeEventHandler, FC } from 'react'
import cls from './index.module.scss'
import { TClassName } from '@/shared/types/shared'
import { CharacterPropertyContent } from '@/widgets/CharacterPropertyContent'
import classNames from 'classnames'
import { SliderInput } from '@/shared/ui/SliderInput'
import { useAppDispatch, useAppSelector } from '@/shared/hooks/redux-ts'
import { pxToRem } from '@/shared/lib/pxToRem'
import {
    changeCheekDepth,
    changeCheekWidth,
} from '@/shared/store/slices/personalization/head/cheekSlice'

interface Props extends TClassName {}
const CharacterCheekContent: FC<Props> = ({ className }) => {
    const dispatch = useAppDispatch()
    const cheekWidth = useAppSelector(
        (state) => state.personalization.head.cheek.cheekWidth
    )
    const cheekDepth = useAppSelector(
        (state) => state.personalization.head.cheek.cheekDepth
    )
    const handleCheekDepth: ChangeEventHandler<HTMLInputElement> = (event) => {
        dispatch(changeCheekDepth(+event.target.value / 100))
    }
    const handleCheekWidth: ChangeEventHandler<HTMLInputElement> = (event) => {
        dispatch(changeCheekWidth(+event.target.value / 100))
    }

    return (
        <CharacterPropertyContent
            items={[
                {
                    content: (
                        <SliderInput
                            thumbSize={pxToRem(20)}
                            min={0}
                            max={100}
                            step={10}
                            defaultValue={cheekDepth * 100}
                            onChange={handleCheekDepth}
                        />
                    ),
                    title: 'Глубина щеки',
                },
                {
                    content: (
                        <SliderInput
                            thumbSize={pxToRem(20)}
                            min={0}
                            max={100}
                            step={10}
                            defaultValue={cheekWidth * 100}
                            onChange={handleCheekWidth}
                        />
                    ),
                    title: 'Ширина щеки',
                },
            ]}
            title="Щеки"
            className={classNames(cls.wrapper, className)}
        />
    )
}

export { CharacterCheekContent }
