import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IInitialState {
    variant?: number
    color?: string
}
const initialState: IInitialState = {}

const torsSlice = createSlice({
    name: 'tors',
    initialState,
    reducers: {
        changeVariant: (state, { payload }: PayloadAction<number>) => {
            state.variant = payload
        },
        changeColor: (state, { payload }: PayloadAction<string>) => {
            state.color = payload
        },
    },
})

export const { changeColor, changeVariant } = torsSlice.actions
export default torsSlice.reducer
