import { FC, ReactNode } from 'react'
import { TClassName } from '@/shared/types/shared'
import { CharacterWheel } from '@/shared/ui/CharacterWheel'
import cls from './index.module.scss'
import classNames from 'classnames'
import { useAppSelector } from '@/shared/hooks/redux-ts'
import { CharacterGeneticContent } from '@/widgets/CharacterGeneticContent'
import {
    EnActiveAttribute,
    EnHairProperties,
    EnHeadProperties,
} from '@/shared/store/slices/personalization/viewSlice'
import { returnHairComponents, returnHeadComponents } from './libs'

interface Props extends TClassName {}
const ViewScreen: FC<Props> = ({ className }) => {
    let renderedAttributeWheel: ReactNode = null
    const activeAttribute = useAppSelector(
        (state) => state.personalization.view.activeAttribute
    )
    const activeProperty = useAppSelector(
        (state) => state.personalization.view.activeProperty
    )

    switch (activeAttribute) {
        case EnActiveAttribute.Genetic:
            renderedAttributeWheel = <CharacterGeneticContent />
            break
    }

    const headProperties = returnHeadComponents(
        activeProperty as EnHeadProperties,
        activeAttribute
    )
    const hairProperty = returnHairComponents(
        activeProperty as EnHairProperties,
        activeAttribute
    )

    return (
        <div className={classNames(cls.wrapper, className)}>
            <CharacterWheel />
            {renderedAttributeWheel}
            {headProperties || hairProperty}
        </div>
    )
}

export { ViewScreen }
