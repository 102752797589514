import cls from './index.module.scss'
import 'swiper/css'
import { FC, useRef } from 'react'
import { TClassName } from '@/shared/types/shared'
import { TriangleIcon } from '@/shared/icons/TriangleIcon'
import classNames from 'classnames'
import { SwiperSlide, Swiper, SwiperClass } from 'swiper/react'
import { fatherSliderConfig, motherSliderConfig } from '../../config/sliders'

interface Props extends TClassName {
    type: 'mother' | 'father'
}
const Slider: FC<Props> = ({ className, type }) => {
    const config = type === 'father' ? fatherSliderConfig : motherSliderConfig
    const swiperRef = useRef<SwiperClass | null>(null)

    const handleClickSlide = (index: number) => {
        if (swiperRef.current) {
            swiperRef.current.slideToLoop(index)
        }
    }

    return (
        <div className={classNames(cls.wrapper, className)}>
            <h2 className={cls.title}>{config.title}</h2>
            <button
                className={classNames(cls.slider_btn, cls.prev_btn)}
                id={config.btns.prevEl}
            >
                <TriangleIcon fill="var(--whiteBase)" />
            </button>
            <Swiper
                {...config.slider}
                className={cls.slider}
                onSwiper={(swiper: SwiperClass) => {
                    swiperRef.current = swiper
                }}
            >
                {config.slides.map(({ id, image }, index) => (
                    <SwiperSlide
                        key={id}
                        className={cls.slide}
                        onClick={() => {
                            handleClickSlide(index)
                        }}
                    >
                        {({ isActive }) => (
                            <div
                                className={classNames(cls.slide_content, {
                                    [cls.slide_active]: isActive,
                                })}
                            >
                                <img src={image} alt="father slide image" />
                            </div>
                        )}
                    </SwiperSlide>
                ))}
            </Swiper>
            <button
                className={classNames(cls.slider_btn, cls.next_btn)}
                id={config.btns.nextEl}
            >
                <TriangleIcon fill="var(--whiteBase)" />
            </button>
        </div>
    )
}

export { Slider }
