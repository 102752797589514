import { MouseEvent as MouseEventReact, MutableRefObject } from 'react'

interface CardRefs {
    [key: number]: HTMLDivElement | null
}

export const handleMouseDownCard = (
    e: MouseEventReact,
    id: number,
    cardRefs: MutableRefObject<CardRefs>,
    wrapperRef: MutableRefObject<HTMLDivElement | null>,
    posRef: MutableRefObject<null | HTMLDivElement>,
    cardsPlaceRef: MutableRefObject<null | HTMLDivElement>,
    onSuccessPay: (isCash: boolean) => void
) => {
    const cardRef = cardRefs.current[id]
    if (cardRef) {
        cardRef.ondragstart = () => {
            return false
        }
        const shiftX = e.clientX - cardRef.getBoundingClientRect().left
        const shiftY = e.clientY - cardRef.getBoundingClientRect().top
        cardRef.style.position = 'absolute'
        cardRef.style.zIndex = '1000'
        cardRef.style.left = `${e.clientX - shiftX}px`
        cardRef.style.top = `${e.clientY - shiftY}px`

        if (wrapperRef.current) wrapperRef.current.append(cardRef)

        const onMouseMove = (moveEvent: MouseEvent) => {
            cardRef.style.left = `${moveEvent.clientX - shiftX}px`
            cardRef.style.top = `${moveEvent.clientY - shiftY}px`
        }

        const onMouseUp = () => {
            if (posRef.current) {
                const posRect = posRef.current.getBoundingClientRect()
                const cardRect = cardRef.getBoundingClientRect()
                const isOverPos =
                    cardRect.top > posRect.top - 100 &&
                    cardRect.left > posRect.left - 100 &&
                    cardRect.bottom < posRect.bottom + 100 &&
                    cardRect.right < posRect.right + 100

                if (isOverPos) {
                    onSuccessPay(false)
                } else {
                    if (cardsPlaceRef.current)
                        cardsPlaceRef.current.append(cardRef)
                    cardRef.style.position = 'static'
                }
            }
            document.removeEventListener('mousemove', onMouseMove)
            document.removeEventListener('mouseup', onMouseUp)
        }

        document.addEventListener('mousemove', onMouseMove)
        document.addEventListener('mouseup', onMouseUp)
    }
}
