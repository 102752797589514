import {
    usernameValidate,
    passwordValidate,
    confirmationPasswordValidate,
    emailValidate,
} from '@/shared/utils/validateMethods'
import * as Yup from 'yup'

export const signupSchema = Yup.object().shape({
    username: usernameValidate,
    password: passwordValidate,
    passwordRepeat: confirmationPasswordValidate('password'),
    email: emailValidate,
})
