import { ChangeEventHandler, FC, FormEventHandler, useState } from 'react'
import cls from './index.module.scss'
import { TState } from '@/shared/types/shared'
import { EnModalsSteps } from '..'
import { Input } from '../Input'
import { Button } from '../../Button/Button'
import { useAuthControllerPasswordRecoveryMutation } from '@/shared/store/api/openApi'
import { useAppDispatch } from '@/shared/hooks/redux-ts'
import { changeRecoveryUUID } from '@/shared/store/slices/authSlice'

interface Props {
    setStep: TState<EnModalsSteps>
}
const EnterIdentifier: FC<Props> = ({ setStep }) => {
    const dispatch = useAppDispatch()
    const [identifier, setIdentifier] = useState<string>('')
    const [formError, setFormError] = useState<string>('')
    const [recovery] = useAuthControllerPasswordRecoveryMutation()

    const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        setIdentifier(event.target.value)
    }
    const handleFormSubmit: FormEventHandler = async (event) => {
        event.preventDefault()
        try {
            const { data } = await recovery({
                passwordRecoveryRequest: {
                    emailOrUsername: identifier,
                },
            })
            if (data) {
                setFormError('')
                dispatch(changeRecoveryUUID(data.passwordResetUuid))
                setStep(EnModalsSteps.Verification)
            } else {
                setIdentifier('')
                //@ts-expect-error: Не типизированный ответ ошибки
                setFormError(error?.data?.error || 'Непредвиденная ошибка')
            }
        } catch (error) {
            setIdentifier('')
            //@ts-expect-error: Не типизированный ответ ошибки
            setFormError(error?.data?.error || 'Непредвиденная ошибка')
        }
    }

    return (
        <form className={cls.wrapper} onSubmit={handleFormSubmit}>
            <h2 className={cls.title}>Забыли пароль?</h2>
            <h3 className={cls.subtitle}>
                Введите почту или ваш username аккаунта для восстановления
            </h3>
            <div className={cls.content}>
                <Input
                    errorMessage={formError}
                    value={identifier}
                    name="username"
                    onChange={handleChange}
                    type="text"
                    className={cls.inp_wrapper}
                    placeholder="Введите почту или username"
                />
                <Button
                    disabled={identifier.length < 6}
                    type="submit"
                    className={cls.button}
                    value="Подтвердить"
                />
            </div>
        </form>
    )
}

export { EnterIdentifier }
