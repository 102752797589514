import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IChangeTokens {
    refreshToken: string
    accessToken: string
}

interface IInitialState extends IChangeTokens {}

const initialState: IInitialState = {
    accessToken: '',
    refreshToken: '',
}

const authSlicePersist = createSlice({
    name: 'authTokens',
    initialState,
    reducers: {
        changeTokens(
            state,
            {
                payload: { accessToken, refreshToken },
            }: PayloadAction<IChangeTokens>
        ) {
            state.accessToken = accessToken
            state.refreshToken = refreshToken
        },
    },
})

export const { changeTokens } = authSlicePersist.actions
export default authSlicePersist.reducer
