import { MouseEvent } from 'react'
import styles from './SubGoals.module.scss'
import { EnCharacterPurpose } from '@/pages/CharacterPersonalization/screens/About/components/LifeGoalSelect/LifeGoalSelect.typings'
import { type TSubGoals } from '../GoalSelectItem.typings'
import { useAppDispatch } from '@/shared/hooks/redux-ts'
import { changeMainContentVisible } from '@/shared/store/slices/personalization/personalizationSlice'
import {
    EnGoals,
    ILifeGoals,
    visibleComponentTraitSelect,
} from '@/shared/store/slices/personalization/baseSlice'

interface Props {
    subGoals: TSubGoals[]
    onSelect: (type: EnGoals, id: number | EnCharacterPurpose) => void
    lifeGoals: ILifeGoals
    closeTiles: React.Dispatch<React.SetStateAction<boolean>>
}
const SubGoals = ({ closeTiles, lifeGoals, onSelect, subGoals }: Props) => {
    const dispatch = useAppDispatch()
    const handleSelect = (
        event: MouseEvent,
        id: number | EnCharacterPurpose
    ) => {
        event.stopPropagation()
        onSelect(EnGoals.additionalGoal, id)
        dispatch(visibleComponentTraitSelect())
        dispatch(changeMainContentVisible(false))
        closeTiles(false)
    }

    return (
        <div className={styles.wrapper}>
            {subGoals.map((item) => {
                return (
                    <div
                        key={item.id}
                        className={styles.tile}
                        onClick={(event) => handleSelect(event, item.id)}
                    >
                        <img src={item.icon} />
                    </div>
                )
            })}
        </div>
    )
}

export default SubGoals
