import { Autoplay, Navigation } from 'swiper/modules'
import { SwiperProps } from 'swiper/react'
import { FATHER_SLIDES, MOTHER_SLIDES } from '../constants/slides'

type TButtonsProps = {
    nextEl: string
    prevEl: string
}
type TSliderConfig = (
    navigationElements: TButtonsProps
) => Omit<SwiperProps, 'className'>

const sliderConfig: TSliderConfig = ({ nextEl, prevEl }) => ({
    slidesPerView: 4,
    loop: true,
    direction: 'vertical',
    speed: 500,
    modules: [Navigation],
    autoHeight: true,
    navigation: {
        prevEl: `#${prevEl}`,
        nextEl: `#${nextEl}`,
    },
})

const fatherSliderBtns: TButtonsProps = {
    nextEl: 'bq0jd9jdi3210jdn',
    prevEl: 'a082sis3wm5po12m',
}
const motherSliderBtns: TButtonsProps = {
    nextEl: 'cmwqimodeim80921',
    prevEl: 'moixqsio92dmiw20',
}

const motherSliderConfig = {
    title: 'Мама',
    slider: sliderConfig(motherSliderBtns),
    slides: MOTHER_SLIDES,
    btns: motherSliderBtns,
} as const
const fatherSliderConfig = {
    title: 'Папа',
    slider: sliderConfig(fatherSliderBtns),
    slides: FATHER_SLIDES,
    btns: fatherSliderBtns,
} as const

export { motherSliderConfig, fatherSliderConfig }
