export enum Tickets {
    reports,
    moderation,
    experts,
}
export const blocks = [
    {
        key: Tickets.reports,
        title: 'Репорты:',
    },
    {
        key: Tickets.moderation,
        title: 'Модерация:',
    },
    {
        key: Tickets.experts,
        title: 'Эксперт:',
    },
]
