import { useEffect, useState } from 'react'
import rpc from 'altv-rpc'

export const useHudPenaltyWidgetData = () => {
    interface HudPenaltyInfo {
        data: {
            show: boolean
            penaltyNumber: number
            penaltyTime: Date
        }
    }

    const [hudPenaltyInfo, setHudPenaltyInfo] = useState<HudPenaltyInfo>({
        data: {
            show: false,
            penaltyNumber: 0,
            penaltyTime: new Date(1970, 0, 1, 0, 0, 0),
        },
    })

    const callback = (data: HudPenaltyInfo) => {
        setHudPenaltyInfo(data)
    }

    const event = 'hud:penalty'

    useEffect(() => {
        rpc.on(event, callback)
        return () => rpc.off(event, callback)
    }, [])

    return hudPenaltyInfo.data
}
