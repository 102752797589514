import { FC } from 'react'
import { TChildren, TClassName } from '@/shared/types/shared'
import classNames from 'classnames'
import cls from './index.module.scss'

interface Props extends TClassName, TChildren {}
const CharacterPropertiesWrapper: FC<Props> = ({ className, children }) => {
    return <div className={classNames(cls.wrapper, className)}>{children}</div>
}

export { CharacterPropertiesWrapper }
