import { EnColors } from '@/shared/constants/character/hairsColors'
import { COLORS } from '@/shared/constants/character/hairsColors'
import { TCharacterVariantItem } from '@/widgets/CharacterVariantsWrapper/ui'
import { CSSProperties } from 'react'

const COLORS_ITEMS = (
    handleColorSelect: (id: EnColors) => void,
    itemClassName?: string
) =>
    COLORS.map<TCharacterVariantItem<EnColors>>(({ rgb, id }) => ({
        content: (
            <div
                className={itemClassName}
                style={
                    {
                        '--color': rgb,
                    } as CSSProperties
                }
            />
        ),
        id,
        onSelect: () => {
            handleColorSelect(id)
        },
        isActive: false,
    }))

export { COLORS_ITEMS }
