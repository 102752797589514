import { useEffect, useState } from 'react'

export const useKeyboard = () => {
    const [capsLockEnabled, setCapsLockEnabled] = useState<boolean>(false)

    useEffect(() => {
        const handler = (e: KeyboardEvent) => {
            setCapsLockEnabled(
                e.getModifierState && e.getModifierState('CapsLock')
            )
        }
        document.addEventListener('keydown', handler)

        return () => {
            document.removeEventListener('keydown', handler)
        }
    })

    return { capsLockEnabled }
}
