import { FC, memo, useEffect, useState } from 'react'
import cls from './index.module.scss'
import { CircleItem } from '@/shared/ui/CharacterWheel/components/CircleItem/CircleItem'
import { getWheelCoords } from '@/shared/ui/CharacterWheel/components/utils/getWheelCoords'
import { useAppDispatch } from '@/shared/hooks/redux-ts'
import { TClassName } from '@/shared/types/shared'
import classNames from 'classnames'
import {
    changeProperty,
    TActiveProperty,
} from '@/shared/store/slices/personalization/viewSlice'
import { THasPropertyItem } from '../../types'
import { pxToRem } from '@/shared/lib/pxToRem'

export enum Property {
    Torso,
    Leg,
}

interface Props extends TClassName {
    activeProperty?: TActiveProperty
    hasPropertyItem: THasPropertyItem
}

export const PropertyWheel: FC<Props> = memo(
    ({
        className,
        activeProperty,
        hasPropertyItem: { item: attributeItem },
    }) => {
        const dispatch = useAppDispatch()
        const [isMount, setIsMounted] = useState<boolean>(false)

        useEffect(() => {
            const timeout = setTimeout(() => {
                setIsMounted(true)
            }, 0)

            return () => {
                clearTimeout(timeout)
            }
        }, [])

        const arr = getWheelCoords({
            count: attributeItem[1].length,
            radius: 500,
            bias: -8,
            totalCount: 20,
        })

        return (
            <div
                className={classNames(
                    cls.container,
                    {
                        [cls.isMounted]: isMount,
                    },
                    className
                )}
            >
                <svg
                    className={cls.image}
                    viewBox="0 0 1095 1080"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle
                        opacity="0.3"
                        cx="525.5"
                        cy="539.5"
                        r="508"
                        stroke="url(#paint0_linear_214_908)"
                        strokeWidth="123"
                    />
                    <defs>
                        <linearGradient
                            id="paint0_linear_214_908"
                            x1="1329.05"
                            y1="498.685"
                            x2="544.632"
                            y2="503.787"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop />
                            <stop offset="1" stopOpacity="0" />
                        </linearGradient>
                    </defs>
                </svg>

                <div className={cls.items}>
                    {arr.map(({ x, y }, i) => {
                        const { slug, icon } = attributeItem[1][i]

                        return (
                            <div
                                key={`${slug}${x}${y}`}
                                style={{
                                    top: `${pxToRem(y)}rem`,
                                    left: `${pxToRem(x)}rem`,
                                }}
                                className={cls.item}
                            >
                                <CircleItem
                                    Icon={icon}
                                    isActive={slug === activeProperty}
                                    onClick={() =>
                                        dispatch(changeProperty(slug))
                                    }
                                />
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
)

PropertyWheel.displayName = 'PropertyWheel'
