import { FC, ReactNode } from 'react'
import cls from './index.module.scss'
import { TClassName } from '@/shared/types/shared'
import { CharacterPropertiesWrapper } from '@/widgets/CharacterPropertiesWrapper'
import classNames from 'classnames'

interface Props extends TClassName {
    title: string
    items: {
        title: string
        content: ReactNode
    }[]
}
const CharacterPropertyContent: FC<Props> = ({ className, items, title }) => {
    return (
        <CharacterPropertiesWrapper
            className={classNames(cls.wrapper, className)}
        >
            <h2 className={cls.title}>{title}</h2>
            <ul className={cls.group}>
                {items.map(({ content, title }) => (
                    <li className={cls.item} key={title}>
                        <h3 className={cls.item_title}>{title}</h3>
                        {content}
                    </li>
                ))}
            </ul>
        </CharacterPropertiesWrapper>
    )
}

export { CharacterPropertyContent }
