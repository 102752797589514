import { FC } from 'react'
import styles from './Tachometer.module.scss'
import { TachometerSvg } from '@/entities/AutoSensors/components/TachometerSvg/TachometerSvg'

interface Props {
    rotations: number
    maxRotations: number
}

export const Tachometer: FC<Props> = ({ rotations, maxRotations }) => {
    const formattedRpm = Number(rotations / 1000).toFixed()

    return (
        <div className={styles.container}>
            <TachometerSvg
                percent={rotations / maxRotations}
                className={styles.tachometer}
            />
            <div className={styles.rotations}>{formattedRpm}</div>
            <div className={styles.subTitle}>x 1000 RPM</div>
        </div>
    )
}
