import { useState, useRef, useEffect, ReactElement } from 'react'
import styles from './PaymentSystem.module.scss'
import { Props, Card } from './PaymentSystem.typings'
import { formatNumbers } from './utils/formatNumbers'
import cashGetterLogo from '@/shared/assets/images/vectors/PaymentSystem/_/cashGetterLogo.png'
import nfcImage from '@/shared/assets/images/vectors/PaymentSystem/_/nfcImage.png'
import walletImage from '@/shared/assets/images/illustrations/PaymentSystem/_/walletImage.webp'
import exampleBackground from '@/shared/assets/images/backgrounds/PaymentSystem/_/exampleBackground.webp'
import PosScreen from './PosScreen/PosScreen'
import BeforePaymentScreen from './PosScreen/screens/BeforePaymentScreen/BeforePaymentScreen'
import AfterPaymentScreen from './PosScreen/screens/AfterPaymentScreen/AfterPaymentScreen'
import CardItem from './Card/Card'
import { handleMouseDownCard } from './utils/handleMouseDownCard'
import { handleMouseDownCash } from './utils/handleMouseDownCash'
import { handleMouseDownPhone } from './utils/handleMouseDownPhone'

const PaymentSystem = (props: Props) => {
    const screens = {
        beforePaymentScreen: <BeforePaymentScreen summ={props.paymentValue} />,
        afterPaymentScreen: <AfterPaymentScreen summ={props.paymentValue} />,
    }

    const [activeScreen, setActiveScreen] = useState<ReactElement>(
        screens.beforePaymentScreen
    )
    const [cardsArr] = useState<Card[]>([
        {
            id: 1,
            number: 1203543356534590,
            date: '23/09',
            name: 'ALEXANDR_ISMAILOV',
            background: exampleBackground,
        },
        {
            id: 2,
            number: 2203543356534590,
            date: '23/09',
            name: 'ALEXANDR_ISMAILOV',
            background: exampleBackground,
        },
        {
            id: 3,
            number: 3203543356534590,
            date: '23/09',
            name: 'ALEXANDR_ISMAILOV',
            background: exampleBackground,
        },
        {
            id: 4,
            number: 4203543356534590,
            date: '23/09',
            name: 'ALEXANDR_ISMAILOV',
            background: exampleBackground,
        },
        {
            id: 5,
            number: 5203543356534590,
            date: '23/09',
            name: 'ALEXANDR_ISMAILOV',
            background: exampleBackground,
        },
    ])

    const cardRefs = useRef<{ [key: number]: HTMLDivElement | null }>({})
    const wrapperRef = useRef<null | HTMLDivElement>(null)
    const posRef = useRef<null | HTMLDivElement>(null)
    const cardsPlaceRef = useRef<null | HTMLDivElement>(null)
    const cashImageRef = useRef<null | HTMLDivElement>(null)
    const cashGetterRef = useRef<null | HTMLDivElement>(null)
    const walletRef = useRef<null | HTMLDivElement>(null)
    const phoneRef = useRef<null | HTMLDivElement>(null)

    const [redirectTimer, setRedirectTimer] = useState<number | null>(null)

    const onSuccessPay = (isCash: boolean) => {
        if (!isCash) setActiveScreen(screens.afterPaymentScreen)
        const timer = window.setTimeout(
            () => (window.location.href = '/'),
            2000
        )
        setRedirectTimer(timer)
        return () => clearTimeout(timer)
    }

    useEffect(() => {
        return () => {
            if (redirectTimer !== null) {
                window.clearTimeout(redirectTimer)
            }
        }
    }, [redirectTimer])

    const setCardRef = (element: HTMLDivElement | null, id: number) => {
        if (element) {
            cardRefs.current[id] = element
        }
    }

    return (
        <div className={styles.wrapper} ref={wrapperRef}>
            <div className={styles.paymentInfoBlock}>
                <div className={styles.paymentInfoBlockTitle}>
                    Произведите оплату
                </div>
                <div className={styles.paymentValueBlock}>
                    <div className={styles.paymentValueTitle}>
                        Сумма покупки:
                    </div>
                    <div className={styles.paymentValue}>
                        $ {formatNumbers(props.paymentValue)}
                    </div>
                </div>
                <div className={styles.rightTooltip}>
                    <div className={styles.rightTooltipTitle}>
                        Терминал оплаты
                    </div>
                    <div className={styles.rightTooltipText}>
                        Приложите свою банковскую карту, либо
                        <br />
                        устройство к терминалу для оплаты.
                    </div>
                </div>
            </div>
            <div className={styles.terminal}>
                <div className={styles.leftTooltip}>
                    <div className={styles.leftTooltipTitle}>
                        Оплата наличными
                    </div>
                    <div className={styles.leftTooltipText}>
                        Достаньте купюру из своего кошелька
                        <br />и приложите её на место приёма
                        <br />
                        наличных средств оплаты.
                    </div>
                </div>
                <div className={styles.cashGetter} ref={cashGetterRef}>
                    <img
                        src={cashGetterLogo}
                        className={styles.cashGetterLogo}
                    />
                </div>
                <div className={styles.pos} ref={posRef}>
                    <img src={nfcImage} className={styles.nfc} />
                    <div className={styles.posScreen}>
                        <PosScreen>{activeScreen}</PosScreen>
                    </div>
                </div>
            </div>
            <div className={styles.cardsPlace} ref={cardsPlaceRef}>
                {cardsArr.map((card) => {
                    return (
                        <div
                            className={styles.cardWrapper}
                            key={card.id}
                            ref={(el) => setCardRef(el, card.id)}
                            onMouseDown={(e) =>
                                handleMouseDownCard(
                                    e,
                                    card.id,
                                    cardRefs,
                                    wrapperRef,
                                    posRef,
                                    cardsPlaceRef,
                                    onSuccessPay
                                )
                            }
                        >
                            <CardItem {...card} />
                        </div>
                    )
                })}
            </div>
            <div className={styles.wallet} ref={walletRef}>
                <img src={walletImage} className={styles.walletImage} />
            </div>
            <div
                className={styles.cashImage}
                ref={cashImageRef}
                onMouseDown={(e) =>
                    handleMouseDownCash(
                        e,
                        cashImageRef,
                        cashGetterRef,
                        posRef,
                        walletRef,
                        onSuccessPay
                    )
                }
            />
            <div
                className={styles.phone}
                ref={phoneRef}
                onMouseDown={(e) =>
                    handleMouseDownPhone(e, phoneRef, posRef, onSuccessPay)
                }
            />
        </div>
    )
}

export default PaymentSystem
