import { FC, useMemo } from 'react'
import { AttributeWheel } from '@/shared/ui/CharacterWheel/components/AttributeWheel/AttributeWheel'
import { PropertyWheel } from '@/shared/ui/CharacterWheel/components/PropertyWheel'
import styles from './index.module.scss'
import { Props, THasPropertyItem } from './types'
import classNames from 'classnames'
import { useAppSelector } from '@/shared/hooks/redux-ts'
import { STRUCTURE } from './constants/structure'

export const CharacterWheel: FC<Props> = ({ className }) => {
    const activeProperty = useAppSelector(
        (state) => state.personalization.view.activeProperty
    )
    const activeAttribute = useAppSelector(
        (state) => state.personalization.view.activeAttribute
    )

    const hasPropertyItem = useMemo<THasPropertyItem>(() => {
        const item = STRUCTURE.find(
            (attribute) => attribute[0].slug === activeAttribute
        )
        return { has: !!item?.[1].length, item } as THasPropertyItem
    }, [activeAttribute])

    return (
        <div className={classNames(styles.container, className)}>
            {hasPropertyItem.has ? (
                <PropertyWheel
                    hasPropertyItem={hasPropertyItem}
                    activeProperty={activeProperty}
                    className={styles.propertyWheel}
                />
            ) : null}

            <AttributeWheel activeAttribute={activeAttribute} />
        </div>
    )
}
