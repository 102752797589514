import { useState } from 'react'
import { ProductCards, Props } from './Store.typings'
import classNames from 'classnames'
import ProductCard from './components/ProductCard/ProductCard'
import Cart from './components/Cart/Cart'
import PaymentSelect from './components/PaymentSelect/PaymentSelect'
import { Customer } from './components/PaymentSelect/PaymentSelect'
import { makeAPurchase } from './modules/makeAPurchase'
import InputElements from './components/InputElements/InputElements'
import cartLogo from '@/shared/assets/images/vectors/Store/_/cartLogo.svg'
import closePic from '@/shared/assets/images/vectors/Store/_/closePic.svg'
import exitIcon from '@/shared/assets/images/vectors/Store/_/exitIcon.svg'
import creditCardIcon from '@/shared/assets/images/vectors/Store/_/creditCardIcon.svg'
import walletIcon from '@/shared/assets/images/vectors/Store/_/walletIcon.svg'
import { exit } from './modules/exit'

import supermarket from './Supermarket.module.scss'
import pharmacy from './Pharmacy.module.scss'
import electronicsStore from './ElectronicsStore.module.scss'
import furnitureStore from './FurnitureStore.module.scss'
import jewelryStore from './JewelryStore.module.scss'
import buildingStore from './BuildingStore.module.scss'
import clothesStore from './ClothesStore.module.scss'
import SidebarCategories from '@/shared/ui/SidebarCategories/SidebarCategories'

const stylesObject: Record<string, { readonly [key: string]: string }> = {
    //Стили основного компонента
    supermarket: supermarket,
    pharmacy: pharmacy,
    electronicsStore: electronicsStore,
    furnitureStore: furnitureStore,
    jewelryStore: jewelryStore,
    buildingStore: buildingStore,
    clothesStore: clothesStore,
}

interface CartDataBase {
    productId: string
    productIcon: string
    productDescription: string
    productValue: number
    productPrice: number
}

const Store = (props: Props) => {
    const mainStyles = stylesObject[props.storeType]

    const [activeCategory, setActiveCategory] = useState<string>(
        props.categories[0].key
    )
    const categoryTitle = props.categories.find(
        (key) => key.key === activeCategory
    )?.title
    const [isCartVisible, setIsCartVisible] = useState<boolean>(false)
    const [mainGoods, setMainGoods] = useState<ProductCards[]>(props.mainGoods)
    const [isPaymentModalVisible, setIsPaymentModalVisible] =
        useState<boolean>(false)
    const [cartDataBase, setCartDataBase] = useState<CartDataBase[]>([])
    const tempTotal = cartDataBase.reduce(
        (sum, current) => sum + current.productPrice * current.productValue,
        0
    )
    const total = tempTotal + tempTotal * props.salesTax
    const cartAmount = cartDataBase.reduce(
        (sum, current) => sum + Number(current.productValue),
        0
    )

    const deleteFromCart = (product: string) => {
        setCartDataBase((prev) =>
            prev.filter((item) => {
                return item.productId !== product
            })
        )
    }

    const changeProductValue = (product: CartDataBase, operator?: boolean) => {
        setCartDataBase(
            cartDataBase
                .map((item) => {
                    if (item.productId == product.productId && operator)
                        ++item.productValue
                    else if (
                        item.productId == product.productId &&
                        item.productValue > 1 &&
                        !operator
                    )
                        --item.productValue
                    else if (
                        item.productId == product.productId &&
                        item.productValue == 1 &&
                        !operator
                    ) {
                        setCartDataBase((prev) =>
                            prev.filter(
                                (el) => el.productId != product.productId
                            )
                        )
                        return null
                    }
                    return item
                })
                .filter((item) => item !== null) as CartDataBase[]
        )
    }

    const addProduct = (product: any) => {
        const hasProduct = cartDataBase.some(
            (item) => item.productId == product.productId
        )
        if (hasProduct) changeProductValue(product)
        else setCartDataBase((prev) => [...prev, product])
    }

    const viewPaymentOptions = (value: boolean) => {
        setIsPaymentModalVisible(value)
    }

    const setSidebarButtonCheck = (category: string) => {
        setMainGoods(props[category])
        setIsCartVisible(false)
        setActiveCategory(category)
    }

    const viewCart = () => {
        setIsCartVisible(true)
    }

    const getSearch = (keyCode: number, search: string) => {
        const searchArray: any = props[activeCategory].filter((el: any) =>
            el.description.toLowerCase().includes(search.toLowerCase())
        )
        setMainGoods(searchArray)
    }

    const clearSearch = () => {
        setMainGoods(props[activeCategory])
    }

    const getColorFilter = (colorId: string) => {
        const searchArray = props[activeCategory].filter(
            (el: any) => el?.color && el.color.includes(colorId)
        )
        setMainGoods(searchArray)
    }

    const changeSortSelect = (key: string) => {
        const sortComplete: ProductCards[] = [...mainGoods]
        switch (key) {
            case 'fromLowerToUpper': {
                setMainGoods(sortComplete.sort((a, b) => a.price - b.price))
                break
            }
            case 'fromUpperToLower': {
                setMainGoods(sortComplete.sort((a, b) => b.price - a.price))
                break
            }
        }
    }

    const finishShopping = (
        customer: Customer,
        businessId: string,
        payWithCash: boolean
    ) => {
        const listOfGoods = cartDataBase.map((item) => {
            return {
                productId: item.productId,
                productValue: item.productValue,
            }
        })

        makeAPurchase(listOfGoods, customer, businessId, payWithCash, total)
    }

    return (
        <div className={mainStyles.mainWrapper}>
            <div className={mainStyles.topRightInfo}>
                <div className={mainStyles.userPaySourceWrapper}>
                    <img
                        className={mainStyles.paySourceIcons}
                        src={walletIcon}
                    />
                    <div className={mainStyles.paySourceValue}>
                        $ {props.playerCash.toLocaleString()}
                    </div>
                </div>
                <div className={mainStyles.userPaySourceWrapper}>
                    <img
                        className={mainStyles.paySourceIcons}
                        src={creditCardIcon}
                    />
                    <div className={mainStyles.paySourceValue}>
                        $ {props.playerCreditCardValue.toLocaleString()}
                    </div>
                </div>
                <div
                    className={mainStyles.exitWrapper}
                    onClick={() => exit(true)}
                >
                    <div className={mainStyles.topRightInfoText}>Выйти</div>
                    <img className={mainStyles.exitIcon} src={exitIcon} />
                </div>
            </div>
            <div className={mainStyles.container}>
                {isPaymentModalVisible ? (
                    <PaymentSelect
                        business={[
                            { id: '1', title: 'Наркоторговля' },
                            { id: '2', title: 'Проституция' },
                        ]}
                        onClose={viewPaymentOptions}
                        total={total}
                        playerBalance={props.playerBalance}
                        onSelect={finishShopping}
                        storeType={props.storeType}
                    />
                ) : null}
                <div className={mainStyles.header}>
                    <div className={mainStyles.headerLeftContainer}>
                        <div>
                            <img
                                className={mainStyles.mainLogo}
                                src={props.headerLogo}
                            />
                        </div>
                        <div className={mainStyles.title}>
                            <span>{props.title}</span>
                        </div>
                    </div>
                    <div className={mainStyles.headerRightContainer}>
                        <div
                            className={classNames(
                                mainStyles.cartButton,
                                isCartVisible && mainStyles.cartButtonSelected
                            )}
                            onClick={viewCart}
                        >
                            <img
                                src={cartLogo}
                                className={classNames(
                                    mainStyles.cartLogo,
                                    isCartVisible && mainStyles.cartLogoSelected
                                )}
                            />
                        </div>
                        <div className={mainStyles.cartInfo}>
                            <div>{cartAmount} товаров</div>
                            <div>{total.toLocaleString()} $</div>
                        </div>
                        <div
                            className={mainStyles.trashButton}
                            onClick={() => console.log('store closed')}
                        >
                            <img
                                className={mainStyles.closePic}
                                src={closePic}
                            />
                        </div>
                    </div>
                </div>

                <SidebarCategories
                    storeType={props.storeType}
                    activeCategory={activeCategory}
                    onSelect={setSidebarButtonCheck}
                    categories={props.categories}
                />

                {/* ------------------main------------------- */}
                {isCartVisible ? (
                    <Cart
                        goods={cartDataBase}
                        onDelete={deleteFromCart}
                        onBuy={viewPaymentOptions}
                        onPlusOrMinus={changeProductValue}
                        storeType={props.storeType}
                        total={total}
                        tax={(total / (1 + props.salesTax)) * props.salesTax}
                    />
                ) : null}

                {!isCartVisible ? (
                    <div className={mainStyles.main}>
                        <div className={mainStyles.mainHeader}>
                            <div className={mainStyles.mainHeaderTitle}>
                                {categoryTitle}
                            </div>
                            <InputElements
                                onSearch={getSearch}
                                onClear={clearSearch}
                                select={props.select}
                                onApplySelect={changeSortSelect}
                                storeType={props.storeType}
                                colorSelect={props.colorSelect}
                                onColorSelect={getColorFilter}
                            />
                        </div>
                        <div className={mainStyles.goodsWindow}>
                            {mainGoods?.map((item) => {
                                const productInCart = cartDataBase.find(
                                    (target) => target.productId == item.id
                                )
                                return (
                                    <ProductCard
                                        key={item.id}
                                        id={item.id}
                                        price={item.price}
                                        image={item.image}
                                        description={item.description}
                                        sale={item.sale}
                                        onSelect={addProduct}
                                        onPlusOrMinus={changeProductValue}
                                        value={
                                            cartDataBase.length == 0
                                                ? 0
                                                : productInCart
                                                ? productInCart?.productValue
                                                : 0
                                        }
                                        storeType={props.storeType}
                                        buyButtonTitle="В корзину"
                                    />
                                )
                            })}
                        </div>
                    </div>
                ) : null}
                {/* ------------------------------------------ */}
            </div>
        </div>
    )
}

export default Store
