import { EnFamilyGenes } from '@/widgets/CharacterGeneticContent/constants/slides'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IInitialState {
    selectedLeadingColor?: EnFamilyGenes
    similarity: number
    leadingMix: number
}
const initialState: IInitialState = {
    similarity: 0.5,
    leadingMix: 0.5,
}

const personalizationGeneticSlice = createSlice({
    name: 'personalizationGenetic',
    initialState,
    reducers: {
        changeLeadingColor(state, { payload }: PayloadAction<EnFamilyGenes>) {
            state.selectedLeadingColor = payload
        },
        changeSimilarity(state, { payload }: PayloadAction<number>) {
            state.similarity = payload
        },
        changeLeadingMix(state, { payload }: PayloadAction<number>) {
            state.leadingMix = payload
        },
    },
})

export const { changeLeadingColor, changeLeadingMix, changeSimilarity } =
    personalizationGeneticSlice.actions
export default personalizationGeneticSlice.reducer
