import { ChangeEventHandler, KeyboardEventHandler, useState } from 'react'
import { Props } from './InputElements.typings'

import supermarketInputElements from './SupermarketInputElements.module.scss'
import buildingStoreInputElements from './BuildingStoreInputElements.module.scss'
import clothesStoreInputElements from './ClothesStoreInputElements.module.scss'
import electronicsStoreInputElements from './ElectronicsStoreInputElements.module.scss'
import furnitureStoreInputElements from './FurnitureStoreInputElements.module.scss'
import jewelryStoreInputElements from './JewelryStoreInputElements.module.scss'
import pharmacyInputElements from './PharmacyInputElements.module.scss'
import SortSelect from '@/shared/ui/SortSelect/SortSelect'
import selectArrowPic from '@/shared/assets/images/vectors/Store/shared/selectArrowPic.svg'

const stylesObject: Record<string, { readonly [key: string]: string }> = {
    //InputElements styles
    supermarket: supermarketInputElements,
    pharmacy: pharmacyInputElements,
    electronicsStore: electronicsStoreInputElements,
    furnitureStore: furnitureStoreInputElements,
    jewelryStore: jewelryStoreInputElements,
    buildingStore: buildingStoreInputElements,
    clothesStore: clothesStoreInputElements,
}

const InputElements = (props: Props) => {
    const mainStyles = stylesObject[props.storeType]

    const [search, setSearch] = useState<string>('')
    const [colorSelectIsVisible, setColorSelectIsVisible] =
        useState<boolean>(false)

    const setSearchValue: ChangeEventHandler<HTMLInputElement> = (event) => {
        setSearch(event.target.value)
    }

    const callSearch: KeyboardEventHandler = (event) => {
        const keyCode = event.keyCode
        props.onSearch(keyCode, search)
    }

    const clearSearch = () => {
        setSearch('')
        props.onClear()
    }

    const openColorSelect = (colorId: string) => {
        props.onColorSelect(colorId)
    }

    return (
        <div className={mainStyles.mainHeaderTools}>
            <div className={mainStyles.searchForm}>
                <input
                    type="search"
                    value={search}
                    className={mainStyles.search}
                    placeholder="Введите товар..."
                    onKeyUp={callSearch}
                    onChange={setSearchValue}
                />
                {search.length !== 0 ? (
                    <button
                        type="reset"
                        className={mainStyles.clearButton}
                        onClick={clearSearch}
                    />
                ) : null}
            </div>
            {props.colorSelect ? (
                <div className={mainStyles.colorSelectWrapper}>
                    <div
                        className={mainStyles.colorSelect}
                        onClick={() =>
                            setColorSelectIsVisible(
                                (prevColorSelect) => !prevColorSelect
                            )
                        }
                    >
                        Выберите цвет
                        <img
                            className={mainStyles.arrow}
                            src={selectArrowPic}
                        />
                    </div>
                    {colorSelectIsVisible ? (
                        <div className={mainStyles.openColorSelect}>
                            {props.colorSelect.map((color) => (
                                <div
                                    key={color.colorId}
                                    className={mainStyles.optionColor}
                                    style={{ background: `${color.color}` }}
                                    onClick={() =>
                                        openColorSelect(color.colorId)
                                    }
                                />
                            ))}
                        </div>
                    ) : null}
                </div>
            ) : null}
            <SortSelect
                storeType={props.storeType}
                select={props.select}
                onApplySelect={props.onApplySelect}
            />
        </div>
    )
}

export default InputElements
