import styleCategoryIcon from './assets/style-category.svg'
import styleCategoryInactiveIcon from './assets/style-category-inactive.svg'
import hobbiesCategoryIcon from './assets/hobbies-category.svg'
import hobbiesCategoryInactiveIcon from './assets/hobbies-category-inactive.svg'
import communicationCategoryIcon from './assets/communication-category.svg'
import communicationCategoryInactiveIcon from './assets/communication-category-inactive.svg'
import emotionsCategoryIcon from './assets/emotions-category.svg'
import emotionsCategoryInactiveIcon from './assets/emotions-category-inactive.svg'
import activeIcon from './assets/active.svg'
import animalLoverIcon from './assets/animalLover.svg'
import antiKidsIcon from './assets/antiKids.svg'
import artConnoisseurIcon from './assets/artConnoisseur.svg'
import awkwardCommunicationIcon from './assets/awkwardCommunication.svg'
import bookwormIcon from './assets/bookworm.svg'
import braveIcon from './assets/brave.svg'
import bullyIcon from './assets/bully.svg'
import butterfingersIcon from './assets/butterfingers.svg'
import careeristIcon from './assets/careerist.svg'
import catLoverIcon from './assets/catLover.svg'
import cheerfulIcon from './assets/cheerful.svg'
import cholericIcon from './assets/choleric.svg'
import cleanIcon from './assets/clean.svg'
import clumsyIcon from './assets/clumsy.svg'
import confidentIcon from './assets/confident.svg'
import couchPotatoIcon from './assets/couchPotato.svg'
import creatorIcon from './assets/creator.svg'
import danceMachineIcon from './assets/danceMachine.svg'
import dogLoverIcon from './assets/dogLover.svg'
import easygoingIcon from './assets/easygoing.svg'
import eccentricIcon from './assets/eccentric.svg'
import ecoEnthusiastIcon from './assets/ecoEnthusiast.svg'
import egotisticalIcon from './assets/egotistical.svg'
import eternalChildIcon from './assets/eternalChild.svg'
import evilIcon from './assets/evil.svg'
import familyOrientedIcon from './assets/familyOriented.svg'
import fickleIcon from './assets/fickle.svg'
import freeganIcon from './assets/freegan.svg'
import friendlyIcon from './assets/friendly.svg'
import geniusIcon from './assets/genius.svg'
import gluttonIcon from './assets/glutton.svg'
import gourmetIcon from './assets/gourmet.svg'
import handyIcon from './assets/handy.svg'
import highMaintenanceIcon from './assets/highMaintenance.svg'
import insiderIcon from './assets/insider.svg'
import jealousIcon from './assets/jealous.svg'
import kindIcon from './assets/kind.svg'
import kleptomaniacIcon from './assets/kleptomaniac.svg'
import lactoseIntolerantIcon from './assets/lactoseIntolerant.svg'
import lonerIcon from './assets/loner.svg'
import moodyIcon from './assets/moody.svg'
import musicLoverIcon from './assets/musicLover.svg'
import natureLoverIcon from './assets/natureLover.svg'
import perfectionistIcon from './assets/perfectionist.svg'
import pickyIcon from './assets/picky.svg'
import practicalIcon from './assets/practical.svg'
import quirkyIcon from './assets/quirky.svg'
import respectfulIcon from './assets/respectful.svg'
import romanticIcon from './assets/romantic.svg'
import skepticIcon from './assets/skeptic.svg'
import slobIcon from './assets/slob.svg'
import touchyIcon from './assets/touchy.svg'
import vegetarianIcon from './assets/vegetarian.svg'
import { EnCharacterTrait } from './CharacterTraitsSelect.typings'

export const traitsArr = {
    traits1: [
        {
            id: EnCharacterTrait.Active,
            icon: activeIcon,
            title: 'Активный(-ая)',
            selected: false,
        },
        {
            id: EnCharacterTrait.Cheerful,
            icon: cheerfulIcon,
            title: 'Весёлый(-ая)',
            selected: false,
        },
        {
            id: EnCharacterTrait.HighMaintenance,
            icon: highMaintenanceIcon,
            title: 'Высокие запросы',
            selected: false,
        },
        {
            id: EnCharacterTrait.Genius,
            icon: geniusIcon,
            title: 'Гений',
            selected: false,
        },
        {
            id: EnCharacterTrait.Touchy,
            icon: touchyIcon,
            title: 'Недотрога',
            selected: false,
        },
        {
            id: EnCharacterTrait.Butterfingers,
            icon: butterfingersIcon,
            title: 'Растяпа',
            selected: false,
        },
        {
            id: EnCharacterTrait.Romantic,
            icon: romanticIcon,
            title: 'Романтик',
            selected: false,
        },
        {
            id: EnCharacterTrait.Confident,
            icon: confidentIcon,
            title: 'Самоуверенный(-ая)',
            selected: false,
        },
        {
            id: EnCharacterTrait.Creator,
            icon: creatorIcon,
            title: 'Творец',
            selected: false,
        },
        {
            id: EnCharacterTrait.Moody,
            icon: moodyIcon,
            title: 'Угрюмый(-ая)',
            selected: false,
        },
        {
            id: EnCharacterTrait.Choleric,
            icon: cholericIcon,
            title: 'Холерик',
            selected: false,
        },
    ],
    traits2: [
        {
            id: EnCharacterTrait.Gourmet,
            icon: gourmetIcon,
            title: 'Гурман',
            selected: true,
        },
        {
            id: EnCharacterTrait.ArtConnoisseur,
            icon: artConnoisseurIcon,
            title: 'Искусствовед',
            selected: true,
        },
        {
            id: EnCharacterTrait.Bookworm,
            icon: bookwormIcon,
            title: 'Книжный червь',
            selected: true,
        },
        {
            id: EnCharacterTrait.MusicLover,
            icon: musicLoverIcon,
            title: 'Меломан',
            selected: true,
        },
        {
            id: EnCharacterTrait.Perfectionist,
            icon: perfectionistIcon,
            title: 'Перфекционист(-ка)',
            selected: true,
        },
        {
            id: EnCharacterTrait.Handy,
            icon: handyIcon,
            title: 'Умелец',
            selected: true,
        },
        {
            id: EnCharacterTrait.Eccentric,
            icon: eccentricIcon,
            title: 'Эксцентричный(-ая)',
            selected: true,
        },
    ],
    traits3: [
        {
            id: EnCharacterTrait.Vegetarian,
            icon: vegetarianIcon,
            title: 'Вегетарианец(-ка)',
            selected: false,
        },
        {
            id: EnCharacterTrait.EternalChild,
            icon: eternalChildIcon,
            title: 'Вечное дитя',
            selected: false,
        },
        {
            id: EnCharacterTrait.Careerist,
            icon: careeristIcon,
            title: 'Карьерист(-ка)',
            selected: false,
        },
        {
            id: EnCharacterTrait.Kleptomaniac,
            icon: kleptomaniacIcon,
            title: 'Клептоман(-ка)',
            selected: false,
        },
        {
            id: EnCharacterTrait.CouchPotato,
            icon: couchPotatoIcon,
            title: 'Лежебока',
            selected: false,
        },
        {
            id: EnCharacterTrait.CatLover,
            icon: catLoverIcon,
            title: 'Любит кошек',
            selected: false,
        },
        {
            id: EnCharacterTrait.NatureLover,
            icon: natureLoverIcon,
            title: 'Любит природу',
            selected: false,
        },
        {
            id: EnCharacterTrait.DogLover,
            icon: dogLoverIcon,
            title: 'Любит собак',
            selected: false,
        },
        {
            id: EnCharacterTrait.AnimalLover,
            icon: animalLoverIcon,
            title: 'Любовь к животным',
            selected: false,
        },
        {
            id: EnCharacterTrait.LactoseIntolerant,
            icon: lactoseIntolerantIcon,
            title: 'Непереносимость лактозы',
            selected: false,
        },
        {
            id: EnCharacterTrait.Slob,
            icon: slobIcon,
            title: 'Неряха',
            selected: false,
        },
        {
            id: EnCharacterTrait.Clumsy,
            icon: clumsyIcon,
            title: 'Неуклюжий(-ая)',
            selected: false,
        },
        {
            id: EnCharacterTrait.Glutton,
            icon: gluttonIcon,
            title: 'Обжора',
            selected: false,
        },
        {
            id: EnCharacterTrait.Brave,
            icon: braveIcon,
            title: 'Отважный(-ая)',
            selected: false,
        },
        {
            id: EnCharacterTrait.Practical,
            icon: practicalIcon,
            title: 'Практичный(-ая)',
            selected: false,
        },
        {
            id: EnCharacterTrait.Picky,
            icon: pickyIcon,
            title: 'Привереда',
            selected: false,
        },
        {
            id: EnCharacterTrait.Skeptic,
            icon: skepticIcon,
            title: 'Скептик',
            selected: false,
        },
        {
            id: EnCharacterTrait.DanceMachine,
            icon: danceMachineIcon,
            title: 'Танцмашина',
            selected: false,
        },
        {
            id: EnCharacterTrait.Freegan,
            icon: freeganIcon,
            title: 'Фриган',
            selected: false,
        },
        {
            id: EnCharacterTrait.NeatFreak,
            icon: cleanIcon,
            title: 'Чистюля',
            selected: false,
        },
        {
            id: EnCharacterTrait.Quirky,
            icon: quirkyIcon,
            title: 'Чудаковатый(-ая)',
            selected: false,
        },
        {
            id: EnCharacterTrait.EcoEnthusiast,
            icon: ecoEnthusiastIcon,
            title: 'Экоэнтузиаст',
            selected: false,
        },
    ],
    traits4: [
        {
            id: EnCharacterTrait.Kind,
            icon: kindIcon,
            title: 'Добрый(-ая)',
            selected: false,
        },
        {
            id: EnCharacterTrait.Friendly,
            icon: friendlyIcon,
            title: 'Дружелюбный(-ая)',
            selected: false,
        },
        {
            id: EnCharacterTrait.Bully,
            icon: bullyIcon,
            title: 'Задира',
            selected: false,
        },
        {
            id: EnCharacterTrait.Evil,
            icon: evilIcon,
            title: 'Злой(-ая)',
            selected: false,
        },
        {
            id: EnCharacterTrait.Insider,
            icon: insiderIcon,
            title: 'Инсайдер',
            selected: false,
        },
        {
            id: EnCharacterTrait.Fickle,
            icon: fickleIcon,
            title: 'Непостоянный(-ая)',
            selected: false,
        },
        {
            id: EnCharacterTrait.Loner,
            icon: lonerIcon,
            title: 'Одиночка',
            selected: false,
        },
        {
            id: EnCharacterTrait.AntiKids,
            icon: antiKidsIcon,
            title: 'Против детей',
            selected: false,
        },
        {
            id: EnCharacterTrait.Jealous,
            icon: jealousIcon,
            title: 'Ревнивый(-ая)',
            selected: false,
        },
        {
            id: EnCharacterTrait.Easygoing,
            icon: easygoingIcon,
            title: 'Свой(-я) в доску',
            selected: false,
        },
        {
            id: EnCharacterTrait.Respectful,
            icon: respectfulIcon,
            title: 'Уважительный(-ая)',
            selected: false,
        },
        {
            id: EnCharacterTrait.Egotistical,
            icon: egotisticalIcon,
            title: 'Эгоцентричный(-ая)',
            selected: false,
        },
        {
            id: EnCharacterTrait.FamilyOriented,
            icon: familyOrientedIcon,
            title: 'Семьянин',
            selected: false,
        },
    ],
}

export const characterTraitsLabels = [
    {
        id: 1,
        icon: emotionsCategoryIcon,
        inactiveIcon: emotionsCategoryInactiveIcon,
    },
    {
        id: 2,
        icon: hobbiesCategoryIcon,
        inactiveIcon: hobbiesCategoryInactiveIcon,
    },
    {
        id: 3,
        icon: styleCategoryIcon,
        inactiveIcon: styleCategoryInactiveIcon,
    },
    {
        id: 4,
        icon: communicationCategoryIcon,
        inactiveIcon: communicationCategoryInactiveIcon,
    },
]
