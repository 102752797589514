import { rpc } from 'altv-xrpc-webview'
import { baseApi } from '@/shared/store/api/baseApi'
import { NeedsStatus } from './types'

export const interactionWheelApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        needsChange: builder.query<NeedsStatus, void>({
            queryFn() {
                return {
                    data: {
                        toilet: 1,
                        shower: 1,
                        immunity: 1,
                        sleep: 1,
                        mood: 1,
                        food: 1,
                        water: 1,
                    },
                }
            },
            async onCacheEntryAdded(
                _,
                { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
            ) {
                try {
                    await cacheDataLoaded

                    const listener = (...args: unknown[]) => {
                        const [data] = args as [NeedsStatus]
                        updateCachedData((draft) => {
                            if (draft) {
                                draft.toilet = data.toilet
                                draft.shower = data.shower
                                draft.immunity = data.immunity
                                draft.sleep = data.sleep
                                draft.mood = data.mood
                                draft.food = data.food
                                draft.water = data.water
                            }
                        })
                    }
                    rpc.onClient('hud:needs:change', listener)
                } catch {
                    // ...
                }
                await cacheEntryRemoved
            },
        }),
    }),
})

export const { useNeedsChangeQuery } = interactionWheelApi
