import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IInitialState {
    cheekDepth: number
    cheekWidth: number
}
const initialState: IInitialState = {
    cheekDepth: 0.5,
    cheekWidth: 0.5,
}

const personalizationCheekSlice = createSlice({
    name: 'personalizationCheek',
    initialState,
    reducers: {
        changeCheekWidth(state, { payload }: PayloadAction<number>) {
            state.cheekWidth = payload
        },
        changeCheekDepth(state, { payload }: PayloadAction<number>) {
            state.cheekDepth = payload
        },
    },
})

export const { changeCheekWidth, changeCheekDepth } =
    personalizationCheekSlice.actions
export default personalizationCheekSlice.reducer
