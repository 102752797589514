import React, { useEffect, useState } from 'react'
import Select from '@/shared/ui/Input/Select/Select'
import { EVENTS, RPC_METHODS } from './EventEmitter.config'
import styles from './EventEmitter.module.scss'
import { Play } from '@/shared/icons/Play'
import oldrpc from 'altv-rpc'
import { Loading } from '@/shared/icons/Loading'
import { rpc } from 'altv-xrpc-webview'

export const EventEmitter: React.FC = () => {
    const [activeIndex, setActiveIndex] = useState<number>(0)
    const activeEvent = EVENTS[activeIndex]
    const options = EVENTS.map(({ title }, index) => ({
        label: title,
        value: index,
    }))
    const [activeMethod, setActiveMethod] = useState(RPC_METHODS[0].value)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [response, setResponse] = useState({})
    const [error, setError] = useState<string | null>(null)
    const [isVisible, setIsVisible] = useState<boolean>(false)

    const handleClick = () => {
        if (activeMethod === 'trigger' || activeMethod === 'triggerServer') {
            oldrpc[activeMethod](activeEvent.event, activeEvent.body)
        } else if (activeMethod === 'call' || activeMethod === 'callServer') {
            setIsLoading(true)
            oldrpc[activeMethod](activeEvent.event, activeEvent.body, {
                timeout: 2000,
            })
                .then((res) => {
                    setResponse(res)
                })
                .catch((err) => {
                    setError(err)
                })
                .finally(() => {
                    setIsLoading(false)
                })
        } else if (activeMethod === 'xrpc:emitServer') {
            setIsLoading(true)
            rpc.emitServer(activeEvent.event, activeEvent.body)
                .then((res) => {
                    setResponse(res)
                })
                .catch((err) => {
                    setError(err)
                })
                .finally(() => {
                    setIsLoading(false)
                })
        } else if (activeMethod === 'xrpc:emitClient') {
            setIsLoading(true)
            rpc.emitClient(activeEvent.event, activeEvent.body)
                .then((res) => {
                    setResponse(res)
                })
                .catch((err) => {
                    setError(err)
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }

    useEffect(() => {
        const handler = (event: KeyboardEvent) => {
            if (event.code === 'F6') {
                setIsVisible((pre) => !pre)
            }
        }

        document.addEventListener('keydown', handler)

        return () => document.removeEventListener('keydown', handler)
    }, [])

    if (!isVisible) return null

    return (
        <div className={styles.container}>
            <div>
                <div className={styles.title}>Выбор события</div>
                <div className={styles.row}>
                    <Select
                        options={options}
                        value={activeIndex}
                        onChange={(index) => {
                            setActiveIndex(index)
                        }}
                        className={styles.select}
                    />
                    <Select
                        options={RPC_METHODS}
                        value={activeMethod}
                        onChange={(method) => {
                            setActiveMethod(method)
                        }}
                        className={styles.select}
                    />
                    <div className={styles.play} onClick={handleClick}>
                        {!isLoading ? (
                            <Play color="#FFD80E" className={styles.icon} />
                        ) : (
                            <Loading color="#FFD80E" className={styles.icon} />
                        )}
                    </div>
                </div>
            </div>
            <div className={styles.content}>
                <div className={styles.column}>
                    <div>
                        <span className={styles.title}>Name: </span>
                        {activeEvent.event}
                    </div>
                    <div className={styles.title}>Body: </div>
                    <pre className={styles.pre}>
                        {JSON.stringify(activeEvent.body, null, 2)}
                    </pre>
                </div>
                {!activeMethod.includes('trigger') ? (
                    <div className={styles.column}>
                        <div className={styles.title}>
                            {error ? 'Error: ' : 'Response: '}
                        </div>
                        <pre className={styles.pre}>
                            {JSON.stringify(error || response, null, 2)}
                        </pre>
                    </div>
                ) : null}
            </div>
        </div>
    )
}
