import styles from './index.module.scss'
import { PlayerInfoForm } from '@/features/PlayerInfoForm/PlayerInfoForm'
import CharacterTraitsSelect from './components/CharacterTraitsSelect/CharacterTraitsSelect'
import LifeGoalSelect from './components/LifeGoalSelect/LifeGoalSelect'
import { useAppSelector } from '@/shared/hooks/redux-ts'

const AboutScreen = () => {
    const mainContentVisible = useAppSelector(
        (state) => state.personalization.personalization.mainContentVisible
    )
    const isCharacterTraitsVisible = useAppSelector(
        (state) => state.personalization.base.isCharacterTraitsVisible
    )
    const isLifeGoalVisible = useAppSelector(
        (state) => state.personalization.base.isLifeGoalVisible
    )

    return (
        <div className={styles.wrapper}>
            <div className={styles.playerInfoForm}>
                {mainContentVisible ? <PlayerInfoForm /> : null}
            </div>

            {isCharacterTraitsVisible ? <CharacterTraitsSelect /> : null}
            {isLifeGoalVisible ? <LifeGoalSelect /> : null}
        </div>
    )
}

export { AboutScreen }
