import styles from './index.module.scss'
import { actionButtons } from './index.config'
import { useAppDispatch, useAppSelector } from '@/shared/hooks/redux-ts'
import { FC, useCallback } from 'react'
import { getRandomAboutSettings } from './lib/getRandomAboutSettings'
import { TClassName } from '@/shared/types/shared'
import classNames from 'classnames'
import {
    setAboutSettings,
    visibleComponentPlayerInfoForm,
} from '@/shared/store/slices/personalization/baseSlice'
import {
    changeMainContentVisible,
    nextStep,
    prevStep,
} from '@/shared/store/slices/personalization/personalizationSlice'

interface Props extends TClassName {
    currentStep: number
}
const ActionButtons: FC<Props> = ({ currentStep, className }) => {
    const dispatch = useAppDispatch()
    const mainContentVisible = useAppSelector(
        (state) => state.personalization.personalization.mainContentVisible
    )
    const aboutSettings = useAppSelector(
        (state) => state.personalization.base.aboutSettings
    )
    const onRandom = useCallback(() => {
        const newAboutSettings = getRandomAboutSettings({ aboutSettings })
        dispatch(setAboutSettings(newAboutSettings))
    }, [aboutSettings])

    const actionButtonsHandler = (action: string) => {
        if (action === 'random') onRandom()
        else if (action === 'decline') {
            if (!mainContentVisible) {
                dispatch(visibleComponentPlayerInfoForm())
                dispatch(changeMainContentVisible(true))
            } else if (mainContentVisible && currentStep - 1 > 0) {
                dispatch(prevStep())
            }
        } else if (action === 'confirm') {
            if (mainContentVisible) {
                dispatch(nextStep())
            } else {
                dispatch(changeMainContentVisible(true))
                dispatch(visibleComponentPlayerInfoForm())
            }
        }
    }

    return (
        <div className={classNames(styles.actionsBlock, className)}>
            {actionButtons.map((item) => {
                const Icon = item.icon
                return (
                    <button key={item.action} type="button">
                        <Icon
                            fill={
                                item.action === 'confirm' ? '#FFD80E' : '#fff'
                            }
                            width={item.action === 'confirm' ? 4.6875 : 3.6875}
                            onSelect={actionButtonsHandler}
                            action={item.action}
                        />
                    </button>
                )
            })}
        </div>
    )
}

export default ActionButtons
