import { useState, ChangeEvent, FC } from 'react'
import styles from './AddMoneyScreen.module.scss'
import AcceptButton from '../components/AcceptButton'
import { addMoney } from '../modules/addMoney'
import { Screen } from '../ATM.config'

interface Props {
    onSelect: (
        activeScreen: Screen,
        message: string,
        inputValue: string
    ) => void
}

const AddMoneyScreen: FC<Props> = ({ onSelect }): JSX.Element => {
    const [inputValue, setInputValue] = useState<string>('')
    const gas = Math.floor(Number(inputValue) * 0.05)
    const toAdding = Number(inputValue) + gas
    const maskGas = Number(gas).toLocaleString()
    const maskInput = Number(inputValue).toLocaleString()
    const maskToAdding = Number(toAdding).toLocaleString()

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newValue = event.currentTarget.value.replace(/\D/g, '') // Фильтрация только цифр
        setInputValue(newValue)
    }

    const addingMoney = () => {
        addMoney(Number(inputValue))
        onSelect(
            Screen.Confirmation,
            'Вы положили на счет',
            `${Number(inputValue).toLocaleString()} $`
        )
    }

    return (
        <div className={styles.cardShape}>
            <div className={styles.title}>Введите сумму:</div>
            <div className={styles.mainInfo}>
                <input
                    type="text"
                    autoFocus
                    value={inputValue}
                    className={styles.value}
                    onChange={handleChange}
                    maxLength={11}
                />
                <div className={styles.mask}>{maskInput} $</div>
                <div className={styles.info}>
                    <div>
                        <div>Комиссия:</div>
                        <div>{maskGas} $</div>
                    </div>
                    <div>
                        <div>К пополнению:</div>
                        <div>{maskToAdding} $</div>
                    </div>
                </div>
            </div>
            <div className={styles.acceptButton}>
                <AcceptButton title="Пополнить" onSelect={addingMoney} />
            </div>
        </div>
    )
}

export default AddMoneyScreen
