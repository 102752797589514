export const getDayOfBirth = (monthOfBirth: string) => {
    if (monthOfBirth === 'Февраль')
        return String(Math.floor(Math.random() * 28))
    else if (
        monthOfBirth === 'Апрель' ||
        monthOfBirth === 'Июнь' ||
        monthOfBirth === 'Сентябрь' ||
        monthOfBirth === 'Ноябрь'
    )
        return String(Math.floor(Math.random() * 30))
    else return String(Math.floor(Math.random() * 31))
}
