import { combineReducers } from '@reduxjs/toolkit'
import lips from './lipsSlice'
import nose from './noseSlice'
import eyebrow from './eyebrowSlice'
import eye from './eyeSlice'
import cheek from './cheekSlice'
import cheekbones from './cheekbonesSlice'

export const headReducers = combineReducers({
    lips,
    nose,
    eyebrow,
    eye,
    cheek,
    cheekbones,
})
