import {
    EnActiveAttribute,
    EnHairProperties,
} from '@/shared/store/slices/personalization/viewSlice'
import { CharacterHairBeardContent } from '@/widgets/CharacterHairBeardContent'
import { CharacterHairContent } from '@/widgets/CharacterHairContent'
import { CharacterHairEyebrowContent } from '@/widgets/CharacterHairEyebrowContent'
import { CharacterHairTorsContent } from '@/widgets/CharacterHairTorsContent'
import { ReactElement } from 'react'

export const returnHairComponents = (
    activeProperty?: EnHairProperties,
    activeAttribute?: EnActiveAttribute
): ReactElement | null => {
    let component = null
    if (
        activeProperty === undefined ||
        activeAttribute !== EnActiveAttribute.Hair
    )
        return component

    switch (activeProperty) {
        case EnHairProperties.Hair:
            component = <CharacterHairContent />
            break
        case EnHairProperties.Beard:
            component = <CharacterHairEyebrowContent />
            break
        case EnHairProperties.Eyebrow:
            component = <CharacterHairBeardContent />
            break
        case EnHairProperties.Tors:
            component = <CharacterHairTorsContent />
            break
    }

    return component
}
