import { FC } from 'react'
import styles from './ScreenTitle.module.scss'
import { useAppSelector } from '@/shared/hooks/redux-ts'
import { enStepTitles } from './ScreenTitle.types'
import { currentStepSelector } from '@/shared/store/slices/personalization/personalizationSlice'

const ScreenTitle: FC = () => {
    const currentStep = useAppSelector(currentStepSelector)
    let title
    if (currentStep === 1) {
        title = enStepTitles.step1
    } else if (currentStep === 2) {
        title = enStepTitles.step2
    } else {
        title = enStepTitles.step3
    }

    return <div className={styles.wrapper}>{title}</div>
}

export default ScreenTitle
