import { useEffect, useState } from 'react'
import rpc from 'altv-rpc'
import { Props, RpcStore } from '../Store.typings'
import { RpcResponse } from '@/shared/types/common'

export const useStoreData = () => {
    const [showStore, setShow] = useState<boolean>(false)
    const [paramsStore, setParams] = useState<Props | null>(null)

    const callback = ({ data: { show, params } }: RpcResponse<RpcStore>) => {
        setShow(show)
        setParams(params)
    }

    const event = 'store:show'

    useEffect(() => {
        rpc.on(event, callback)

        return () => {
            rpc.off(event, callback)
        }
    })

    return { showStore, paramsStore }
}
