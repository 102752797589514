import { FC, useState } from 'react'
import cls from './index.module.scss'
import { TClassName } from '@/shared/types/shared'
import { UiModal } from '@/shared/ui/Modal'
import { FORGOT_PASSWORD_MODAL } from '@/shared/constants'
import classNames from 'classnames'
import { CodeVerification } from './CodeVerification'
import { PasswordChange } from './PasswordChange'
import { EnterIdentifier } from './EnterIdentifier'

export enum EnModalsSteps {
    Verification,
    PasswordChanged,
    EnterIdentifier,
}
interface Props extends TClassName {}

const ForgotPasswordModal: FC<Props> = ({ className }) => {
    const [step, setStep] = useState<EnModalsSteps>(
        EnModalsSteps.EnterIdentifier
    )

    return (
        <UiModal
            handleClose={() => {
                setStep(EnModalsSteps.EnterIdentifier)
            }}
            slug={FORGOT_PASSWORD_MODAL}
            className={classNames(cls.modal, className)}
        >
            {step === EnModalsSteps.EnterIdentifier ? (
                <EnterIdentifier setStep={setStep} />
            ) : step === EnModalsSteps.Verification ? (
                <CodeVerification setStep={setStep} />
            ) : (
                <PasswordChange />
            )}
        </UiModal>
    )
}

export { ForgotPasswordModal }
