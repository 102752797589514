import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IInitialState {
    fullLips: number
}
const initialState: IInitialState = {
    fullLips: 0.5,
}

const personalizationLipsSlice = createSlice({
    name: 'personalizationLips',
    initialState,
    reducers: {
        changeFullLips(state, { payload }: PayloadAction<number>) {
            state.fullLips = payload
        },
    },
})

export const { changeFullLips } = personalizationLipsSlice.actions
export default personalizationLipsSlice.reducer
