import { useState, useEffect } from 'react'
import styles from './CriticalStateScreen.module.scss'
import { Button } from '../Button/Button'
import reanimationLogo from '@/shared/assets/images/vectors/CriticalState/_/reanimationLogo.svg'
import { loseConsciousness } from './modules/loseConsciousness'
import { buttons } from './CriticalStateScreen.config'

const CriticalStateScreen = () => {
    const [timer, setTimer] = useState<string>('')

    const countdown = () => {
        let time = 420
        const loop = () => {
            let sec = String(time % 60)
            if (sec.length < 2) sec = '0' + sec
            const count = `${Math.floor(time / 60)}:${sec}`
            setTimer(count)
            time--
            time > -1 ? setTimeout(loop, 1000) : loseConsciousness(true)
        }
        loop()
    }

    useEffect(() => countdown(), [])

    return (
        <div className={styles.criticalStateScreen}>
            <div className={styles.radius1} />
            <div className={styles.info}>
                <img className={styles.reanimationLogo} src={reanimationLogo} />
                <div className={styles.container}>
                    <div className={styles.bigtext}>Критическое Состояние</div>
                    <div className={styles.smalltext}>
                        Персонаж получил серьезные ранения, организм из
                        последних сил борется за жизнь
                    </div>
                </div>
                <div className={styles.counter}>
                    Вы потеряете сознание через {timer} секунд
                </div>
                <div className={styles.buttonBlock}>
                    {buttons.map((item) => {
                        return (
                            <Button
                                key={item.title}
                                size="M"
                                onClick={() => item.function(true)}
                                className={styles.btn}
                            >
                                {item.title}
                            </Button>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default CriticalStateScreen
