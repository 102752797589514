import { ChangeEventHandler, FC } from 'react'
import cls from './index.module.scss'
import { TClassName } from '@/shared/types/shared'
import classNames from 'classnames'
import { useAppDispatch, useAppSelector } from '@/shared/hooks/redux-ts'
import { SliderInput } from '@/shared/ui/SliderInput'
import { pxToRem } from '@/shared/lib/pxToRem'
import { changeLeadingMix } from '@/shared/store/slices/personalization/geneticSlice'

interface Props extends TClassName {}
const ParameterLeadingMix: FC<Props> = ({ className }) => {
    const dispatch = useAppDispatch()
    const similarLeading = useAppSelector(
        (state) => state.personalization.genetic.similarity
    )
    const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        dispatch(changeLeadingMix(+event.target.value / 100))
    }

    return (
        <div className={classNames(cls.wrapper, className)}>
            <h2 className={cls.title}>Смесь кожи</h2>
            <SliderInput
                thumbSize={pxToRem(22)}
                onChange={handleChange}
                max={100}
                min={0}
                step={10}
                defaultValue={similarLeading * 100}
            />
        </div>
    )
}

export { ParameterLeadingMix }
