import { useAppDispatch } from '@/shared/hooks/redux-ts'
import {
    EnTraitsSlot,
    visibleComponentTraitSelect,
    setSelectedTraitSlot,
} from '@/shared/store/slices/personalization/baseSlice'
import { changeMainContentVisible } from '@/shared/store/slices/personalization/personalizationSlice'

type Return = (traitSlot: EnTraitsSlot) => void

const useTraitSelect = (): Return => {
    const dispatch = useAppDispatch()
    return (traitSlot) => {
        dispatch(visibleComponentTraitSelect())
        dispatch(changeMainContentVisible(false))
        dispatch(setSelectedTraitSlot(traitSlot))
    }
}

export { useTraitSelect }
