import { HudInfo } from '@/shared/ui/HudInfo/HudInfo'
import styles from './Hud.module.scss'
import { HudNeeds } from '@/entities/HudNeeds/HudNeeds'
import { useHudInfoData } from '@/shared/ui/HudInfo/model/useHudInfoData'
import { HudHeader } from '@/shared/ui/HudHeader/HudHeader'
import Suppression from '@/shared/ui/Suppression/Suppression'
import { AutoSensors } from '@/entities/AutoSensors/AutoSensors'
import { useAutoSensorsData } from '@/entities/AutoSensors/model/useAutoSensorsData'
import { InteractionWheel } from '@/entities/InteractionWheel/InteractionWheel'
import HudTickets from '@/widgets/HudTickets/HudTickets'
import HudHints from '@/widgets/HudHints/HudHints'
import { useStoreData } from '@/widgets/Store/model/useStoreData'
import Store from '@/widgets/Store/Store'
import { Chat } from '@/shared/ui/Chat/Chat'
import GYMGame from '@/widgets/GYMGame/GYMGame'
import { useGYMGame } from '@/widgets/GYMGame/model/useGYMGame'
import { usePoliceRadar } from '@/widgets/PoliceRadar/model/usePoliceRadar'
import PoliceRadar from '@/widgets/PoliceRadar/PoliceRadar'
import HudPenaltyWidget from '@/widgets/HudPenaltyWidget/HudPenaltyWidget'
import { VoiceChat } from '@/widgets/VoiceChat/VoiceChat'
import HudQuests from '@/widgets/HudQuests/HudQuests'
import { Quest } from '@/widgets/HudQuests/HudQuests.typings'
import InteractionSuggestion from '@/widgets/InterractionSuggestion/InteractionSuggestion'

export const Hud = () => {
    const { data } = useHudInfoData()
    const { data: autoSensorsData, show: showSensors } = useAutoSensorsData()
    //const { show: showInteractionWheel, type, params } = useInteractionWheelData()
    const { showStore, paramsStore } = useStoreData()
    const GYMData = useGYMGame()
    const policeRadarShow = usePoliceRadar()
    // const showPlayerMenu = usePlayerMenu()

    const quests: Quest[] = [
        {
            id: 1,
            title: 'Помочь уборщику',
            stages: [
                {
                    id: 1,
                    title: 'Помыть полы',
                    isCompleted: true,
                },
                {
                    id: 2,
                    title: 'Помыть полы',
                    isCompleted: true,
                },
                {
                    id: 3,
                    title: 'Помыть полы',
                    isCompleted: false,
                },
            ],
            direction: 35,
        },
        {
            id: 2,
            title: 'Помочь уборщику',
            stages: [
                {
                    id: 1,
                    title: 'Помыть полы',
                    isCompleted: false,
                },
                {
                    id: 2,
                    title: 'Помыть полы',
                    isCompleted: false,
                },
                {
                    id: 3,
                    title: 'Помыть полы',
                    isCompleted: false,
                },
            ],
            direction: 230,
        },
        {
            id: 3,
            title: 'Помочь уборщику',
            stages: [
                {
                    id: 1,
                    title: 'Помыть полы',
                    isCompleted: true,
                },
                {
                    id: 2,
                    title: 'Помыть полы',
                    isCompleted: false,
                },
                {
                    id: 3,
                    title: 'Помыть полы',
                    isCompleted: false,
                },
            ],
            direction: 105,
        },
    ]

    return (
        <div className={styles.container}>
            <Suppression />
            <div className={styles.hudInfo}>
                {data ? <HudInfo {...data} /> : null}
            </div>
            {/* <div className={styles.weaponSlots}>
                <WeaponSlots />
            </div> */}
            <div className={styles.needs}>
                <HudNeeds />
            </div>
            {showStore ? paramsStore ? <Store {...paramsStore} /> : null : null}
            {/* <div className={styles.wishes}>
                <HudWishes />
            </div> */}

            {GYMData.start ? <GYMGame duration={GYMData.duration} /> : null}

            <div className={styles.tickets}>
                <HudTickets />
            </div>
            <div className={styles.hints}>
                <HudHints />
            </div>
            <div className={styles.chat}>
                <Chat />
            </div>
            <div className={styles.header}>
                <HudHeader id="123456" />
            </div>
            {/* <div className={styles.actionList}>
                <ActionList actions={DEFAULT_ACTION_LIST} />
            </div> */}
            {showSensors ? (
                <div className={styles.autoSensors}>
                    <AutoSensors {...autoSensorsData} />
                </div>
            ) : null}
            <div className={styles.interactions}>
                <InteractionWheel
                //type={type}
                //onClick={setInteraction}
                //params={params}
                />
            </div>
            {policeRadarShow.isVisible ? <PoliceRadar /> : null}
            <HudPenaltyWidget />
            <VoiceChat />
            <HudQuests quests={quests} />
            <InteractionSuggestion />
        </div>
    )
}
