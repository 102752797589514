import { FC } from 'react'
import { TIcon } from '../types/shared'

interface Props extends TIcon {}
const TriangleIcon: FC<Props> = ({ className, fill }) => {
    return (
        <svg
            width="18"
            height="10"
            viewBox="0 0 18 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            color={fill || 'white'}
            className={className}
        >
            <path
                d="M8.50605 0.205039L0.20355 8.53057C-0.0683121 8.80334 -0.0678551 9.24497 0.204956 9.51729C0.477733 9.7894 0.91961 9.7887 1.19168 9.51589L9.00003 1.68592L16.8083 9.51617C17.0805 9.78895 17.522 9.78965 17.7949 9.51758C17.9316 9.38106 18 9.20222 18 9.02339C18 8.845 17.9321 8.66687 17.7963 8.5306L9.49397 0.205039C9.36326 0.07366 9.18537 -6.2833e-05 9.00003 -6.28352e-05C8.81469 -6.28374e-05 8.63701 0.0738717 8.50605 0.205039Z"
                fill="currentColor"
            />
        </svg>
    )
}

export { TriangleIcon }
