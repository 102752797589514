import { FC, useState } from 'react'
import styles from './LifeGoalSelect.module.scss'
import { EnCharacterPurpose } from './LifeGoalSelect.typings'
import GoalSelectItem from '@/pages/CharacterPersonalization/commonComponents/GoalSelectItem/GoalSelectItem'
import LifeGoalWidget from '../LifeGoalWidget/LifeGoalWidget'
import { lifeGoalArr } from './LifeGoalSelect.config'
import { useAppDispatch } from '@/shared/hooks/redux-ts'
import {
    EnGoals,
    changeLifeGoals,
} from '@/shared/store/slices/personalization/baseSlice'

interface Props {}
const LifeGoalSelect: FC<Props> = () => {
    const dispatch = useAppDispatch()
    const [checkedGoal, setCheckedGoal] = useState<EnCharacterPurpose>()

    const handleSelectItem = (type: EnGoals, id: EnCharacterPurpose) => {
        dispatch(changeLifeGoals({ type, id }))
        type === EnGoals.mainGoal && setCheckedGoal(id)
    }

    return (
        <div className={styles.wrapper}>
            <LifeGoalWidget isTitle={false} />
            <div className={styles.title}>Выбрать жизненную цель</div>
            <div className={styles.flexContainer}>
                {lifeGoalArr.map((item) => {
                    return (
                        <GoalSelectItem
                            key={item.id}
                            id={item.id}
                            img={item.icon}
                            title={item.title}
                            onSelect={handleSelectItem}
                            subGoals={item.subGoals}
                            checkedGoal={checkedGoal}
                        />
                    )
                })}
            </div>
        </div>
    )
}

export default LifeGoalSelect
