import styles from './CameraRotation.module.scss'
import mouseImg from './assets/mouseImg.svg'

const CameraRotation = () => {
    return (
        <div className={styles.cameraRotation}>
            <img src={mouseImg} />
            <div>Поворот камеры</div>
        </div>
    )
}

export default CameraRotation
