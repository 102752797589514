import styles from './VoiceChat.module.scss'
import microphoneIconInactive from '@/shared/assets/images/vectors/Hud/VoiceChat/microphoneIconInactive.svg'
import microphoneIconActive from '@/shared/assets/images/vectors/Hud/VoiceChat/microphoneIconActive.svg'
//import telIconActive from './assets/telIconActive.svg'
import telIconInactive from '@/shared/assets/images/vectors/Hud/VoiceChat/telIconInactive.svg'
import { useVoiceChangeQuery, useVoiceSetQuery } from './api/VoiceChatApi'

enum VoiceTypes {
    talk = 'Разговор',
    scream = 'Крик',
    whisper = 'Шепот',
}

export const VoiceChat = () => {
    const { data: isActive } = useVoiceChangeQuery()
    const { data: type = 'talk' } = useVoiceSetQuery()

    return (
        <div className={styles.wrapper}>
            <div className={styles.itemContainer}>
                <div className={styles.iconContainer}>
                    <div className={styles.buttonName}>N</div>
                    <img
                        src={
                            isActive
                                ? microphoneIconActive
                                : microphoneIconInactive
                        }
                        className={styles.icon}
                    />
                </div>
                <div className={styles.title}>
                    {VoiceTypes[type as keyof typeof VoiceTypes]}
                </div>
            </div>
            <div className={styles.itemContainer}>
                <div className={styles.iconContainer}>
                    <div className={styles.buttonName}>R</div>
                    <img src={telIconInactive} className={styles.icon} />
                </div>
                <div className={styles.title}>TAC</div>
            </div>
        </div>
    )
}
