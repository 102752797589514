import { FC } from 'react'
import cls from './index.module.scss'
import { TClassName } from '@/shared/types/shared'
import classNames from 'classnames'
import { Slider } from '../Slider'
import { CharacterPropertiesWrapper } from '@/widgets/CharacterPropertiesWrapper'

interface Props extends TClassName {}
const Sliders: FC<Props> = ({ className }) => {
    return (
        <CharacterPropertiesWrapper
            className={classNames(cls.wrapper, className)}
        >
            <Slider type="father" />
            <Slider type="mother" />
        </CharacterPropertiesWrapper>
    )
}

export { Sliders }
