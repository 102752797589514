import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IInitialState {
    variant?: number
    color?: string
}
const initialState: IInitialState = {}

const beardSlice = createSlice({
    name: 'beard',
    initialState,
    reducers: {
        changeVariant: (state, { payload }: PayloadAction<number>) => {
            state.variant = payload
        },
        changeColor: (state, { payload }: PayloadAction<string>) => {
            state.color = payload
        },
    },
})

export const { changeColor, changeVariant } = beardSlice.actions
export default beardSlice.reducer
