import React, { ButtonHTMLAttributes } from 'react'
import styles from './Button.module.scss'
import classNames from 'classnames'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    value: string
    className?: string
    full?: boolean
    variant?: 'primary' | 'secondary'
}

export const Button: React.FC<ButtonProps> = ({
    value,
    className,
    type = 'button',
    full = false,
    variant = 'primary',
    ...btnProps
}) => {
    return (
        <button
            // eslint-disable-next-line react/button-has-type
            type={type}
            className={classNames(
                styles.container,
                className,
                full && styles.full,
                variant === 'secondary' && styles.secondary
            )}
            {...btnProps}
        >
            {value}
        </button>
    )
}
