import { useState, useEffect } from 'react'
import styles from './HudHints.module.scss'
import { hotkeys } from './HudHints.config'
import cn from 'classnames'
import {
    useAltStatusQuery,
    useLocationToTransportQuery,
} from './api/hudHintsApi'

interface Item {
    key: string
    title: string
    isDynamic: boolean
}

const HudHints = () => {
    const { data: altStatus = false } = useAltStatusQuery()

    const { data: location } = useLocationToTransportQuery()

    const [anim, setAnim] = useState<string | null>(styles.activeKey)
    useEffect(() => {
        setAnim(null)
        const timeout = setTimeout(() => setAnim(styles.activeKey), 50)
        return () => clearTimeout(timeout)
    }, [location])

    return (
        <div className={styles.hints}>
            {altStatus
                ? location &&
                  Array.isArray(hotkeys[location]) &&
                  hotkeys[location].map((item: Item) => {
                      const isDynamic = item.isDynamic
                      return (
                          <div key={item.key} className={styles.wrapper}>
                              <div
                                  className={cn(styles.key, isDynamic && anim)}
                              >
                                  {item.key}
                              </div>
                              <div className={styles.title}>{item.title}</div>
                          </div>
                      )
                  })
                : null}
        </div>
    )
}

export default HudHints
