import { FC, useMemo, useState } from 'react'
import styles from './CharacterTraitsSelect.module.scss'
import { Arr } from './CharacterTraitsSelect.typings'
import TraitSelect from './TraitSelect/TraitSelect'
import cn from 'classnames'
import LifeGoalWidget from '../LifeGoalWidget/LifeGoalWidget'
import {
    traitsArr,
    characterTraitsLabels,
} from './CharacterTraitsSelect.config'
import { useAppSelector } from '@/shared/hooks/redux-ts'

interface Props {}
const CharacterTraitsSelect: FC<Props> = () => {
    const selectedTrait1 = useAppSelector(
        (state) => state.personalization.base.characterTraits.trait1
    )
    const selectedTrait2 = useAppSelector(
        (state) => state.personalization.base.characterTraits.trait2
    )
    const selectedTrait3 = useAppSelector(
        (state) => state.personalization.base.characterTraits.trait3
    )

    const [selectedLabel, setSelectedLabel] = useState<number>(1)
    const [selectedTraits, setSelectedTraits] = useState<[number, Arr[]]>([
        characterTraitsLabels[0].id,
        traitsArr.traits1,
    ])
    const [checkedTrait] = useState<number>(1)

    const handleSelectLabel = (id: number) => {
        setSelectedLabel(id)
        switch (id) {
            case 1: {
                setSelectedTraits([id, traitsArr.traits1])
                break
            }
            case 2: {
                setSelectedTraits([id, traitsArr.traits2])
                break
            }
            case 3: {
                setSelectedTraits([id, traitsArr.traits3])
                break
            }
            case 4: {
                setSelectedTraits([id, traitsArr.traits4])
                break
            }
        }
    }

    const renderedTraits = useMemo(() => {
        return selectedTraits[1].filter(({ id }) => {
            return (
                id !== selectedTrait1?.id &&
                id !== selectedTrait2?.id &&
                id !== selectedTrait3?.id
            )
        })
    }, [selectedTrait1, selectedTrait2, selectedTrait3, selectedLabel])

    return (
        <div className={styles.wrapper}>
            <LifeGoalWidget isTitle={false} />
            <div className={styles.title}>Выбрать черту характера</div>
            <div className={styles.labels}>
                {characterTraitsLabels.map((item) => {
                    return (
                        <div
                            className={cn(
                                styles.label,
                                selectedLabel === item.id && styles.selected
                            )}
                            key={item.id}
                            onClick={() => handleSelectLabel(item.id)}
                        >
                            <img
                                className={styles.labelIcon}
                                src={
                                    selectedTraits[0] === item.id
                                        ? item.icon
                                        : item.inactiveIcon
                                }
                            />
                        </div>
                    )
                })}
            </div>
            <div className={styles.gridBlock}>
                <div className={styles.grid}>
                    {renderedTraits.map((item) => {
                        return (
                            <TraitSelect
                                key={item.id}
                                item={item}
                                trait={checkedTrait}
                            />
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default CharacterTraitsSelect
