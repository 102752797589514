import { MutableRefObject } from 'react'

interface WrapperRects {
    x: number
    y: number
}

export const moveAt = (
    x: number,
    y: number,
    shiftX: number,
    shiftY: number,
    localStorageNameX: string,
    localStorageNameY: string,
    wrapperRects: WrapperRects,
    elementRef: MutableRefObject<HTMLDivElement | null>,
    padding: number,
    scale: number
) => {
    let rectX = x - shiftX + 'px'
    let rectY = y - shiftY + 'px'

    if (elementRef.current) {
        if (x - shiftX < padding) {
            rectX = padding + 'px'
        } else if (
            x - shiftX + elementRef.current.offsetWidth * scale >
            wrapperRects.x - padding
        ) {
            rectX =
                wrapperRects.x -
                padding -
                elementRef.current.offsetWidth * scale +
                'px'
        } else rectX = x - shiftX + 'px'

        if (y - shiftY < padding) {
            rectY = padding + 'px'
        } else if (
            y - shiftY + elementRef.current.offsetHeight * scale >
            wrapperRects.y - padding
        ) {
            rectY =
                wrapperRects.y -
                padding -
                elementRef.current.offsetHeight * scale +
                'px'
        } else rectY = y - shiftY + 'px'
    }

    if (elementRef.current) elementRef.current.style.left = rectX
    if (elementRef.current) elementRef.current.style.top = rectY
    localStorage.setItem(localStorageNameX, rectX)
    localStorage.setItem(localStorageNameY, rectY)
}
