import { rpc } from 'altv-xrpc-webview'
import { baseApi } from '@/shared/store/api/baseApi'
import { OpenProps, CommandProps } from './types'

export const interactionWheelApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        interactionOpen: builder.query<OpenProps, void>({
            queryFn() {
                return {
                    data: {
                        isOpen: false,
                        type: 'withPlayer',
                        hasBag: false,
                        hasCuff: false,
                        hasRope: false,
                        charactersInside: [],
                    },
                }
            },
            async onCacheEntryAdded(
                _,
                { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
            ) {
                try {
                    await cacheDataLoaded

                    const listener = (...args: unknown[]) => {
                        const [data] = args as [OpenProps]
                        updateCachedData((draft) => {
                            if (draft) {
                                draft.isOpen = data.isOpen
                                draft.type = data.type
                                draft.hasBag = data.hasBag
                                draft.hasCuff = data.hasCuff
                                draft.hasRope = data.hasRope
                                draft.charactersInside = data.charactersInside
                            }
                        })
                    }
                    rpc.onClient('interaction:open', listener)
                } catch {
                    // ...
                }
                await cacheEntryRemoved
            },
        }),
        interactionCommand: builder.mutation<CommandProps, CommandProps>({
            async queryFn(data) {
                try {
                    const res = await rpc.emitServer(
                        'interaction:command',
                        data
                    )
                    return { data: res }
                } catch (error: any) {
                    return { error }
                }
            },
        }),
    }),
})

export const { useInteractionOpenQuery, useInteractionCommandMutation } =
    interactionWheelApi
