export enum EnCharacterPurpose {
    Athlete = 'Athlete', // Легкоатлет
    Bodybuilder = 'Bodybuilder', // Культурист
    SuperParent = 'SuperParent', // Супер-родитель
    GoodHeritage = 'GoodHeritage', // Хорошая наследственность
    HappyFamily = 'HappyFamily', // Счастливая семья
    BestBartender = 'BestBartender', // Лучший бармен
    Gourmet = 'Gourmet', // Гурман
    Chef = 'Chef', // Шеф-повар
    FabulousWealth = 'FabulousWealth', // Сказочное богатство
    Baron = 'Baron', // Барон
    Collector = 'Collector', // Коллекционер
    Gangster = 'Gangster', // Гангстер
    EnemyOfTheState = 'EnemyOfTheState', // Враг народа
    OnTwoWheels = 'OnTwoWheels', // На двух колесах
    LawEnforcer = 'LawEnforcer', // Страж порядка
    Hippocrates = 'Hippocrates', // Гиппократ
    Politician = 'Politician', // Политик
    Engineer = 'Engineer', // Инженер
    Vegetarian = 'Vegetarian', // Вегетарианец
    Nutritionist = 'Nutritionist', // Диетолог
    MarathonRunner = 'MarathonRunner', // Марафонец
    Voyager = 'Voyager', // Вояжер
    Jumanji = 'Jumanji', // Джуманджи
    ThrillSeeker = 'ThrillSeeker', // Любитель острых ощущений
    PersonOfTheEra = 'PersonOfTheEra', // Человек эпохи
    SerialRomantic = 'SerialRomantic', // Серийный романтик
    Soulmate = 'Soulmate', // Родственная душа
    Fisherman = 'Fisherman', // Рыбак
    Gardener = 'Gardener', // Садовод
    Hunter = 'Hunter', // Охотник
}
