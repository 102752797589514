import { ChangeEventHandler, FC } from 'react'
import cls from './index.module.scss'
import { TClassName } from '@/shared/types/shared'
import { CharacterPropertyContent } from '@/widgets/CharacterPropertyContent'
import classNames from 'classnames'
import { SliderInput } from '@/shared/ui/SliderInput'
import { useAppDispatch, useAppSelector } from '@/shared/hooks/redux-ts'
import { pxToRem } from '@/shared/lib/pxToRem'
import {
    changeEyebrowHeight,
    changeEyebrowWidth,
} from '@/shared/store/slices/personalization/head/eyebrowSlice'

interface Props extends TClassName {}
const CharacterEyebrowContent: FC<Props> = ({ className }) => {
    const dispatch = useAppDispatch()
    const eyebrowHeight = useAppSelector(
        (state) => state.personalization.head.eyebrow.eyebrowHeight
    )
    const eyebrowWidth = useAppSelector(
        (state) => state.personalization.head.eyebrow.eyebrowWidth
    )
    const handleEyebrowHeight: ChangeEventHandler<HTMLInputElement> = (
        event
    ) => {
        dispatch(changeEyebrowHeight(+event.target.value / 100))
    }
    const handleEyebrowWidth: ChangeEventHandler<HTMLInputElement> = (
        event
    ) => {
        dispatch(changeEyebrowWidth(+event.target.value / 100))
    }

    return (
        <CharacterPropertyContent
            items={[
                {
                    content: (
                        <SliderInput
                            thumbSize={pxToRem(20)}
                            min={0}
                            max={100}
                            step={10}
                            defaultValue={eyebrowHeight * 100}
                            onChange={handleEyebrowHeight}
                        />
                    ),
                    title: 'Высота бровей',
                },
                {
                    content: (
                        <SliderInput
                            thumbSize={pxToRem(20)}
                            min={0}
                            max={100}
                            step={10}
                            defaultValue={eyebrowWidth * 100}
                            onChange={handleEyebrowWidth}
                        />
                    ),
                    title: 'Глубина бровей',
                },
            ]}
            title="Брови"
            className={classNames(cls.wrapper, className)}
        />
    )
}

export { CharacterEyebrowContent }
