export const MainCircleSvg = ({ className }: { className: string }) => {
    return (
        <svg
            viewBox="0 0 893 893"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <circle
                opacity="0.5"
                cx="446.5"
                cy="446.5"
                r="385"
                stroke="url(#paint0_linear_0_803)"
                strokeWidth="123"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_0_803"
                    x1="1076.5"
                    y1="414.5"
                    x2="461.5"
                    y2="418.5"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop />
                    <stop offset="1" stopOpacity="0" />
                </linearGradient>
            </defs>
        </svg>
    )
}
