import React, { useContext } from 'react'
import { rpc } from 'altv-xrpc-webview'
import { useSharedStore } from '@/shared/hooks/useSharedStore'

interface LoginProps {
    email: string
    password: string
}

interface SignupProps {
    login: string
    password: string
    email: string
}

interface AuthContextData {
    isAuth: boolean
    isLoading: boolean
    signUp: (data: SignupProps) => Promise<any>
    login: (data: LoginProps) => Promise<any>
}

export const AuthContext = React.createContext<AuthContextData>({
    isAuth: false,
    isLoading: true,
    signUp() {
        throw 'AuthContext is not initialized'
    },
    login() {
        throw 'AuthContext is not initialized'
    },
})

export const useAuth = () => useContext(AuthContext)

interface Data {
    isAuth: boolean
}

export const useAuthContextData = () => {
    const { state, isInited } = useSharedStore<Data>('auth', { isAuth: true })

    const signUp = (data: SignupProps) =>
        rpc.emitServerWithTimeout('auth:sign-up', 2000, data)
    const login = (data: LoginProps) =>
        rpc.emitServerWithTimeout('auth:login', 2000, data)

    return { ...state, isLoading: !isInited, signUp, login }
}
