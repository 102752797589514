import styles from './MainScreen.module.scss'
import addMoneyPic from '@/shared/assets/images/vectors/ATM/MainScreen/addMoneyPic.svg'
import withdrawalMoneyPic from '@/shared/assets/images/vectors/ATM/MainScreen/withdrawalMoneyPic.svg'
import transfersPic from '@/shared/assets/images/vectors/ATM/shared/transfersPic.svg'
import balancePic from '@/shared/assets/images/vectors/ATM/MainScreen/balancePic.svg'
import NavButton from '../components/NavButton'
import { Screen } from '../ATM.config'
import { FC } from 'react'

interface Props {
    onSelect: (activeScreen: Screen) => void
}

const MainScreen: FC<Props> = ({ onSelect }) => {
    return (
        <div className={styles.cardShape}>
            <div className={styles.bottomBackground} />
            <div className={styles.navigation}>
                <div className={styles.leftTopNavBlock}>
                    <NavButton
                        title="Положить на счет"
                        pic={addMoneyPic}
                        onSelect={() => onSelect(Screen.AddMoney)}
                    />
                </div>
                <div className={styles.rightTopNavBlock}>
                    <NavButton
                        title="Снять наличные"
                        pic={withdrawalMoneyPic}
                        onSelect={() => onSelect(Screen.PinCode)}
                    />
                </div>
                <div className={styles.leftBottomNavBlock}>
                    <NavButton
                        title="Платежи и переводы"
                        pic={transfersPic}
                        onSelect={() => onSelect(Screen.PaymentsAndTransfers)}
                    />
                </div>
                <div className={styles.rightBottomNavBlock}>
                    <NavButton
                        title="Запросить баланс"
                        pic={balancePic}
                        onSelect={() => onSelect(Screen.Balance)}
                    />
                </div>
            </div>
        </div>
    )
}

export default MainScreen
