import * as React from 'react'
import { SVGProps } from 'react'

export const Escort = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="45"
        height="52"
        viewBox="0 0 45 52"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M33.6019 5.70696C31.6945 6.38154 30.1592 9.03335 30.5779 10.9873C31.0664 13.2902 32.6947 14.7092 35.0209 14.8487C36.9748 14.9883 38.3938 14.337 39.301 12.8715C40.6036 10.8245 40.3943 8.59139 38.7427 6.87003C37.347 5.42782 35.5559 5.00911 33.6019 5.70696Z"
            fill={props.color}
        />
        <path
            d="M10.7089 0.330006C8.80141 1.00459 7.26615 3.6564 7.68486 5.61037C8.17335 7.91326 9.80165 9.33221 12.1278 9.47178C14.0818 9.61135 15.5007 8.96002 16.4079 7.49455C17.7106 5.44754 17.5012 3.21443 15.8496 1.49308C14.454 0.0508671 12.6628 -0.36784 10.7089 0.330006Z"
            fill={props.color}
        />
        <path
            d="M4.73433 11.3597C1.89643 13.43 0.314643 16.9657 0.0587659 21.8972C-0.127326 25.526 0.128551 27.8521 0.826396 28.5267C1.5475 29.2711 2.61753 29.2711 3.40842 28.4802C3.98996 27.8986 3.98996 27.8754 3.82713 25.177C3.75735 23.6185 3.78061 21.7576 3.92018 20.8271C4.17605 19.0593 4.85064 16.7564 5.10651 16.7564C5.1763 16.7564 5.68805 18.5475 6.1998 20.7573L7.15353 24.7583L5.19956 36.1797C4.10627 42.4371 3.22233 48.0198 3.22233 48.5548C3.22233 49.0666 3.31538 49.7412 3.47821 50.0203C3.85039 50.7182 5.10651 51.416 6.01371 51.416C6.94417 51.416 8.20029 50.6949 8.52596 49.9738C8.66552 49.6714 9.5262 44.9958 10.4567 39.5526C11.3871 34.1095 12.2245 29.5735 12.3408 29.4571C12.5967 29.178 12.5269 28.8756 14.4111 39.9946C15.3183 45.275 16.1557 49.811 16.2953 50.0668C16.6442 50.7414 17.9236 51.416 18.7843 51.416C20.273 51.416 21.6222 50.1366 21.6222 48.7177C21.6222 48.3455 20.715 42.8325 19.6217 36.4123L17.6445 24.7816L14.132 19.8967C12.178 17.1983 9.92165 14.0348 9.08423 12.8717C7.57223 10.7549 7.15514 10.7308 6.23077 10.7308C5.55619 10.7308 5.38565 10.8945 4.73433 11.3597Z"
            fill={props.color}
        />
        <path
            d="M6.23071 10.7306C6.32376 10.8934 9.46628 14.813 11.7692 17.9998L17.6538 25.269L18.5981 20.6408L19.2029 18.3147L20.459 19.8732C21.6686 21.3852 23.2039 22.7111 24.8787 23.7113C25.3207 23.9672 25.7627 24.1998 25.8557 24.1998C25.9255 24.1998 25.8092 23.6648 25.5533 23.0368C25.2974 22.3854 25.1113 21.2922 25.1113 20.5245C25.1113 19.2684 25.0416 19.1056 24.2739 18.3612C23.1341 17.2912 21.5989 15.0813 20.6917 13.2669C19.3425 10.5686 19.7379 10.7082 14.1319 10.7082C10.1077 10.7082 6.23071 10.7306 6.23071 10.7306Z"
            fill={props.color}
        />
        <path
            d="M29.7171 13.7091C28.461 14.5233 26.9257 16.1748 26.2279 17.5007C25.8092 18.2916 25.6929 18.8732 25.6929 20.3619C25.6929 21.9902 25.7859 22.4089 26.4372 23.7348C27.2514 25.3864 30.1823 28.5267 30.3917 27.9684C30.4615 27.8056 30.8569 27.131 31.2989 26.4797L32.0898 25.3166L31.5315 24.8746C30.7174 24.2233 29.4147 22.3624 29.0891 21.3622C28.7634 20.3619 29.0658 18.8732 29.6939 18.3614C30.0428 18.059 30.066 18.1055 29.9032 18.6173C29.2519 20.6643 29.531 21.8972 31.1361 23.7348C31.9037 24.642 32.113 24.7583 32.8807 24.7118C33.6483 24.6653 33.7181 24.7118 33.7181 25.2933C33.7181 25.619 33.9274 26.1308 34.1833 26.4099C34.951 27.2241 34.9975 28.1545 34.3462 29.1315C33.6716 30.1085 33.8111 30.248 35.3464 30.248C36.8817 30.248 37.0212 30.1085 36.3466 29.1315C35.6953 28.1545 35.7418 27.2241 36.5095 26.4099C36.7653 26.1308 36.9747 25.619 36.9747 25.2933C36.9747 24.7118 37.0445 24.6653 37.8121 24.7118C38.5565 24.7583 38.7891 24.642 39.5335 23.7813C41.1153 21.9902 41.4409 20.6643 40.7896 18.6173C40.6268 18.1055 40.65 18.059 40.9989 18.3614C41.627 18.8732 41.9294 20.3619 41.6037 21.3622C41.2548 22.4554 40.2081 23.9674 39.3009 24.7351L38.603 25.3166L39.3939 26.5029C39.8359 27.131 40.2313 27.8056 40.3011 27.9684C40.5105 28.5267 43.4414 25.3864 44.2556 23.7348C44.9069 22.4089 44.9999 21.9902 44.9999 20.3619C44.9999 18.8732 44.8836 18.2916 44.4649 17.5007C43.7671 16.1748 42.2318 14.5233 40.9757 13.7091L39.9522 13.0811L39.0682 13.965C38.2308 14.8024 36.4397 15.5933 35.3464 15.5933C34.2531 15.5933 32.462 14.8024 31.6246 13.965L30.7406 13.0811L29.7171 13.7091Z"
            fill={props.color}
        />
        <path
            d="M32.3226 27.387C31.4619 28.7594 31.4852 29.4107 32.3924 29.5038C33.4624 29.6201 34.1835 28.992 34.1835 27.9453C34.1835 27.1544 33.6718 26.2937 33.1833 26.2937C33.0902 26.2937 32.7181 26.7822 32.3226 27.387Z"
            fill={props.color}
        />
        <path
            d="M36.9048 26.7122C36.3 27.3868 36.3931 28.7592 37.0677 29.2012C37.8353 29.713 38.9286 29.5734 39.0216 28.9686C39.0914 28.4801 37.8353 26.2935 37.4864 26.2935C37.3701 26.2935 37.1142 26.4796 36.9048 26.7122Z"
            fill={props.color}
        />
        <path
            d="M29.531 38.8546C29.531 50.1365 29.531 50.2062 30.7406 51.0437C31.4152 51.5089 32.6248 51.5089 33.5087 51.0437C34.8346 50.3691 34.8812 49.9969 34.8812 40.134C34.8812 31.3412 34.8812 31.1783 35.3464 31.1783C35.8116 31.1783 35.8116 31.3412 35.8116 40.134C35.8116 49.9969 35.8581 50.3691 37.184 51.0437C38.068 51.5089 39.2776 51.5089 39.9522 51.0437C41.1618 50.2062 41.1618 50.1365 41.1618 38.8546C41.1618 31.9227 41.092 28.4102 40.9291 28.5033C40.8128 28.573 40.6965 28.9685 40.6965 29.3407C40.6965 30.0618 40.1383 30.4805 39.7195 30.0618C39.4171 29.7594 37.6958 29.992 36.998 30.434C36.3001 30.8992 34.3927 30.8992 33.6948 30.4572C32.9504 29.9687 31.3919 29.7361 30.9965 30.0385C30.508 30.4572 29.9962 30.085 29.9962 29.3407C29.9962 28.9685 29.9032 28.573 29.7636 28.5033C29.6008 28.4102 29.531 31.9227 29.531 38.8546Z"
            fill={props.color}
        />
    </svg>
)
