import styles from './LifeGoalWidget.module.scss'
import hexagonGroup from '../../assets/hexagonGroup.svg'
import questionSign from '@/pages/CharacterPersonalization/assets/questionSign.svg'
import { lifeGoalArr } from '../LifeGoalSelect/LifeGoalSelect.config'
import { useAppDispatch, useAppSelector } from '@/shared/hooks/redux-ts'
import classNames from 'classnames'
import { FC } from 'react'
import { useTraitSelect } from '../../hooks/useTraitSelect'
import {
    visibleComponentGoalSelect,
    EnTraitsSlot,
} from '@/shared/store/slices/personalization/baseSlice'
import { changeMainContentVisible } from '@/shared/store/slices/personalization/personalizationSlice'

interface Props {
    isTitle: boolean
}
const LifeGoalWidget: FC<Props> = ({ isTitle }) => {
    const onTraitSelect = useTraitSelect()
    const dispatch = useAppDispatch()
    const isLifeGoalVisible = useAppSelector(
        (state) => state.personalization.base.isLifeGoalVisible
    )
    const isCharacterTraitsVisible = useAppSelector(
        (state) => state.personalization.base.isCharacterTraitsVisible
    )
    const selectedTraitSlot = useAppSelector(
        (state) => state.personalization.base.selectedTraitSlot
    )
    const lifeGoals = useAppSelector(
        (state) => state.personalization.base.lifeGoals
    )
    const characterTraits = useAppSelector(
        (state) => state.personalization.base.characterTraits
    )
    const mainGoal = lifeGoals.mainGoalId
        ? lifeGoalArr.find((item) => item.id === lifeGoals.mainGoalId)
        : null
    // const mainGoalIcon = mainGoal ? mainGoal.icon : questionSign
    const additionalGoal = mainGoal
        ? mainGoal.subGoals.find(
              (item) => item.id === lifeGoals.additionalGoalId
          )
        : null
    const additionalGoalIcon = additionalGoal
        ? additionalGoal.icon
        : questionSign

    const trait1 = characterTraits.trait1?.icon ?? questionSign
    const trait2 = characterTraits.trait2?.icon ?? questionSign
    const trait3 = characterTraits.trait3?.icon ?? questionSign

    return (
        <div
            className={styles.wrapper}
            style={{
                background: isTitle ? 'rgba(32, 32, 32, 1)' : 'transparent',
            }}
        >
            {isTitle ? (
                <div className={styles.title}>
                    Черты характера и жизненная цель:
                </div>
            ) : null}
            <img src={hexagonGroup} className={styles.hexagonGroup} />
            <div
                className={classNames(styles.mainGoal, styles.selectableItem, {
                    [styles.isSelected]: isLifeGoalVisible,
                })}
                onClick={() => {
                    dispatch(visibleComponentGoalSelect())
                    dispatch(changeMainContentVisible(false))
                }}
            >
                <img src={additionalGoalIcon} className={styles.mainGoalIcon} />
            </div>
            <div className={styles.additionalGoal}>
                <img src={questionSign} className={styles.additionalGoalIcon} />
            </div>
            <div
                className={classNames(styles.trait1, styles.selectableItem, {
                    [styles.isSelected]:
                        isCharacterTraitsVisible && selectedTraitSlot === 0,
                })}
                onClick={() => onTraitSelect(EnTraitsSlot.trait1)}
            >
                <img src={trait1} className={styles.trait1Icon} />
            </div>
            <div
                className={classNames(styles.trait2, styles.selectableItem, {
                    [styles.isSelected]:
                        isCharacterTraitsVisible && selectedTraitSlot === 1,
                })}
                onClick={() => onTraitSelect(EnTraitsSlot.trait2)}
            >
                <img src={trait2} className={styles.trait2Icon} />
            </div>
            <div
                className={classNames(styles.trait3, styles.selectableItem, {
                    [styles.isSelected]:
                        isCharacterTraitsVisible && selectedTraitSlot === 2,
                })}
                onClick={() => onTraitSelect(EnTraitsSlot.trait3)}
            >
                <img src={trait3} className={styles.trait3Icon} />
            </div>
        </div>
    )
}

export default LifeGoalWidget
