import * as Yup from 'yup'

export const passwordValidate = Yup.string()
    .required('Необходимо указать пароль')
    .min(6, 'Пароль должен иметь от 6 до 20 символов')
    .max(20, 'Пароль должен иметь от 6 до 20 символов')
    .matches(
        /^[0-9a-zA-Zа-яА-Я$&+,:;=?@#|'<>.-^*()%!]+$/,
        'Пароль содержит недопустимые символы'
    )

export const confirmationPasswordValidate = (passwordFieldName: string) =>
    Yup.string()
        .oneOf(
            [Yup.ref(passwordFieldName), undefined],
            'Пароли должны быть одинаковыми'
        )
        .required('Повторите пароль')

export const usernameValidate = Yup.string()
    .required('Необходимо указать логин')
    .min(4, 'Логин должен иметь от 4 до 14 символов')
    .max(14, 'Логин должен иметь от 4 до 14 символов')
    .lowercase('Логин должен быть в нижнем регистре')
    .matches(
        /^[a-z]+([-_]?[a-z0-9]+){0,2}$/i,
        'Логин содержит недопустимые символы'
    )

export const emailValidate = Yup.string()
    .email('Некорректно указан e-mail')
    .required('Необходимо указать e-mail')
