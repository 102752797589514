export interface Arr {
    id: EnCharacterTrait
    icon: string
    title: string
    selected: boolean
}

export enum EnCharacterTrait {
    Active = 'Active', // Активный(-ая)
    Cheerful = 'Cheerful', // Весёлый(-ая)
    HighMaintenance = 'HighMaintenance', // Высокие запросы
    Genius = 'Genius', // Гений
    Touchy = 'Touchy', // Недотрога
    Butterfingers = 'Butterfingers', // Растяпа
    Romantic = 'Romantic', // Романтик
    Confident = 'Confident', // Самоуверенный(-ая)
    Creator = 'Creator', // Творец
    Moody = 'Moody', // Угрюмый(-ая)
    Choleric = 'Choleric', // Холерик
    Gourmet = 'Gourmet', // Гурман
    ArtConnoisseur = 'ArtConnoisseur', // Искусствовед
    Bookworm = 'Bookworm', // Книжный червь
    MusicLover = 'MusicLover', // Меломан
    Perfectionist = 'Perfectionist', // Перфекционист(-ка)
    Handy = 'Handy', // Умелец
    Eccentric = 'Eccentric', // Эксцентричный(-ая)
    Vegetarian = 'Vegetarian', // Вегетарианец(-ка)
    EternalChild = 'EternalChild', // Вечное дитя
    Careerist = 'Careerist', // Карьерист(-ка)
    Kleptomaniac = 'Kleptomaniac', // Клептоман(-ка)
    CouchPotato = 'CouchPotato', // Лежебока
    CatLover = 'CatLover', // Любит кошек
    NatureLover = 'NatureLover', // Любит природу
    DogLover = 'DogLover', // Любит собак
    AnimalLover = 'AnimalLover', // Любовь к животным
    LactoseIntolerant = 'LactoseIntolerant', // Непереносимость лактозы
    Slob = 'Slob', // Неряха
    Clumsy = 'Clumsy', // Неуклюжий(-ая)
    Glutton = 'Glutton', // Обжора
    Brave = 'Brave', // Отважный(-ая)
    Practical = 'Practical', // Практичный(-ая)
    Picky = 'Picky', // Привереда
    Skeptic = 'Skeptic', // Скептик
    DanceMachine = 'DanceMachine', // Танцмашина
    Freegan = 'Freegan', // Фриган
    NeatFreak = 'NeatFreak', // Чистюля
    Quirky = 'Quirky', // Чудаковатый(-ая)
    EcoEnthusiast = 'EcoEnthusiast', // Экоэнтузиаст
    Kind = 'Kind', // Добрый(-ая)
    Friendly = 'Friendly', // Дружелюбный(-ая)
    Bully = 'Bully', // Задира
    Evil = 'Evil', // Злой(-ая)
    Insider = 'Insider', // Инсайдер
    Fickle = 'Fickle', // Непостоянный(-ая)
    Loner = 'Loner', // Одиночка
    Paranoid = 'Paranoid', // Параноик
    AntiKids = 'AntiKids', // Против детей
    Jealous = 'Jealous', // Ревнивый(-ая)
    Easygoing = 'Easygoing', // Свой(-я) в доску
    FamilyOriented = 'FamilyOriented', // Семьянин
    Respectful = 'Respectful', // Уважительный(-ая)
    Egotistical = 'Egotistical', // Эгоцентричный(-ая)
}
