import { useState, useEffect } from 'react'
import rpc from 'altv-rpc'
import { GetData } from '../PoliceRadar.typings'

export const usePoliceRadar = () => {
    const [isVisible, setVisible] = useState<boolean>(false)
    const [getData, setGetData] = useState<GetData>({
        patrolCarSpeed: 0,
        xMitFrontSpeed: 30,
        xMitRearSpeed: 50,
        frontCarPlate: 'F30FF',
        rearCarPlate: 'F69GG',
        xMitFrontOn: false,
        xMitRearOn: false,
    })
    const radarData = { isVisible: isVisible, getData: getData }

    useEffect(() => {
        const event = 'policeRadar:show'

        const callback = ({ data }: any) => {
            setVisible(data.show)
            setGetData(data.getData)
        }

        rpc.on(event, callback)
        return () => {
            rpc.off(event, callback)
        }
    }, [])

    return radarData
}
