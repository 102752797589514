import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import gait1Icon from '@/pages/CharacterPersonalization/screens/About/components/GaitSelect/assets/gait1Icon.svg'
import { EnCharacterPurpose } from '@/pages/CharacterPersonalization/screens/About/components/LifeGoalSelect/LifeGoalSelect.typings'
import { EnCharacterTrait } from '@/pages/CharacterPersonalization/screens/About/components/CharacterTraitsSelect/CharacterTraitsSelect.typings'

//////////// TYPES ///////////////
export enum EnGender {
    male = 'male',
    female = 'female',
}
type TGate = {
    id: number
    icon: string
    title: string
}
export interface IAboutSettings {
    firstName: string
    lastName: string
    country: string
    city: string
    gender: EnGender
    weight: number
    height: number
    gait: TGate
    age: string
    monthOfBirth: string
    dayOfBirth: string
    nationality: string
}
export interface ILifeGoals {
    mainGoalId: null | number
    additionalGoalId: null | EnCharacterPurpose
}
export enum EnTraitsSlot {
    'trait1',
    'trait2',
    'trait3',
}
interface IArr {
    id: EnCharacterTrait
    icon: string
    title: string
    selected: boolean
}
interface ICharacterTraits {
    trait1: null | IArr
    trait2: null | IArr
    trait3: null | IArr
}
export enum EnGoals {
    mainGoal,
    additionalGoal,
}
//////////// TYPES ///////////////

interface IInitialState {
    aboutSettings: IAboutSettings
    isLifeGoalVisible: boolean
    isCharacterTraitsVisible: boolean
    lifeGoals: ILifeGoals
    selectedTraitSlot: EnTraitsSlot
    characterTraits: ICharacterTraits
}
const initialState: IInitialState = {
    isCharacterTraitsVisible: false,
    isLifeGoalVisible: false,
    selectedTraitSlot: EnTraitsSlot.trait1,
    characterTraits: {
        trait1: null,
        trait2: null,
        trait3: null,
    },
    lifeGoals: {
        mainGoalId: null,
        additionalGoalId: null,
    },
    aboutSettings: {
        firstName: '',
        lastName: '',
        country: '',
        city: '',
        gender: EnGender.male,
        weight: 0,
        height: 0,
        gait: { id: 1, icon: gait1Icon, title: 'Отличная походка' },
        age: '',
        monthOfBirth: '',
        dayOfBirth: '',
        nationality: '',
    },
}

const personalizationBaseSlice = createSlice({
    name: 'personalization',
    initialState,
    reducers: {
        changeLifeGoals(
            state,
            {
                payload: { id, type },
            }: PayloadAction<{ type: EnGoals; id: EnCharacterPurpose | number }>
        ) {
            if (type === EnGoals.mainGoal) {
                if (typeof id === 'number') {
                    const newState = { ...state.lifeGoals, mainGoalId: id }
                    state.lifeGoals = newState
                }
            } else if (type === EnGoals.additionalGoal) {
                if (typeof id !== 'number') {
                    const newState = {
                        ...state.lifeGoals,
                        additionalGoalId: id,
                    }
                    state.lifeGoals = newState
                }
            }
        },
        changeCharacterTraits(state, { payload }: PayloadAction<IArr>) {
            const newState = {
                ...state.characterTraits,
                [EnTraitsSlot[state.selectedTraitSlot]]: payload,
            }
            state.characterTraits = newState
        },
        changeAboutSettings(
            state,
            {
                payload: { key, value },
            }: PayloadAction<{ key: string; value: string | number | TGate }>
        ) {
            const newSettings = {
                ...state.aboutSettings,
                [key]: value,
            }
            state.aboutSettings = newSettings
        },
        randomName(
            state,
            {
                payload: { firstName, lastName },
            }: PayloadAction<{ firstName: string; lastName: string }>
        ) {
            const newSettings = {
                ...state.aboutSettings,
                firstName,
                lastName,
            }

            state.aboutSettings = newSettings
        },
        visibleComponentGoalSelect(state) {
            state.isLifeGoalVisible = true
            state.isCharacterTraitsVisible = false
        },
        visibleComponentTraitSelect(state) {
            state.isLifeGoalVisible = false
            state.isCharacterTraitsVisible = true
        },
        visibleComponentPlayerInfoForm(state) {
            state.isLifeGoalVisible = false
            state.isCharacterTraitsVisible = false
        },
        setAboutSettings(state, { payload }: PayloadAction<IAboutSettings>) {
            state.aboutSettings = payload
        },
        setSelectedTraitSlot(state, { payload }: PayloadAction<EnTraitsSlot>) {
            state.selectedTraitSlot = payload
        },
    },
})

export const {
    visibleComponentGoalSelect,
    visibleComponentTraitSelect,
    visibleComponentPlayerInfoForm,
    changeLifeGoals,
    changeAboutSettings,
    changeCharacterTraits,
    randomName,
    setAboutSettings,
    setSelectedTraitSlot,
} = personalizationBaseSlice.actions
export default personalizationBaseSlice.reducer
