import { FC } from 'react'
import { pxToRem } from '../../lib/pxToRem'

interface GapProps {
    size?: number
    direction?: 'verctical' | 'horizontal'
}

export const Gap: FC<GapProps> = ({ size = 16, direction = 'vertical' }) => {
    return (
        <div
            style={{
                paddingBottom:
                    direction === 'vertical' ? `${pxToRem(size)}rem` : 0,
                paddingRight:
                    direction === 'horizontal' ? `${pxToRem(size)}rem` : 0,
            }}
        />
    )
}
