import personalization from './personalizationSlice'
import base from './baseSlice'
import view from './viewSlice'
import genetic from './geneticSlice'
import { headReducers } from './head'
import { hairReducers } from './hair'
import { combineReducers } from '@reduxjs/toolkit'

export const personalizationReducers = combineReducers({
    personalization,
    view,
    base,
    genetic,
    head: headReducers,
    hair: hairReducers,
})
