import color0 from '@/shared/assets/images/illustrations/Character/Parameters/color0.png'
import color1 from '@/shared/assets/images/illustrations/Character/Parameters/color1.png'
import color2 from '@/shared/assets/images/illustrations/Character/Parameters/color2.png'
import color3 from '@/shared/assets/images/illustrations/Character/Parameters/color3.png'
import color4 from '@/shared/assets/images/illustrations/Character/Parameters/color4.png'
import color5 from '@/shared/assets/images/illustrations/Character/Parameters/color5.png'
import color6 from '@/shared/assets/images/illustrations/Character/Parameters/color6.png'
import color7 from '@/shared/assets/images/illustrations/Character/Parameters/color7.png'
import color8 from '@/shared/assets/images/illustrations/Character/Parameters/color8.png'
import color9 from '@/shared/assets/images/illustrations/Character/Parameters/color9.png'
import color10 from '@/shared/assets/images/illustrations/Character/Parameters/color10.png'
import color11 from '@/shared/assets/images/illustrations/Character/Parameters/color11.png'
import color12 from '@/shared/assets/images/illustrations/Character/Parameters/color12.png'
import color13 from '@/shared/assets/images/illustrations/Character/Parameters/color13.png'
import color14 from '@/shared/assets/images/illustrations/Character/Parameters/color14.png'
import color15 from '@/shared/assets/images/illustrations/Character/Parameters/color15.png'
import color16 from '@/shared/assets/images/illustrations/Character/Parameters/color16.png'
import color17 from '@/shared/assets/images/illustrations/Character/Parameters/color17.png'
import color18 from '@/shared/assets/images/illustrations/Character/Parameters/color18.png'
import color19 from '@/shared/assets/images/illustrations/Character/Parameters/color19.png'
import color20 from '@/shared/assets/images/illustrations/Character/Parameters/color20.png'
import color21 from '@/shared/assets/images/illustrations/Character/Parameters/color21.png'
import color22 from '@/shared/assets/images/illustrations/Character/Parameters/color22.png'
import color23 from '@/shared/assets/images/illustrations/Character/Parameters/color23.png'
import color24 from '@/shared/assets/images/illustrations/Character/Parameters/color24.png'
import color25 from '@/shared/assets/images/illustrations/Character/Parameters/color25.png'
import color26 from '@/shared/assets/images/illustrations/Character/Parameters/color26.png'
import color27 from '@/shared/assets/images/illustrations/Character/Parameters/color27.png'
import color28 from '@/shared/assets/images/illustrations/Character/Parameters/color28.png'
import color29 from '@/shared/assets/images/illustrations/Character/Parameters/color29.png'
import color30 from '@/shared/assets/images/illustrations/Character/Parameters/color30.png'
import color31 from '@/shared/assets/images/illustrations/Character/Parameters/color31.png'
import color32 from '@/shared/assets/images/illustrations/Character/Parameters/color32.png'
import color33 from '@/shared/assets/images/illustrations/Character/Parameters/color33.png'
import color34 from '@/shared/assets/images/illustrations/Character/Parameters/color34.png'
import color35 from '@/shared/assets/images/illustrations/Character/Parameters/color35.png'
import color36 from '@/shared/assets/images/illustrations/Character/Parameters/color36.png'
import color37 from '@/shared/assets/images/illustrations/Character/Parameters/color37.png'
import color38 from '@/shared/assets/images/illustrations/Character/Parameters/color38.png'
import color39 from '@/shared/assets/images/illustrations/Character/Parameters/color39.png'
import color40 from '@/shared/assets/images/illustrations/Character/Parameters/color40.png'
import color41 from '@/shared/assets/images/illustrations/Character/Parameters/color41.png'
import color42 from '@/shared/assets/images/illustrations/Character/Parameters/color42.png'
import color43 from '@/shared/assets/images/illustrations/Character/Parameters/color43.png'
import color44 from '@/shared/assets/images/illustrations/Character/Parameters/color4.png'
import color45 from '@/shared/assets/images/illustrations/Character/Parameters/color45.png'
import { EnFamilyGenes } from './slides'

const COLORS = [
    {
        id: EnFamilyGenes.Benjamin,
        colorImage: color0,
    },
    {
        id: EnFamilyGenes.Daniel,
        colorImage: color1,
    },
    {
        id: EnFamilyGenes.Joshua,
        colorImage: color2,
    },
    {
        id: EnFamilyGenes.Noah,
        colorImage: color3,
    },
    {
        id: EnFamilyGenes.Andrew,
        colorImage: color4,
    },
    {
        id: EnFamilyGenes.Juan,
        colorImage: color5,
    },
    {
        id: EnFamilyGenes.Alex,
        colorImage: color6,
    },
    {
        id: EnFamilyGenes.Isaac,
        colorImage: color7,
    },
    {
        id: EnFamilyGenes.Evan,
        colorImage: color8,
    },
    {
        id: EnFamilyGenes.Ethan,
        colorImage: color9,
    },
    {
        id: EnFamilyGenes.Vincent,
        colorImage: color10,
    },
    {
        id: EnFamilyGenes.Angel,
        colorImage: color11,
    },
    {
        id: EnFamilyGenes.Diego,
        colorImage: color12,
    },
    {
        id: EnFamilyGenes.Adrian,
        colorImage: color13,
    },
    {
        id: EnFamilyGenes.Gabriel,
        colorImage: color14,
    },
    {
        id: EnFamilyGenes.Michael,
        colorImage: color15,
    },
    {
        id: EnFamilyGenes.Santiago,
        colorImage: color16,
    },
    {
        id: EnFamilyGenes.Kevin,
        colorImage: color17,
    },
    {
        id: EnFamilyGenes.Louis,
        colorImage: color18,
    },
    {
        id: EnFamilyGenes.Samuel,
        colorImage: color19,
    },
    {
        id: EnFamilyGenes.Anthony,
        colorImage: color20,
    },
    {
        id: EnFamilyGenes.Hannah,
        colorImage: color21,
    },
    {
        id: EnFamilyGenes.Audrey,
        colorImage: color22,
    },
    {
        id: EnFamilyGenes.Jasmine,
        colorImage: color23,
    },
    {
        id: EnFamilyGenes.Giselle,
        colorImage: color24,
    },
    {
        id: EnFamilyGenes.Amelia,
        colorImage: color25,
    },
    {
        id: EnFamilyGenes.Isabella,
        colorImage: color26,
    },
    {
        id: EnFamilyGenes.Zoe,
        colorImage: color27,
    },
    {
        id: EnFamilyGenes.Ava,
        colorImage: color28,
    },
    {
        id: EnFamilyGenes.Camila,
        colorImage: color29,
    },
    {
        id: EnFamilyGenes.Violet,
        colorImage: color30,
    },
    {
        id: EnFamilyGenes.Sophia,
        colorImage: color31,
    },
    {
        id: EnFamilyGenes.Evelyn,
        colorImage: color32,
    },
    {
        id: EnFamilyGenes.Nicole,
        colorImage: color33,
    },
    {
        id: EnFamilyGenes.Ashley,
        colorImage: color34,
    },
    {
        id: EnFamilyGenes.Grace,
        colorImage: color35,
    },
    {
        id: EnFamilyGenes.Brianna,
        colorImage: color36,
    },
    {
        id: EnFamilyGenes.Natalie,
        colorImage: color37,
    },
    {
        id: EnFamilyGenes.Olivia,
        colorImage: color38,
    },
    {
        id: EnFamilyGenes.Elizabeth,
        colorImage: color39,
    },
    {
        id: EnFamilyGenes.Charlotte,
        colorImage: color40,
    },
    {
        id: EnFamilyGenes.Emma,
        colorImage: color41,
    },
    {
        id: EnFamilyGenes.Claude,
        colorImage: color42,
    },
    {
        id: EnFamilyGenes.Niko,
        colorImage: color43,
    },
    {
        id: EnFamilyGenes.John,
        colorImage: color44,
    },
    {
        id: EnFamilyGenes.Misty,
        colorImage: color45,
    },
] as const
export { COLORS }
