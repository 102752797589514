import { FC } from 'react'
import styles from './GenderSelect.module.scss'
import classNames from 'classnames'
import { Props } from '@/widgets/GenderSelect/GenderSelect.typings'
import { Male } from '@/shared/icons/Male'
import { Female } from '@/shared/icons/Female'
import { EnGender } from '@/shared/store/slices/personalization/baseSlice'

export const GenderSelect: FC<Props> = ({ value, onSelect }) => {
    return (
        <div className={styles.container}>
            <div
                className={classNames(
                    value === EnGender.female && styles.active
                )}
                onClick={() => onSelect?.(EnGender.female)}
            >
                <Male className={styles.icon} />
            </div>
            <div
                className={classNames(value === EnGender.male && styles.active)}
                onClick={() => onSelect?.(EnGender.male)}
            >
                <Female className={styles.icon} />
            </div>
        </div>
    )
}
