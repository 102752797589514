import { useState, useEffect } from 'react'
import styles from '../../PoliceRadar.module.scss'
import { Props } from './SpeedWindow.typings'

const SpeedWindow = (props: Props) => {
    const [displayValue, setDisplayValue] = useState<string | null>('')

    useEffect(() => {
        if (props.isTurningOn) setDisplayValue('888')
        else if (props.menuTitle) {
            setDisplayValue(props.menuTitle)
        } else if (props.xMitSameOn || props.xMitOppOn) {
            setDisplayValue(props.speed)
        } else {
            setDisplayValue('')
        }
    }, [props])

    return (
        <div className={styles.display}>
            <p className={styles.ghost_speed}>888</p>
            <p className={styles.speed}>{displayValue}</p>
        </div>
    )
}

export default SpeedWindow
