enum EnColors {
    dark_brown = 'color_1_1',
    soft_brown = 'color_1_10',
    warm_tan = 'color_1_15',
    deep_terracotta = 'color_1_20',
    medium_brown = 'color_1_25',
    muted_taupe = 'color_1_30',
    dusty_rose = 'color_1_32',
    vibrant_pink = 'color_1_33',
    bold_crimson = 'color_1_34',
    rich_mauve = 'color_1_35',
    forest_green = 'color_1_36',
    deep_teal = 'color_1_37',
    slate_gray = 'color_1_38',
    olive_green = 'color_1_39',
    earthy_gray = 'color_1_41',
    golden_rod = 'color_1_42',
    warm_amber = 'color_1_46',
    bright_orange = 'color_1_47',
    copper_tone = 'color_1_48',
    burnt_sienna = 'color_1_52',
    dark_cherry = 'color_1_53',
    crimson_red = 'color_2_1',
    faded_brick = 'color_2_3',
    rosy_pink = 'color_2_18',
    clay_red = 'color_2_22',
    bright_scarlet = 'color_2_23',
    deep_rose = 'color_2_25',
    magenta_hue = 'color_2_26',
    purple_blush = 'color_2_27',
    plum_purple = 'color_2_28',
    steel_blue = 'color_2_35',
    emerald_green = 'color_2_38',
    moss_green = 'color_2_40',
    mustard_yellow = 'color_2_44',
    rusty_orange = 'color_2_48',
    beige_cream = 'color_2_52',
    taupe_brown = 'color_2_53',
    sage_green = 'color_2_57',
    charcoal_gray = 'color_2_59',
    vibrant_leaf = 'color_4_2',
    sienna_brown = 'color_5_20',
    dusty_peach = 'color_5_35',
    mossy_forest = 'color_5_40',
    chartreuse = 'color_5_43',
    brick_orange = 'color_5_48',
    scarlet_blood = 'color_5_53',
    crimson_blush = 'color_6_1',
    rosewood = 'color_6_25',
}

const COLORS = [
    { id: EnColors.dark_brown, rgb: 'rgb(53,40,33)' },
    { id: EnColors.soft_brown, rgb: 'rgb(79,61,44)' },
    { id: EnColors.warm_tan, rgb: 'rgb(111,84,55)' },
    { id: EnColors.deep_terracotta, rgb: 'rgb(70,37,27)' },
    { id: EnColors.medium_brown, rgb: 'rgb(96,50,33)' },
    { id: EnColors.muted_taupe, rgb: 'rgb(62,47,42)' },
    { id: EnColors.dusty_rose, rgb: 'rgb(73,46,47)' },
    { id: EnColors.vibrant_pink, rgb: 'rgb(130,53,87)' },
    { id: EnColors.bold_crimson, rgb: 'rgb(121,44,54)' },
    { id: EnColors.rich_mauve, rgb: 'rgb(118,67,64)' },
    { id: EnColors.forest_green, rgb: 'rgb(42,66,55)' },
    { id: EnColors.deep_teal, rgb: 'rgb(42,56,52)' },
    { id: EnColors.slate_gray, rgb: 'rgb(42,46,50)' },
    { id: EnColors.olive_green, rgb: 'rgb(61,71,44)' },
    { id: EnColors.earthy_gray, rgb: 'rgb(47,53,45)' },
    { id: EnColors.golden_rod, rgb: 'rgb(87,80,33)' },
    { id: EnColors.warm_amber, rgb: 'rgb(112,81,28)' },
    { id: EnColors.bright_orange, rgb: 'rgb(114,69,28)' },
    { id: EnColors.copper_tone, rgb: 'rgb(120,64,32)' },
    { id: EnColors.burnt_sienna, rgb: 'rgb(108,43,28)' },
    { id: EnColors.dark_cherry, rgb: 'rgb(83,30,26)' },
    { id: EnColors.crimson_red, rgb: 'rgb(109,42,45)' },
    { id: EnColors.faded_brick, rgb: 'rgb(97,55,51)' },
    { id: EnColors.rosy_pink, rgb: 'rgb(113,44,55)' },
    { id: EnColors.clay_red, rgb: 'rgb(94,37,32)' },
    { id: EnColors.bright_scarlet, rgb: 'rgb(171,36,35)' },
    { id: EnColors.deep_rose, rgb: 'rgb(138,50,51)' },
    { id: EnColors.magenta_hue, rgb: 'rgb(151, 63, 99)' },
    { id: EnColors.purple_blush, rgb: 'rgb(135,46,94)' },
    { id: EnColors.plum_purple, rgb: 'rgb(120,42,94)' },
    { id: EnColors.steel_blue, rgb: 'rgb(61,89,103)' },
    { id: EnColors.emerald_green, rgb: 'rgb(67,128,95)' },
    { id: EnColors.moss_green, rgb: 'rgb(64,103,51)' },
    { id: EnColors.mustard_yellow, rgb: 'rgb(152,136,45)' },
    { id: EnColors.rusty_orange, rgb: 'rgb(159,75,35)' },
    { id: EnColors.beige_cream, rgb: 'rgb(150,135,117)' },
    { id: EnColors.taupe_brown, rgb: 'rgb(121,105,87)' },
    { id: EnColors.sage_green, rgb: 'rgb(94,107,94)' },
    { id: EnColors.charcoal_gray, rgb: 'rgb(59,52,61)' },
    { id: EnColors.vibrant_leaf, rgb: 'rgb(62,102,38)' },
    { id: EnColors.sienna_brown, rgb: 'rgb(87,43,33)' },
    { id: EnColors.dusty_peach, rgb: 'rgb(151,90,88)' },
    { id: EnColors.mossy_forest, rgb: 'rgb(69,86,60)' },
    { id: EnColors.chartreuse, rgb: 'rgb(102,105,44)' },
    { id: EnColors.brick_orange, rgb: 'rgb(149,78,35)' },
    { id: EnColors.scarlet_blood, rgb: 'rgb(143,43,33)' },
    { id: EnColors.crimson_blush, rgb: 'rgb(143,59,62)' },
    { id: EnColors.rosewood, rgb: 'rgb(155,70,71)' },
] as const

export { EnColors, COLORS }
