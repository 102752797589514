import { FC, ChangeEventHandler, HTMLInputTypeAttribute, useRef } from 'react'
import styles from './Input.module.scss'
import { SVGProps } from 'react'
import classNames from 'classnames'

interface InputProps {
    placeholder: string
    Icon: (props: SVGProps<SVGSVGElement>) => JSX.Element
    value?: string
    onChange?: ChangeEventHandler<HTMLInputElement>
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
    type?: HTMLInputTypeAttribute
    name?: string
    className?: string
    error?: any
}

export const Input: FC<InputProps> = ({
    placeholder,
    Icon,
    value = '',
    onChange,
    onKeyDown,
    type,
    name,
    className,
    error,
}) => {
    const inputRef = useRef<HTMLInputElement>(null)

    return (
        <div>
            <div
                className={classNames(styles.container, className)}
                onClick={() => inputRef.current?.focus()}
            >
                <Icon color="rgb(148, 148, 148)" className={styles.icon} />
                <input
                    value={value}
                    ref={inputRef}
                    placeholder={placeholder}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    type={type}
                    name={name}
                />
            </div>
            {error ? <div className={styles.error}>{error}</div> : null}
        </div>
    )
}
