import { useRef } from 'react'
import styles from './Helicam.module.scss'
import cn from 'classnames'
import satelliteIcon from '@/shared/assets/images/vectors/Helicam/_/satellite-icon.svg'
import arrowImg from '@/shared/assets/images/vectors/Helicam/_/arrow.svg'
import targetCrossImg from '@/shared/assets/images/vectors/Helicam/_/target-cross.svg'
import zoomBarImg from '@/shared/assets/images/vectors/Helicam/_/zoom-bar.svg'
import cameraPitchImg from '@/shared/assets/images/vectors/Helicam/_/camera-pitch.svg'
import lineImg from '@/shared/assets/images/vectors/Helicam/_/line.svg'
import cameraHeadingImg from '@/shared/assets/images/vectors/Helicam/_/camera-heading.svg'
import { useHelicam } from './model/useHelicam'
import { zoom } from './utils/zoom'
import moment from 'moment'
import { uselockBarProgress } from './utils/useLockBarProgress'

const Helicam = () => {
    const zoomRef = useRef<HTMLSpanElement | null>(null)
    const zoomArrowRef = useRef<HTMLImageElement | null>(null)
    const params = useHelicam()
    const lockBarProgress = uselockBarProgress(params.isFocus)

    return (
        <div className={styles.wrapper}>
            <div className={styles.helicopterInfo}>
                <div>
                    <div className={cn(styles.satelliteWrapper, styles.inline)}>
                        <img
                            className={cn(styles.svg, styles.satelliteIcon)}
                            src={satelliteIcon}
                        />
                    </div>
                    <p
                        className={cn(
                            styles.text,
                            styles.inline,
                            styles.cameraLabel
                        )}
                    >
                        LOS SANTOS POLICE DEPARTMENT
                    </p>
                </div>
                <div className={styles.street}>
                    <p
                        className={cn(
                            styles.text,
                            styles.streetText,
                            styles.noLeftPadding,
                            styles.street
                        )}
                    >
                        {params.street}
                    </p>
                </div>
                <div className={styles.hiContainerLeft}>
                    {/* <p className={cn(styles.text, styles.inline, styles.hiLatitude)}>.</p> */}
                    <div className={styles.relative}>
                        <div className={cn(styles.hiSubLl, styles.infoData)}>
                            <div>
                                <p
                                    className={cn(
                                        styles.text,
                                        styles.inline,
                                        styles.noLeftPadding
                                    )}
                                >
                                    SPD
                                </p>
                                <p
                                    className={cn(
                                        styles.text,
                                        styles.inline,
                                        styles.floatRight,
                                        styles.hiSpeed
                                    )}
                                >
                                    {String(params.helicopterSpeed)}
                                </p>
                            </div>
                            <p
                                className={cn(
                                    styles.text,
                                    styles.inline,
                                    styles.noLeftPadding
                                )}
                            >
                                ALT
                            </p>
                            <p
                                className={cn(
                                    styles.text,
                                    styles.inline,
                                    styles.floatRight,
                                    styles.hiAltitude
                                )}
                            >
                                {String(params.helicopterAlt)}
                            </p>
                        </div>
                        <div className={cn(styles.hiSubLr, styles.infoData)}>
                            <p className={cn(styles.text, styles.hiSpeedUnit)}>
                                KTS
                            </p>
                            <p
                                className={cn(
                                    styles.text,
                                    styles.hiFltitudeUnit
                                )}
                            >
                                FT
                            </p>
                        </div>
                    </div>
                </div>
                <div className={styles.hiContainerRight}>
                    {/* <p className={cn(styles.text, styles.inline, styles.hiLongitude)}>.</p> */}
                    <div className={styles.relative}>
                        <div className={cn(styles.hiSubRl, styles.infoData)}>
                            <p className={cn(styles.text, styles.inline)}>
                                HDG
                            </p>
                            <p
                                className={cn(
                                    styles.text,
                                    styles.inline,
                                    styles.floatRight,
                                    styles.hiHeading
                                )}
                            >
                                {String(params.helicopterHDG)}
                            </p>
                        </div>
                        <div className={cn(styles.hiSubRr, styles.infoData)}>
                            <p className={cn(styles.text, styles.inline)}>°T</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.targetInfo}>
                <div className={cn(styles.street, styles.taStreetWrapper)}>
                    <p
                        className={cn(
                            styles.text,
                            styles.streetText,
                            styles.street
                        )}
                    >
                        {params.street}
                    </p>
                </div>
                <span className={styles.taContainerWrapper}>
                    <div className={styles.taContainerLeft}>
                        {/* <p className={cn(styles.text, styles.inline, styles.taLatitude)}>.</p> */}
                        <div>
                            <div
                                className={cn(styles.taSubLl, styles.infoData)}
                            >
                                <div>
                                    {/* eslint-disable-next-line react/jsx-max-depth*/}
                                    <p
                                        className={cn(
                                            styles.text,
                                            styles.inline
                                        )}
                                    >
                                        SPD
                                    </p>
                                    {/* eslint-disable-next-line react/jsx-max-depth*/}
                                    <p
                                        className={cn(
                                            styles.text,
                                            styles.inline,
                                            styles.floatRight,
                                            styles.taSpeed
                                        )}
                                    >
                                        {String(params.targetSpeed)}
                                    </p>
                                </div>
                                <p className={cn(styles.text, styles.inline)}>
                                    ELV
                                </p>
                                <p
                                    className={cn(
                                        styles.text,
                                        styles.inline,
                                        styles.floatRight,
                                        styles.taElevation
                                    )}
                                >
                                    {String(params.targetElv)}
                                </p>
                            </div>
                            <div
                                className={cn(styles.taSubLr, styles.infoData)}
                            >
                                <p
                                    className={cn(
                                        styles.text,
                                        styles.taSpeedUnit
                                    )}
                                >
                                    MPH
                                </p>
                                <p
                                    className={cn(
                                        styles.text,
                                        styles.taElevationUnit
                                    )}
                                >
                                    FT
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.taContainerRight}>
                        {/* <p className={cn(styles.text, styles.inline, styles.taLongitude)}>.</p> */}
                        <div>
                            <div
                                className={cn(styles.taSubRl, styles.infoData)}
                            >
                                <div>
                                    {/* eslint-disable-next-line react/jsx-max-depth*/}
                                    <p
                                        className={cn(
                                            styles.text,
                                            styles.inline
                                        )}
                                    >
                                        HDG
                                    </p>
                                    {/* eslint-disable-next-line react/jsx-max-depth*/}
                                    <p
                                        className={cn(
                                            styles.text,
                                            styles.inline,
                                            styles.floatRight,
                                            styles.taHeading
                                        )}
                                    >
                                        {String(params.targetHDG)}
                                    </p>
                                </div>
                                <p className={cn(styles.text, styles.inline)}>
                                    SLT
                                </p>
                                <p
                                    className={cn(
                                        styles.text,
                                        styles.inline,
                                        styles.floatRight,
                                        styles.taDistance
                                    )}
                                >
                                    {String(params.targetSLT)}
                                </p>
                            </div>
                            <div
                                className={cn(styles.taSubRr, styles.infoData)}
                            >
                                <p className={cn(styles.text, styles.inline)}>
                                    °T
                                </p>
                                <p
                                    className={cn(
                                        styles.taDistanceUnit,
                                        styles.text,
                                        styles.inline
                                    )}
                                >
                                    M
                                </p>
                            </div>
                            <div className={styles.numberplateWrapper}>
                                <p className={cn(styles.text, styles.inline)}>
                                    LPL
                                </p>
                                <p
                                    className={cn(
                                        styles.text,
                                        styles.inline,
                                        styles.floatRight,
                                        styles.taNumberplate
                                    )}
                                >
                                    ---
                                </p>
                            </div>
                        </div>
                    </div>
                </span>
            </div>
            <div className={styles.timedateContainer}>
                <div>
                    <p className={cn(styles.text, styles.inline, styles.date)}>
                        {moment(params.date).format('l')}
                    </p>
                </div>
                <div>
                    <p className={cn(styles.text, styles.inline, styles.time)}>
                        {moment(params.date).format('LT')}
                    </p>
                </div>
            </div>
            <div className={styles.centered}>
                <span className={styles.crossWrapper}>
                    <img
                        className={cn(
                            styles.targetCross,
                            styles.svg,
                            styles.centered
                        )}
                        src={targetCrossImg}
                    />
                </span>
            </div>

            {params.focusing ? (
                <div className={styles.lockBarContainer}>
                    <p className={cn(styles.lockBarText, styles.text)}>
                        Focusing...
                    </p>
                    <div className={styles.lockBarWrapper}>
                        <div
                            className={styles.lockBarProgress}
                            style={{ width: `${lockBarProgress}%` }}
                        />
                    </div>
                </div>
            ) : null}

            <div className={styles.cameraInfo}>
                <div className={styles.cameraInfoStack}>
                    <p className={cn(styles.visionState, styles.text)}>HDEO</p>
                    <p
                        className={cn(
                            styles.lockState,
                            styles.text,
                            styles.inline
                        )}
                    >
                        LOCK
                    </p>
                    <p
                        className={cn(
                            styles.lockType,
                            styles.text,
                            styles.inline,
                            params.lock && styles.lockStateActive
                        )}
                    >
                        {params.lock || 'None'}
                    </p>
                </div>
                <p className={cn(styles.text, styles.inline)}>W</p>
                <span ref={zoomRef} style={{ position: 'relative' }}>
                    <img
                        className={cn(styles.zoomArrow, styles.svg)}
                        style={{
                            left: `${zoom(
                                params.zoom,
                                zoomRef,
                                zoomArrowRef
                            )}px`,
                        }}
                        src={arrowImg}
                        ref={zoomArrowRef}
                    />
                    <img
                        className={cn(styles.zoomBar, styles.svg)}
                        src={zoomBarImg}
                    />
                </span>
                <p className={cn(styles.text, styles.inline)}>N</p>
                <div className={styles.northInfobox}>
                    {/* <div className={styles.compassArrow}></div> */}
                </div>
            </div>
            <div className={styles.relativeInfo}>
                <p
                    className={cn(
                        styles.text,
                        styles.inline,
                        styles.cameraPitch
                    )}
                >
                    {params.headAir}°
                </p>
                <div className={styles.inline}>
                    <img
                        className={cn(styles.cameraImg, styles.svg)}
                        src={cameraPitchImg}
                    />
                    <img
                        className={cn(
                            styles.pitchLine,
                            styles.cameraLine,
                            styles.svg
                        )}
                        style={{ transform: `rotate(${params.headAir}deg)` }}
                        src={lineImg}
                    />
                </div>
                <div className={styles.inline}>
                    <img
                        className={cn(styles.cameraImg, styles.svg)}
                        src={cameraHeadingImg}
                    />
                    <img
                        className={cn(
                            styles.headingLine,
                            styles.cameraLine,
                            styles.svg
                        )}
                        style={{ transform: `rotate(${params.headCamera}deg)` }}
                        src={lineImg}
                    />
                </div>
                <p
                    className={cn(
                        styles.cameraHeading,
                        styles.text,
                        styles.inline
                    )}
                >
                    {params.headCamera}°
                </p>
            </div>
        </div>
    )
}

export default Helicam
