import { useState, useEffect } from 'react'

export const uselockBarProgress = (isFocus: boolean) => {
    const [intervalId, setIntervalId] = useState<ReturnType<
        typeof setInterval
    > | null>(null)
    const [lockBarProgress, setLockBarProgress] = useState<number>(0)
    useEffect(() => {
        if (intervalId) clearInterval(intervalId)
        if (isFocus && lockBarProgress <= 100) {
            const intervalID = setInterval(() => {
                setLockBarProgress((prev) => prev + 1)
                if (lockBarProgress >= 100 || !isFocus)
                    clearInterval(intervalID)
            }, 200)
            setIntervalId(intervalID)
        } else if (!isFocus && lockBarProgress > 0) {
            const intervalID = setInterval(() => {
                setLockBarProgress((prev) => prev - 1)
                if (lockBarProgress <= 0 || isFocus) clearInterval(intervalID)
            }, 200)
            setIntervalId(intervalID)
        }
    }, [isFocus])
    return lockBarProgress
}
