import { FC, useState } from 'react'
import { Input } from '../Input/Input'
import { Gap } from '@/shared/ui/Gap/Gap'
import { PasswordInput } from '../PasswordInput/PasswordInput'
import { EMail, Login, PromoCode } from '@/shared/icons/generated'
import { useFormik } from 'formik'
import styles from './RegistrationForm.module.scss'
import { CheckBox } from '../CheckBox/CheckBox'
import { SocialBlock } from '../SocialBlock/SocialBlock'
import { Button } from '../Button/Button'
import { toastify } from '@/shared/ui/Toast/Toast'
import { useAppDispatch } from '@/shared/hooks/redux-ts'
import { showModal } from '@/shared/store/slices/modalsSlice'
import { REGISTRATION_CONFIRMATION_MODAL } from '@/shared/constants'
import { signupSchema } from './validateSchema'
import { useAuthControllerSignUpMutation } from '@/shared/store/api/openApi'
import { changeRegistrationUUID } from '@/shared/store/slices/authSlice'

interface RegistrationFormProps {
    onSuccess: () => void
}

export const RegistrationForm: FC<RegistrationFormProps> = () => {
    const dispatch = useAppDispatch()
    const [formError, setFormError] = useState<string>('')
    const [register] = useAuthControllerSignUpMutation()
    const {
        handleChange,
        values,
        handleSubmit,
        setFieldValue,
        errors,
        touched,
    } = useFormik({
        initialValues: {
            username: '',
            password: '',
            passwordRepeat: '',
            email: '',
            promocode: '',
            newsletter: true,
            acceptRules: false,
        },
        validationSchema: signupSchema,
        onSubmit: async ({
            email,
            newsletter,
            password,
            username,
            promocode,
            acceptRules,
        }) => {
            if (!acceptRules) {
                toastify.info('Необходимо принять правила сервера')
                return
            }

            try {
                const { data, error } = await register({
                    authSignupRequest: {
                        email,
                        newsletter,
                        password,
                        username,
                        promocode,
                    },
                })
                if (data) {
                    dispatch(changeRegistrationUUID(data.verifyUuid))
                    dispatch(
                        showModal({ slug: REGISTRATION_CONFIRMATION_MODAL })
                    )
                } else {
                    //@ts-expect-error: Не типизированный ответ ошибки
                    setFormError(error?.data?.error || 'Непредвиденная ошибка')
                }
            } catch (e) {
                console.error('Error during registration', e)
            }
        },
    })

    return (
        <form className={styles.container} onSubmit={handleSubmit}>
            <SocialBlock type="registration" />
            <Gap size={16} />
            <Input
                Icon={Login}
                placeholder="Логин"
                onChange={handleChange}
                value={values.username}
                name="username"
                error={touched.username ? errors.username : null}
            />
            <Gap size={10} />
            <PasswordInput
                onChange={handleChange}
                value={values.password}
                name="password"
                error={touched.password ? errors.password : null}
            />
            <Gap size={10} />
            <PasswordInput
                onChange={handleChange}
                value={values.passwordRepeat}
                name="passwordRepeat"
                placeholder="Подтвердите пароль"
                error={touched.passwordRepeat ? errors.passwordRepeat : null}
            />
            <Gap size={10} />
            <Input
                Icon={EMail}
                placeholder="E-mail"
                onChange={handleChange}
                value={values.email}
                name="email"
                error={touched.email ? errors.email : null}
            />
            <Gap size={10} />
            <Input
                error={errors.promocode}
                Icon={PromoCode}
                placeholder="Промокод"
                onChange={handleChange}
                value={values.promocode}
                name="promocode"
            />
            <Gap size={10} />
            <CheckBox
                label="Согласен принимать информационные рассылки"
                checked={values.newsletter}
                onChange={(checked) => setFieldValue('newsletter', checked)}
            />
            <Gap size={10} />
            <CheckBox
                label="Я ознакомился с правилами сервера и принимаю их"
                checked={values.acceptRules}
                onChange={(checked) => setFieldValue('acceptRules', checked)}
            />
            {formError ? (
                <p className={styles.formErrorMessage}>{formError}</p>
            ) : null}
            <Gap size={20} />
            <div className={styles.buttonsBlock}>
                <Button
                    value="Правила сервера"
                    className={styles.button}
                    full
                    variant="secondary"
                />
                <Button
                    value="Создать аккаунт"
                    type="submit"
                    className={styles.button}
                    full
                />
            </div>
        </form>
    )
}
