import { useEffect, useState } from 'react'
import { RpcResponse } from '@/shared/types/common'
import rpc from 'altv-rpc'
import { Props } from '../AutoSensors.typings'
import { mapParamsToProps } from '@/entities/AutoSensors/mappers/mapParamsToProps'

export interface AutoSensorsParams {
    speed: number
    maxSpeed: number
    maxFuel: number
    fuel: number
    rpm: number
    maxRpm: number
    isEngineOn: boolean
    isLightsOn: boolean
    blinkerID: number
    isLocked: boolean
    gear: string
    isArsOn: boolean
    isDoorOpen: boolean
    isAbsOn: boolean
    isEmergencyOn: boolean
    isEspOn: boolean
    hasSportMode: boolean
    isSportMode: boolean
    hasLaunchControl: boolean
    isLaunchControl: boolean
}

const initialData = {
    speed: 0,
    maxSpeed: 80,
    maxFuel: 10,
    fuel: 0,
    rpm: 0,
    maxRpm: 5000,
    transmission: '1',
    turnSignals: {
        isLeftOn: false,
        isRightOn: false,
    },
    modes: {
        hasSportMode: false,
        isSportMode: null,
        hasLaunchControl: false,
        isLaunchControl: null,
    },
    sensors: {
        isArsOn: false,
        isAbsOn: false,
        isEspOn: false,
        isEngineOn: false,
        isEmergencyOn: false,
        isHeadlightsOn: false,
        isDoorOpen: false,
    },
}

interface AutoSensorsResponse {
    show?: boolean
    params?: AutoSensorsParams
}

export const useAutoSensorsData = () => {
    const [resParams, setResParams] = useState<Partial<AutoSensorsParams>>({})
    const [data, setData] = useState<Props>(initialData)
    const [show, setShow] = useState<boolean>(false)

    const callback = ({
        data: { params, show },
    }: RpcResponse<AutoSensorsResponse>) => {
        if (show !== undefined) {
            setShow(show)
        }
        if (params) {
            setResParams((prev) => ({ ...prev, ...params }))
            setData(mapParamsToProps(resParams))
        }
    }

    const event = 'vehicles:showIndicators'

    useEffect(() => {
        rpc.on(event, callback)

        return () => {
            rpc.off(event, callback)
        }
    })

    return { data, show: show && data }
}
