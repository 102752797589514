import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IInitialState {
    registrationUUID?: string
    recoveryUUID?: string
}

const initialState: IInitialState = {}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        changeRegistrationUUID(state, { payload }: PayloadAction<string>) {
            state.registrationUUID = payload
        },
        changeRecoveryUUID(state, { payload }: PayloadAction<string>) {
            state.recoveryUUID = payload
        },
    },
})

export const { changeRecoveryUUID, changeRegistrationUUID } = authSlice.actions
export default authSlice.reducer
