import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IInitialState {
    eyebrowHeight: number
    eyebrowWidth: number
}
const initialState: IInitialState = {
    eyebrowHeight: 0.5,
    eyebrowWidth: 0.5,
}

const personalizationEyebrowSlice = createSlice({
    name: 'personalizationEyebrow',
    initialState,
    reducers: {
        changeEyebrowHeight(state, { payload }: PayloadAction<number>) {
            state.eyebrowHeight = payload
        },
        changeEyebrowWidth(state, { payload }: PayloadAction<number>) {
            state.eyebrowWidth = payload
        },
    },
})

export const { changeEyebrowHeight, changeEyebrowWidth } =
    personalizationEyebrowSlice.actions
export default personalizationEyebrowSlice.reducer
