import { EnGender } from '@/shared/store/slices/personalization/baseSlice'
import { names } from './getRandomFullName.config'
import { nationalities } from '@/features/PlayerInfoForm/PlayerInfoForm.config'

export const getRandomFullName = (gender: EnGender, nationality: string) => {
    const getRandom = (arr: string[]) => {
        return arr[Math.floor(Math.random() * arr.length)]
    }

    const nationalityObj = nationalities.find((item) => {
        if (gender === EnGender.male) {
            return nationality === item.titles.male
        } else {
            return nationality === item.titles.female
        }
    })

    const nationalitySign: string = nationalityObj ? nationalityObj.code : ''
    const firstName = getRandom(
        names[nationalitySign][gender === EnGender.male ? 'male' : 'female']
            .name
    )
    const lastName = getRandom(
        names[nationalitySign][gender === EnGender.male ? 'male' : 'female']
            .lastName
    )

    return { firstName, lastName }
}
