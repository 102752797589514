import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { baseApi } from './api/baseApi'
import chatHistoryReducer from './slices/chatHistorySlice'
import modalsReducer from './slices/modalsSlice'
import friendsSliceReducer from './slices/friendsSlice'
import authSlicePersistReducer from './slices/authSlice.persist'
import authSlice from './slices/authSlice'
import { personalizationReducers } from './slices/personalization'

import { PERSIST, persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

// PERSIST CONFIG
const persistConfig = {
    key: 'crp:storage',
    storage: storage,
    whitelist: ['authPersist'],
}

// COMBINED REDUCERS

// ALL REDUCERS
const reducers = combineReducers({
    [baseApi.reducerPath]: baseApi.reducer,
    friends: friendsSliceReducer,
    chatHistory: chatHistoryReducer,
    modals: modalsReducer,
    authPersist: authSlicePersistReducer,
    auth: authSlice,
    personalization: personalizationReducers,
})

const persistedReducers = persistReducer(persistConfig, reducers)

const store = configureStore({
    reducer: persistedReducers,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [PERSIST],
            },
        }).concat(baseApi.middleware),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

const persistor = persistStore(store)

export { store, persistor }
setupListeners(store.dispatch)
