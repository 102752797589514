import { FC } from 'react'
import styles from './TabsPanel.module.scss'
import classNames from 'classnames'

interface Tab {
    title: string
    value: any
}

interface TabsPanelProps {
    tabs: Tab[]
    onChange: (value: Tab['value']) => void
    value: Tab['value']
}

export const TabsPanel: FC<TabsPanelProps> = ({
    tabs,
    onChange,
    value: activeValue,
}) => {
    return (
        <div className={styles.container}>
            {tabs.map(({ title, value }) => {
                return (
                    <div
                        className={classNames(
                            styles.tab,
                            value === activeValue && styles.activeTab
                        )}
                        key={value}
                        onClick={() => onChange(value)}
                    >
                        {title}
                    </div>
                )
            })}
        </div>
    )
}
