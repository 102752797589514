interface GetWheelCoordsArgs {
    totalCount?: number
    radius: number
    bias?: number
    count: number
}

interface Item {
    x: number
    y: number
}

type GetWheelCoords = (args: GetWheelCoordsArgs) => Item[]

export const getWheelCoords: GetWheelCoords = ({
    totalCount = 15,
    radius,
    bias = 15,
    count,
}) => {
    const angle = (2 * Math.PI) / totalCount
    const x0 = radius
    const y0 = radius

    return Array.from({ length: count }, (_, i) => {
        return {
            x: x0 + radius * Math.cos((i + 0.5 - count / 2) * angle) + bias,
            y: y0 + radius * Math.sin((i + 0.5 - count / 2) * angle) + bias,
        }
    })
}
