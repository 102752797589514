import { Dating } from '@/shared/icons/Dating'
import { Cuff } from '@/shared/icons/Cuff'

import { Kiss } from '@/shared/icons/Kiss'
import { Hugs } from '@/shared/icons/Hugs'
import { HandRope } from '@/shared/icons/HandRope'
import { Gag } from '@/shared/icons/Gag'
import {
    Canister,
    CarKey,
    Case,
    Crime,
    Defibrillator,
    Dimensions,
    FirstAidKit,
    FixCar,
    FixCar2,
    GiveFive,
    GiveMoney,
    GloveBox,
    Heart,
    Hood,
    IdCard,
    OpenDoors,
    OpenWindow,
    Pickup,
    PickUpWounded,
    PushCar,
    PushInCar,
    Rape,
    Search,
    SeatBelt,
    Sex,
    Slap,
    Syringe,
    Trade,
    Trunk,
} from '@/shared/icons/generated'
import { Threat } from '@/shared/icons/Threat'
import { DriverLicense } from '@/shared/icons/DriverLicense'
import { MedInsurance } from '@/shared/icons/MedInsurance'
import { WeaponLicense } from '@/shared/icons/WeaponLicense'
import { BusinessLicense } from '@/shared/icons/BusinessLicense'
import { HeadBag } from '@/shared/icons/HeadBag'
import { HunterLicense } from '@/shared/icons/HunterLicense'
import { FishingLicense } from '@/shared/icons/FishingLicense'
import { CargoLicense } from '@/shared/icons/CargoLicense'
import { GetOutOfCar } from '@/shared/icons/GetOurOfCar'
import { InteractionsParams, Passenger } from './InteractionWheel.typings'
import { Engine } from '@/shared/icons/Engine'
import { UploadingUnloading } from '@/shared/icons/uploadingUnloading'
import { Upload } from '@/shared/icons/Upload'
import { Unload } from '@/shared/icons/Unload'
import { Trading } from '@/shared/icons/Trading'
import { Sleeping } from '@/shared/icons/Sleeping'
import { Escort } from '@/shared/icons/Escort'

export const initialInteractionsParams = {
    name: '',
    hasCuff: false,
    hasRope: false,
    hasBag: false,
}

export const characterInteractions = {
    withPlayer: ({ hasRope, hasBag, hasCuff }: InteractionsParams) => [
        {
            Icon: Trade,
            key: 'trade',
            title: 'Торговля',
        },
        {
            Icon: Pickup,
            key: null,
            title: 'Взять на руки',
            items: [
                {
                    Icon: Pickup,
                    key: 'pickup1',
                    title: 'Взять на руки 1',
                },
                {
                    Icon: Pickup,
                    key: 'pickup2',
                    title: 'Взять на руки 2',
                },
                {
                    Icon: Pickup,
                    key: 'pickup3',
                    title: 'Взять на руки 3',
                },
            ],
        },
        {
            Icon: Case,
            key: null,
            title: 'Фракционные',
            items: [
                {
                    Icon: Cuff,
                    key: hasCuff ? 'delCuff' : 'addCuff',
                    title: hasCuff ? 'Снять наручники' : 'Надеть наручники',
                },
            ],
        },
        {
            Icon: Crime,
            key: null,
            title: 'Криминальное',
            items: [
                {
                    Icon: PushInCar,
                    key: 'pushInCar',
                    title: 'Запихнуть в автомобиль',
                },
                {
                    Icon: Rape,
                    key: 'rape',
                    title: 'Изнасиловать',
                },
                {
                    Icon: FixCar2,
                    key: 'pushInTrunk',
                    title: 'Запихнуть в багажник',
                },
                {
                    Icon: Threat,
                    key: 'threat',
                    title: 'Угрожать',
                },
                {
                    Icon: HeadBag,
                    key: hasBag ? 'delBag' : 'addBag',
                    title: hasBag ? 'Снять мешок' : 'Надеть мешок',
                },
                {
                    Icon: Gag,
                    key: 'addGag',
                    title: 'Засунуть кляп в рот',
                },
                {
                    Icon: HandRope,
                    key: hasRope ? 'delRope' : 'addRope',
                    title: hasRope ? 'Развязать руки' : 'Связать руки',
                },
                {
                    Icon: Escort,
                    key: 'escort',
                    title: 'Конвоировать',
                },
            ],
        },
        {
            Icon: Dating,
            key: 'dating',
            title: 'Поздороваться',
        },
        {
            Icon: IdCard,
            key: null,
            title: 'Документы',
            items: [
                {
                    Icon: IdCard,
                    key: 'idCard',
                    title: 'Показать ID карту',
                },
                {
                    Icon: DriverLicense,
                    key: 'driverLicense',
                    title: 'Показать водительские права',
                },
                {
                    Icon: MedInsurance,
                    key: 'medInsurance',
                    title: 'Показать мед страховку',
                },
                {
                    Icon: WeaponLicense,
                    key: 'weaponLicense',
                    title: 'Показать лицензию на оружие',
                },
                {
                    Icon: BusinessLicense,
                    key: 'businessLicense',
                    title: 'Показать лицензию на осуществление предпринимательской деятельности',
                },
                {
                    Icon: HunterLicense,
                    key: 'hunterLicense',
                    title: 'Показать лицензию охотника',
                },
                {
                    Icon: FishingLicense,
                    key: 'fishingLicense',
                    title: 'Показать лицензию на рыболовство',
                },
                {
                    Icon: CargoLicense,
                    key: 'cargoLicense',
                    title: 'Показать лицензию на перевозку опасных грузов',
                },
            ],
        },
        {
            Icon: GiveMoney,
            key: 'giveMoney',
            title: 'Передать деньги',
        },
        {
            Icon: Heart,
            key: null,
            title: 'Эмоциональное',
            items: [
                {
                    Icon: Kiss,
                    key: 'kiss',
                    title: 'Поцеловать',
                },
                {
                    Icon: Hugs,
                    key: 'hug',
                    title: 'Обнять',
                },
                {
                    Icon: GiveFive,
                    key: 'giveFive',
                    title: 'Дать пять',
                },
                {
                    Icon: Slap,
                    key: 'slap',
                    title: 'Дать пощечину',
                },
                {
                    Icon: Sex,
                    key: 'sex',
                    title: 'Предложить заняться сексом',
                },
            ],
        },
    ],
    inVehicle: ({ passenger }: { passenger: Passenger[] }) => [
        {
            Icon: GetOutOfCar,
            key: 'getOutOfCar',
            title: 'Высадить из машины',
            items: [...passenger],
        },
        {
            Icon: OpenDoors,
            key: null,
            title: 'Открыть двери',
            items: [
                {
                    Icon: OpenWindow,
                    key: 'openWindow',
                    title: 'Открыть окно',
                },
                {
                    Icon: Hood,
                    key: 'openHood',
                    title: 'Открыть капот',
                },
            ],
        },
        {
            Icon: GloveBox,
            key: 'openGloveBox',
            title: 'Открыть бардачок',
        },
        {
            Icon: Dimensions,
            key: 'turnOnLight',
            title: 'Включить свет',
        },
        {
            Icon: Trunk,
            key: 'openTrunk',
            title: 'Открыть багажник',
        },
        {
            Icon: SeatBelt,
            key: 'seatBelt',
            title: 'Пристегнуть ремень безопасности',
        },
        {
            Icon: CarKey,
            key: 'carKey',
            title: 'Закрыть машину',
        },
        {
            Icon: Engine,
            key: 'startEngine',
            title: 'Завести машину',
        },
        {
            Icon: Trading,
            key: 'trading',
            title: 'Торговля',
        },
        {
            Icon: Sleeping,
            key: 'sleeping',
            title: 'Сон',
        },
    ],
    withVehicle: () => [
        {
            Icon: Trunk,
            key: 'openTrunk',
            title: 'Открыть багажник',
        },
        {
            Icon: UploadingUnloading,
            key: null,
            title: 'Загрузка/Разгрузка',
            items: [
                {
                    Icon: Upload,
                    key: 'Upload',
                    title: 'Загрузка',
                },
                {
                    Icon: Unload,
                    key: 'Unload',
                    title: 'Разгрузка',
                },
            ],
        },
        {
            Icon: Hood,
            key: 'openHood',
            title: 'Открыть капот',
        },
        {
            Icon: PushCar,
            key: 'pushCar',
            title: 'Толкать машину',
        },
        {
            Icon: FixCar,
            key: 'fixCar',
            title: 'Чинить машину',
        },
        {
            Icon: Canister,
            key: 'addFuel',
            title: 'Залить топливо',
        },
        {
            Icon: FixCar2,
            key: 'climbInTrunk',
            title: 'Залезть в багажник',
        },
    ],
    withWounded: () => [
        {
            Icon: PickUpWounded,
            key: 'pickupInjured',
            title: 'Взять на руки',
        },
        {
            Icon: Defibrillator,
            key: 'reanimateDefibrillator',
            title: 'Реанимировать дефибрилятором',
        },
        {
            Icon: Syringe,
            key: 'reanimateEpinephrine',
            title: 'Реанимировать с помощью эпинефрина',
        },
        {
            Icon: FirstAidKit,
            key: 'reanimateFirstAidKit',
            title: 'Реанимировать с помощью аптечки',
        },
        {
            Icon: Search,
            key: 'search',
            title: 'Обыскать',
        },
    ],
    withDead: () => [
        {
            Icon: PickUpWounded,
            key: 'pickupInjured',
            title: 'Взять на руки',
        },
        {
            Icon: Defibrillator,
            key: 'reanimateDefibrillator',
            title: 'Реанимировать дефибрилятором',
        },
        {
            Icon: Syringe,
            key: 'reanimateEpinephrine',
            title: 'Реанимировать с помощью эпинефрина',
        },
        {
            Icon: FirstAidKit,
            key: 'reanimateFirstAidKit',
            title: 'Реанимировать с помощью аптечки',
        },
        {
            Icon: Search,
            key: 'search',
            title: 'Обыскать',
        },
    ],
}
