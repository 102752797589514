import { FC, SVGProps } from 'react'

interface Props extends SVGProps<SVGSVGElement> {}
const СheckMark: FC<Props> = ({ color, ...props }) => {
    return (
        <svg width="20" height="15" viewBox="0 0 20 15" fill="none" {...props}>
            <path
                d="M19.7071 0.29291C19.3166 -0.0976368 18.6835 -0.0976368 18.2929 0.29291L6.31228 12.2737L1.70713 7.6685C1.31662 7.27795 0.683496 7.27799 0.29291 7.6685C-0.0976367 8.05901 -0.0976367 8.69213 0.29291 9.08268L5.60518 14.3949C5.99557 14.7854 6.62916 14.7851 7.01939 14.3949L19.7071 1.70713C20.0977 1.31662 20.0976 0.683457 19.7071 0.29291Z"
                fill={color}
            />
        </svg>
    )
}

export { СheckMark }
