import { useState, useEffect } from 'react'
import rpc from 'altv-rpc'
import { Params } from '../Helicam.typings'

export const useHelicam = () => {
    const date = new Date()
    const [params, setParams] = useState<Params>({
        lock: 'None',
        zoom: 0,
        headAir: 0,
        headCamera: 0,
        date: date,
        helicopterSpeed: 0,
        helicopterAlt: 0,
        helicopterHDG: 0,
        targetSpeed: 0,
        targetElv: 0,
        targetHDG: 0,
        targetSLT: 0,
        targetStreet: 'None',
        street: 'None',
        focusing: false,
        isFocus: false,
    })

    const event = 'helicam:params'

    const callback = ({ data }: { data: Params }) => {
        setParams(data)
    }

    useEffect(() => {
        rpc.on(event, callback)
        return () => rpc.off(event, callback)
    }, [])

    return params
}
