import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IInitialState {
    cheekboneHeight: number
    cheekboneWidth: number
}
const initialState: IInitialState = {
    cheekboneHeight: 0.5,
    cheekboneWidth: 0.5,
}

const personalizationCheekbonesSlice = createSlice({
    name: 'personalizationCheekbones',
    initialState,
    reducers: {
        changeCheekbonesWidth(state, { payload }: PayloadAction<number>) {
            state.cheekboneWidth = payload
        },
        changeCheekbonesHeight(state, { payload }: PayloadAction<number>) {
            state.cheekboneHeight = payload
        },
    },
})

export const { changeCheekbonesWidth, changeCheekbonesHeight } =
    personalizationCheekbonesSlice.actions
export default personalizationCheekbonesSlice.reducer
