import { useState, useRef } from 'react'
import greenCrossPic from '@/shared/assets/images/vectors/Store/PaymentSelect/greenCrossPic.svg'
import classNames from 'classnames'
import { Props } from './paymentSelect.typings'

import supermarketPaymentSelect from './SupermarketPaymentSelect.module.scss'
import buildingStorePaymentSelect from './BuildingStorePaymentSelect.module.scss'
import clothesStorePaymentSelect from './ClothesStorePaymentSelect.module.scss'
import electronicsStorePaymentSelect from './ElectronicsStorePaymentSelect.module.scss'
import furnitureStorePaymentSelect from './FurnitureStorePaymentSelect.module.scss'
import jewelryStorePaymentSelect from './JewelryStorePaymentSelect.module.scss'
import pharmacyPaymentSelect from './PharmacyPaymentSelect.module.scss'
//import TattooShopPaymentSelect from './TattooShopPaymentSelect.module.scss'

const stylesObject: Record<string, { readonly [key: string]: string }> = {
    //PaymentSelect styles
    supermarket: supermarketPaymentSelect,
    pharmacy: pharmacyPaymentSelect,
    electronicsStore: electronicsStorePaymentSelect,
    furnitureStore: furnitureStorePaymentSelect,
    jewelryStore: jewelryStorePaymentSelect,
    buildingStore: buildingStorePaymentSelect,
    clothesStore: clothesStorePaymentSelect,
    tattooShop: furnitureStorePaymentSelect,
}

export enum Customer {
    toMyself,
    toBusiness,
}

const PaymentSelect = (props: Props) => {
    const mainStyles = stylesObject[props.storeType]

    const [customer, setCustomer] = useState<Customer>(Customer.toBusiness)
    const [business, setBusiness] = useState<string>('Выберите бизнес')
    const [openSelect, setOpenSelect] = useState<boolean>(false)
    const [businessId, setBusinessId] = useState<string>(props.business[0].id)
    const businessValue = business

    const selectCustomer = (item: Customer) => {
        setCustomer(item)
    }

    const openSortSelect = () => {
        setOpenSelect((prevOpenSelect) => !prevOpenSelect)
    }

    const changeBusinessSelect = (id: string, title: string) => {
        setBusiness(title)
        setOpenSelect(false)
        setBusinessId(id)
    }
    const cash = useRef<HTMLInputElement>(null)

    const buyGoods = () => {
        let payWithCash = false
        if (cash?.current) {
            payWithCash = cash.current.checked
        }
        props.onSelect(customer, businessId, payWithCash)
    }

    return (
        <div
            className={classNames(
                mainStyles.mainShape,
                customer == Customer.toMyself && mainStyles.mainShapeMysef
            )}
        >
            <img
                src={greenCrossPic}
                className={mainStyles.exitCross}
                alt="exit"
                onClick={() => props.onClose(false)}
            />
            <div className={mainStyles.header}>
                Завершите покупку выбрав следующее:
            </div>
            <div className={mainStyles.mainBlock}>
                <div>
                    <div className={mainStyles.title}>Покупка:</div>
                    <div className={mainStyles.customerSelector}>
                        <div
                            className={classNames(
                                mainStyles.customerSelectorBlock,
                                customer == Customer.toMyself &&
                                    mainStyles.customerSelectorBlockSelected
                            )}
                            onClick={() => selectCustomer(Customer.toMyself)}
                        >
                            Себе
                        </div>
                        <div
                            className={classNames(
                                mainStyles.customerSelectorBlock,
                                customer == Customer.toBusiness &&
                                    mainStyles.customerSelectorBlockSelected
                            )}
                            onClick={() => selectCustomer(Customer.toBusiness)}
                        >
                            Бизнесу
                        </div>
                    </div>
                </div>
                {customer == Customer.toBusiness ? (
                    <div>
                        <div className={mainStyles.title}>Бизнес:</div>
                        <div className={mainStyles.selectWrapper}>
                            <div
                                className={mainStyles.select}
                                onClick={openSortSelect}
                            >
                                {businessValue}
                            </div>
                            {openSelect ? (
                                <div className={mainStyles.openSelect}>
                                    {props.business.map((option) => (
                                        <div
                                            key={option.id}
                                            className={classNames(
                                                mainStyles.selectOption,
                                                businessId == option.id &&
                                                    mainStyles.selectOptionSelected
                                            )}
                                            onClick={() =>
                                                changeBusinessSelect(
                                                    option.id,
                                                    option.title
                                                )
                                            }
                                        >
                                            {option.title}
                                        </div>
                                    ))}
                                </div>
                            ) : null}
                        </div>
                    </div>
                ) : null}
                {customer == Customer.toMyself ? (
                    <div>
                        <div className={mainStyles.title}>Оплата:</div>
                        <input
                            type="radio"
                            name="payment"
                            id="cash"
                            className={mainStyles.radio}
                            ref={cash}
                        />
                        <label htmlFor="cash" className={mainStyles.label}>
                            Наличными
                        </label>
                        <input
                            type="radio"
                            name="payment"
                            id="card"
                            defaultChecked
                            className={mainStyles.radio}
                        />
                        <label htmlFor="card" className={mainStyles.label}>
                            Картой
                        </label>
                    </div>
                ) : null}
                {customer == Customer.toBusiness ? (
                    <div>
                        <div className={mainStyles.title}>Оплата:</div>
                        <input
                            type="radio"
                            name="payment"
                            id="card"
                            defaultChecked
                            className={mainStyles.radio}
                        />
                        <label htmlFor="card" className={mainStyles.label}>
                            Картой
                        </label>
                        <div className={mainStyles.cardBalance}>Баланс:</div>
                        <div className={mainStyles.cardBalanceValue}>
                            {props.playerBalance.toLocaleString()} $
                        </div>
                    </div>
                ) : null}
                <div>
                    <div className={mainStyles.title}>К оплате:</div>
                    <div className={mainStyles.forPayment}>
                        {props.total?.toLocaleString()} $
                    </div>
                </div>
            </div>
            <div className={mainStyles.paymmentButton} onClick={buyGoods}>
                Купить
            </div>
        </div>
    )
}

export default PaymentSelect
