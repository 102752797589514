import { Key, ReactElement, ReactNode } from 'react'
import cls from './index.module.scss'
import { TClassName } from '@/shared/types/shared'
import { CharacterPropertiesWrapper } from '@/widgets/CharacterPropertiesWrapper'
import classNames from 'classnames'

type TKeyProp = Key | null | undefined
type TCharacterVariantItem<Id extends TKeyProp> = {
    id: Id
    content: ReactNode
    onSelect: () => void
    isActive?: boolean
}

interface Props<VariantsId extends TKeyProp, ColorsId extends TKeyProp>
    extends TClassName {
    variants: {
        activeCls?: string
        title: string
        items: TCharacterVariantItem<VariantsId>[]
    }
    colors: {
        title: string
        items: TCharacterVariantItem<ColorsId>[]
        activeCls?: string
    }
    additionalColors?: {
        title: string
        activeCls?: string
        items: TCharacterVariantItem<ColorsId>[]
    }
}
const CharacterVariantsWrapper = <
    VariantsId extends TKeyProp,
    ColorsId extends TKeyProp
>({
    className,
    colors,
    variants,
    additionalColors,
}: Props<VariantsId, ColorsId>): ReactElement => {
    return (
        <div className={classNames(cls.wrapper, className)}>
            <CharacterPropertiesWrapper className={cls.variants_wrapper}>
                <h2 className={cls.title}>{variants.title}</h2>
                <ul className={classNames(cls.group, 'has-main-scrollbar-v')}>
                    {variants.items.map(
                        ({ content, id, onSelect, isActive }) => (
                            <li
                                className={classNames(
                                    cls.item,
                                    variants.activeCls
                                        ? {
                                              [variants.activeCls]: isActive,
                                          }
                                        : undefined
                                )}
                                key={id}
                                onClick={onSelect}
                            >
                                {content}
                            </li>
                        )
                    )}
                </ul>
            </CharacterPropertiesWrapper>
            <div className={cls.colors_wrapper}>
                <CharacterPropertiesWrapper className={cls.main_color}>
                    <h2 className={cls.title}>{colors.title}</h2>
                    <ul
                        className={classNames(
                            cls.group,
                            'has-main-scrollbar-v'
                        )}
                    >
                        {colors.items.map(
                            ({ content, id, onSelect, isActive }) => (
                                <li
                                    className={classNames(
                                        cls.item,
                                        colors.activeCls
                                            ? {
                                                  [colors.activeCls]: isActive,
                                              }
                                            : undefined
                                    )}
                                    key={id}
                                    onClick={onSelect}
                                >
                                    {content}
                                </li>
                            )
                        )}
                    </ul>
                </CharacterPropertiesWrapper>
                {!!additionalColors ? (
                    <CharacterPropertiesWrapper
                        className={cls.additional_colors}
                    >
                        <h2 className={cls.title}>{additionalColors.title}</h2>
                        <ul
                            className={classNames(
                                cls.group,
                                'has-main-scrollbar-v'
                            )}
                        >
                            {additionalColors.items.map(
                                ({ content, id, onSelect, isActive }) => (
                                    <li
                                        className={classNames(
                                            cls.item,
                                            additionalColors.activeCls
                                                ? {
                                                      [additionalColors.activeCls]:
                                                          isActive,
                                                  }
                                                : undefined
                                        )}
                                        key={id}
                                        onClick={onSelect}
                                    >
                                        {content}
                                    </li>
                                )
                            )}
                        </ul>
                    </CharacterPropertiesWrapper>
                ) : null}
            </div>
        </div>
    )
}

export { CharacterVariantsWrapper, type TCharacterVariantItem }
