import { FC, InputHTMLAttributes } from 'react'
import cls from './index.module.scss'
import VerificationInput, {
    VerificationInputProps,
} from 'react-verification-input'
import classNames from 'classnames'

interface IVerificationInputActions {
    actions?: Pick<VerificationInputProps, 'onComplete' | 'onChange'>
}

interface Props
    extends InputHTMLAttributes<HTMLInputElement>,
        IVerificationInputActions {
    length?: number
}
const CodeVerificationInput: FC<Props> = ({
    actions,
    length,
    className,
    ...inpProps
}) => {
    return (
        <VerificationInput
            validChars="0-9"
            placeholder=""
            length={length || 6}
            inputProps={inpProps}
            classNames={{
                character: cls.item,
                characterFilled: cls.item_filled,
                characterInactive: cls.item_inactive,
                characterSelected: cls.item_selected,
                container: classNames(cls.wrapper, className),
            }}
            {...actions}
        />
    )
}

export { CodeVerificationInput }
