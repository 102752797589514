import { combineReducers } from '@reduxjs/toolkit'
import beard from './beardSlice'
import eyebrow from './eyebrowSlice'
import hair from './hairSlice'
import tors from './torsSlice'

export const hairReducers = combineReducers({
    beard,
    eyebrow,
    hair,
    tors,
})
