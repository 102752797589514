import { FC, memo } from 'react'
import styles from './AttributeWheel.module.scss'
import { CircleItem } from '@/shared/ui/CharacterWheel/components/CircleItem/CircleItem'
import { MainCircleSvg } from '@/shared/ui/CharacterWheel/components/MainCircleSvg/MainCircleSvg'
import { getWheelCoords } from '@/shared/ui/CharacterWheel/components/utils/getWheelCoords'
import { TClassName } from '@/shared/types/shared'
import classNames from 'classnames'
import { useAppDispatch } from '@/shared/hooks/redux-ts'
import {
    changeAttribute,
    EnActiveAttribute,
} from '@/shared/store/slices/personalization/viewSlice'
import { STRUCTURE } from '../../constants/structure'
import { pxToRem } from '@/shared/lib/pxToRem'

interface Props extends TClassName {
    activeAttribute: EnActiveAttribute
}

export const AttributeWheel: FC<Props> = memo(
    ({ className, activeAttribute }) => {
        const dispatch = useAppDispatch()

        const arr = getWheelCoords({
            count: STRUCTURE.length,
            radius: 385,
        })

        return (
            <div className={classNames(styles.container, className)}>
                <MainCircleSvg className={styles.image} />
                <div className={styles.items}>
                    {arr.map(({ x, y }, i) => {
                        const { slug, icon } = STRUCTURE[i][0]
                        // console.log({ slug, i, icon })

                        return (
                            <div
                                key={`${slug} ${x} ${y}`}
                                style={{
                                    top: `${pxToRem(y)}rem`,
                                    left: `${pxToRem(x)}rem`,
                                }}
                                className={styles.item}
                            >
                                <CircleItem
                                    Icon={icon}
                                    isActive={slug === activeAttribute}
                                    onClick={() =>
                                        dispatch(changeAttribute(slug))
                                    }
                                />
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
)
AttributeWheel.displayName = 'AttributeWheel'
