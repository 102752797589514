import { useEffect, useState } from 'react'
import styles from './HudTickets.module.scss'
import rpc from 'altv-rpc'
import { blocks, Tickets } from './HudTickets.config'
import cn from 'classnames'

const HudTickets = () => {
    const [isVisible, setVisible] = useState<boolean>(false)

    const [value, setValue] = useState<{
        reports: number
        moderation: number
        experts: number
    }>({
        reports: 3,
        moderation: 5,
        experts: 4,
    })

    const event = 'hud:tickets'

    // rpc-type-fix
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const callback = ({ data }: any) => {
        setValue({ ...value, ...data.params })
        setVisible(data.show)
    }

    useEffect(() => {
        rpc.on(event, callback)

        return () => {
            rpc.off(event, callback)
        }
    }, [])

    return (
        <div className={cn(styles.wrapper, !isVisible && styles.wrapperHide)}>
            {blocks.map((item) => {
                const valueKey = Tickets[item.key]
                return (
                    <div key={item.key} className={styles.block}>
                        <div className={styles.title}>{item.title}</div>
                        <div className={styles.value}>
                            {value[valueKey as keyof typeof value]}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default HudTickets
