import styles from './HudHeader.module.scss'
import pictureImg from '@/shared/assets/images/illustrations/Hud/Header/picture.png'

export interface HudHeaderProps {
    id: string
}

export const HudHeader = (props: HudHeaderProps) => {
    return (
        <div className={styles.container}>
            <div className={styles.picture}>
                <img className={styles.pictureImg} src={pictureImg} />
            </div>
            <div className={styles.info}>
                <div className={styles.secondTitle}>
                    <span className={styles.idText}>#</span>
                    <span className={styles.text}>{props.id}</span>
                </div>
            </div>
        </div>
    )
}
