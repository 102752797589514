import { FC } from 'react'
import { TClassName } from '@/shared/types/shared'
import classNames from 'classnames'
import cls from './index.module.scss'
import { Sliders } from './Sliders'
import { Parameters } from './Parameters'

interface Props extends TClassName {}
export const CharacterGeneticContent: FC<Props> = ({ className }) => {
    return (
        <div className={classNames(cls.wrapper, className)}>
            <Sliders />
            <Parameters />
        </div>
    )
}
