import styles from './index.module.scss'
import classNames from 'classnames'
import { Routes, Route } from 'react-router-dom'
import ATM from '@/widgets/ATM/ATM'
import Store from '@/widgets/Store/Store'
import { previewStore } from '../widgets/Store/__mocks__/preview'
import TattooShop from '@/widgets/TattooShop/TattooShop'
import { previewTattooShop } from '@/widgets/TattooShop/__mocks__/preview'
import { Hud } from '@/pages/Hud/Hud'
import { EventEmitter } from '../features/EventEmitter/EventEmitter'
import { useRpcNavigation } from '@/app/model/useRpcNavigation'
import { NavigationContext } from '@/shared/context/context'
import DeathScreen from '@/shared/ui/DeathScreen/DeathScreen'
import CriticalStateScreen from '@/shared/ui/CriticalStateScreen/CriticalStateScreen'
import Helicam from '@/widgets/Helicam/Helicam'
import CharacterPersonalization from '@/pages/CharacterPersonalization/CharacterPersonalization'
import { Auth } from '@/pages/Auth/Auth'
import { AuthContext, useAuthContextData } from './context/AuthContext'
import { Toast } from '@/shared/ui/Toast/Toast'
import PaymentSystem from '@/pages/PaymentSystem/PaymentSystem'
import PlayerMenu from '@/shared/pages/ui/PlayerMenu/PlayerMenu'

const App = () => {
    const params = useRpcNavigation()
    const authData = useAuthContextData()
    const isAuth = true

    const paymentSystemProps = {
        paymentValue: 4000,
    }

    return (
        <NavigationContext.Provider value={params}>
            <AuthContext.Provider value={authData}>
                <Toast />
                <div
                    className={classNames(
                        styles.container,
                        window.alt?.isInjected && styles.grayBg
                    )}
                >
                    <EventEmitter />
                    {isAuth ? (
                        <Routes>
                            <Route path="/" element={<Hud />} />
                            <Route path="/auth" element={<Auth />} />
                            {/* eslint-disable-next-line react/jsx-pascal-case */}
                            <Route path="/atm" element={<ATM />} />
                            <Route
                                path="/store"
                                element={<Store {...previewStore} />}
                            />
                            <Route
                                path="/tattooShop"
                                element={<TattooShop {...previewTattooShop} />}
                            />
                            <Route
                                path="/deathScreen"
                                element={<DeathScreen />}
                            />
                            <Route
                                path="/criticalStateScreen"
                                element={<CriticalStateScreen />}
                            />
                            <Route path="/helicam" element={<Helicam />} />
                            <Route
                                path="/character"
                                element={<CharacterPersonalization />}
                            />
                            <Route
                                path="/paymentSystem"
                                element={
                                    <PaymentSystem {...paymentSystemProps} />
                                }
                            />
                            <Route
                                path="/playerMenu"
                                element={<PlayerMenu price="17$" />}
                            />
                        </Routes>
                    ) : (
                        <Auth />
                    )}
                </div>
            </AuthContext.Provider>
        </NavigationContext.Provider>
    )
}

export default App
