import { createSlice, PayloadAction } from '@reduxjs/toolkit'

enum EnActiveAttribute {
    Genetic = 'genetic',
    Head = 'head',
    Hair = 'hair',
    Hairline = 'hairline',
    Clothes = 'clothes',
}
enum EnHeadProperties {
    Lips = 'Lips',
    Nose = 'Nose',
    Eyebrow = 'Eyebrow',
    Eye = 'Eye',
    Cheekbones = 'Cheekbones',
    Jaw = 'Jaw',
}
enum EnHairProperties {
    Hair = 'Hair',
    Beard = 'Beard',
    Eyebrow = 'Eyebrow',
    Tors = 'Tors',
}

type TActiveProperty = EnHeadProperties | EnHairProperties

interface IInitialState {
    activeAttribute: EnActiveAttribute
    activeProperty?: TActiveProperty
}
const initialState: IInitialState = {
    activeAttribute: EnActiveAttribute.Clothes,
}

const personalizationViewSlice = createSlice({
    name: 'personalizationView',
    initialState,
    reducers: {
        changeAttribute(state, { payload }: PayloadAction<EnActiveAttribute>) {
            state.activeAttribute = payload
        },
        changeProperty(state, { payload }: PayloadAction<TActiveProperty>) {
            state.activeProperty = payload
        },
    },
})

export const { changeAttribute, changeProperty } =
    personalizationViewSlice.actions
export default personalizationViewSlice.reducer
export {
    EnActiveAttribute,
    type TActiveProperty,
    EnHeadProperties,
    EnHairProperties,
}
