import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IInitialState {
    eyeOpenness: number
}
const initialState: IInitialState = {
    eyeOpenness: 0.5,
}

const personalizationEyeSlice = createSlice({
    name: 'personalizationEye',
    initialState,
    reducers: {
        changeEyeOpenness(state, { payload }: PayloadAction<number>) {
            state.eyeOpenness = payload
        },
    },
})

export const { changeEyeOpenness } = personalizationEyeSlice.actions
export default personalizationEyeSlice.reducer
