import {
    EnActiveAttribute,
    EnHeadProperties,
} from '@/shared/store/slices/personalization/viewSlice'
import { CharacterCheekbonesContent } from '@/widgets/CharacterCheekbonesContent'
import { CharacterCheekContent } from '@/widgets/CharacterCheekContent'
import { CharacterEyebrowContent } from '@/widgets/CharacterEyebrowContent'
import { CharacterEyeContent } from '@/widgets/CharacterEyeContent'
import { CharacterLipsContent } from '@/widgets/CharacterLipsContent'
import { CharacterNoseContent } from '@/widgets/CharacterNoseContent'
import { ReactElement } from 'react'

export const returnHeadComponents = (
    activeProperty?: EnHeadProperties,
    activeAttribute?: EnActiveAttribute
): ReactElement | null => {
    let component = null
    if (
        activeProperty === undefined ||
        activeAttribute !== EnActiveAttribute.Head
    )
        return component

    switch (activeProperty) {
        case EnHeadProperties.Lips:
            component = <CharacterLipsContent />
            break
        case EnHeadProperties.Nose:
            component = <CharacterNoseContent />
            break
        case EnHeadProperties.Eyebrow:
            component = <CharacterEyebrowContent />
            break
        case EnHeadProperties.Eye:
            component = <CharacterEyeContent />
            break
        case EnHeadProperties.Cheekbones:
            component = <CharacterCheekbonesContent />
            break
        case EnHeadProperties.Jaw:
            component = <CharacterCheekContent />
            break
    }

    return component
}
