import { createSelector, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../'

const friendsSlice = createSlice({
    name: 'friendsSlice',
    initialState: {
        data: [
            {
                staticId: 123456,
                uuid: 111,
                name: 'Grigory',
                gender: 'male',
            },
        ],
    },
    reducers: {
        // addFriend: (state, action) => {
        //   const data = action.payload;
        //   if(!state.friends.find(item => item.staticId === data.staticId)){
        //     state.friends.push(data)
        //   }
        // },
        // deleteFriend: (state, action) => {
        //   const data = action.payload;
        //   state.friends = state.friends.filter(item => item.staticId !== data.staticId)
        // },
    },
})

//export const { addFriend, deleteFriend } = friendsSlice.actions;
export default friendsSlice.reducer

export const friendsSelector = createSelector(
    (state: RootState) => state.friends,
    (friends) => friends.data
)
