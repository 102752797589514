import { menuItemVariants } from '../PoliceRadar.config'
import { ContextParams } from '../PoliceRadar.typings'

export const handleMenuSet = (
    arrow: string,
    contextParams: ContextParams[],
    menuIndex: number,
    setContextParams: React.Dispatch<React.SetStateAction<ContextParams[]>>
) => {
    const parameter = menuItemVariants[contextParams[menuIndex].id]
    const currentValueIndex = parameter.indexOf(
        contextParams[menuIndex].speedWindow
    )
    const parameterName = contextParams[menuIndex].id
    if (arrow === 'up') {
        if (currentValueIndex < parameter.length - 1) {
            setContextParams((prev) => {
                const newValue = prev
                newValue[menuIndex].speedWindow =
                    parameter[currentValueIndex + 1]
                localStorage.setItem(
                    parameterName,
                    parameter[currentValueIndex + 1]
                )
                return [...newValue]
            })
        } else {
            setContextParams((prev) => {
                const newValue = prev
                newValue[menuIndex].speedWindow = parameter[0]
                localStorage.setItem(parameterName, parameter[0])
                return [...newValue]
            })
        }
    } else if (arrow === 'down') {
        if (currentValueIndex > 0) {
            setContextParams((prev) => {
                const newValue = prev
                newValue[menuIndex].speedWindow =
                    parameter[currentValueIndex - 1]
                localStorage.setItem(
                    parameterName,
                    parameter[currentValueIndex - 1]
                )
                return [...newValue]
            })
        } else {
            setContextParams((prev) => {
                const newValue = prev
                newValue[menuIndex].speedWindow =
                    parameter[parameter.length - 1]
                localStorage.setItem(
                    parameterName,
                    parameter[parameter.length - 1]
                )
                return [...newValue]
            })
        }
    }
}
