import { rpc } from 'altv-xrpc-webview'
import { baseApi } from '@/shared/store/api/baseApi'

export const voiceChatApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        voiceChange: builder.query<boolean, void>({
            queryFn: () => ({ data: false }),
            async onCacheEntryAdded(
                _,
                { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
            ) {
                try {
                    await cacheDataLoaded
                    // rpc-type-fix
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    rpc.onClient('voice:change', (value: any) => {
                        updateCachedData(() => value.isActive)
                    })
                } catch (error) {
                    //...
                }
                await cacheEntryRemoved
            },
        }),
        voiceSet: builder.query<string, void>({
            queryFn: () => ({ data: 'talk' }),
            async onCacheEntryAdded(
                _,
                { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
            ) {
                try {
                    await cacheDataLoaded
                    // rpc-type-fix
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    rpc.onServer('voice:set', (value: any) => {
                        updateCachedData(() => value.type)
                    })
                } catch (error) {
                    //...
                }
                await cacheEntryRemoved
            },
        }),
    }),
})

export const { useVoiceChangeQuery, useVoiceSetQuery } = voiceChatApi
