export const DeclineIcon = ({
    fill,
    width,
    onSelect,
    action,
}: {
    fill: string
    width: number
    onSelect: (action: string) => void
    action: string
}) => {
    return (
        <svg
            style={{ width: `${width}rem`, aspectRatio: 1 / 1 }}
            width="59"
            height="59"
            viewBox="0 0 59 59"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => onSelect(action)}
        >
            <g clipPath="url(#clip0_715_261)">
                <circle cx="29.5" cy="29.5" r="29.5" fill={fill} />
                <g clipPath="url(#clip1_715_261)">
                    <path
                        d="M31.2407 29.5156L38.6389 22.1171C39.1204 21.6359 39.1204 20.8578 38.6389 20.3766C38.1577 19.8953 37.3796 19.8953 36.8984 20.3766L29.4999 27.775L22.1017 20.3766C21.6202 19.8953 20.8423 19.8953 20.3611 20.3766C19.8796 20.8578 19.8796 21.6359 20.3611 22.1171L27.7593 29.5156L20.3611 36.914C19.8796 37.3953 19.8796 38.1734 20.3611 38.6546C20.6009 38.8947 20.9163 39.0152 21.2314 39.0152C21.5465 39.0152 21.8616 38.8947 22.1017 38.6546L29.4999 31.2561L36.8984 38.6546C37.1384 38.8947 37.4535 39.0152 37.7686 39.0152C38.0838 39.0152 38.3989 38.8947 38.6389 38.6546C39.1204 38.1734 39.1204 37.3953 38.6389 36.914L31.2407 29.5156Z"
                        fill="black"
                    />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_715_261">
                    <rect width="59" height="59" fill="white" />
                </clipPath>
                <clipPath id="clip1_715_261">
                    <rect
                        width="19"
                        height="19"
                        fill="white"
                        transform="translate(20 20)"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}
