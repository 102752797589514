import {
    EnActiveAttribute,
    EnHairProperties,
    EnHeadProperties,
} from '@/shared/store/slices/personalization/viewSlice'
import {
    Beard,
    Cheekbones,
    Clothes,
    Eye,
    Eyebrow,
    Genetic,
    Hairs,
    Hairstyle,
    Head,
    Lips,
    Nose,
} from '@/shared/icons/generated'
import { Hairline } from '@/shared/icons/Hairline'
import { TAttributeValue } from '../types'
import Jaw from '@/shared/icons/generated/Jaw'

const STRUCTURE: TAttributeValue[] = [
    [{ slug: EnActiveAttribute.Genetic, icon: Genetic }, []],
    [
        { slug: EnActiveAttribute.Head, icon: Head },
        [
            { slug: EnHeadProperties.Lips, icon: Lips },
            { slug: EnHeadProperties.Nose, icon: Nose },
            { slug: EnHeadProperties.Eyebrow, icon: Eyebrow },
            { slug: EnHeadProperties.Eye, icon: Eye },
            { slug: EnHeadProperties.Cheekbones, icon: Cheekbones },
            { slug: EnHeadProperties.Jaw, icon: Jaw },
        ],
    ],
    [
        { slug: EnActiveAttribute.Hair, icon: Hairs },
        [
            {
                slug: EnHairProperties.Hair,
                icon: Hairstyle,
            },
            {
                slug: EnHairProperties.Eyebrow,
                icon: Eyebrow,
            },
            { slug: EnHairProperties.Beard, icon: Beard },
            { slug: EnHairProperties.Tors, icon: Beard },
        ],
    ],
    [{ slug: EnActiveAttribute.Hairline, icon: Hairline }, []],
    [{ slug: EnActiveAttribute.Clothes, icon: Clothes }, []],
]
export { STRUCTURE, type TAttributeValue }
