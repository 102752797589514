export const createRange = (range: number[], step: number) => {
    const arr = []
    for (let i = range[0]; i <= range[1]; i += step) {
        if (step < 1)
            arr.push({
                label: String(Math.round(i * 10) / 10),
                value: Math.round(i * 10) / 10,
            })
        else arr.push({ label: String(i), value: i })
    }
    return arr
}
