import { Props } from './Cart.typings'

import supermarketCart from './SupermarketCart.module.scss'
import buildingStoreCart from './BuildingStoreCart.module.scss'
import clothesStoreCart from './ClothesStoreCart.module.scss'
import electronicsStoreCart from './ElectronicsStoreCart.module.scss'
import furnitureStoreCart from './FurnitureStoreCart.module.scss'
import jewelryStoreCart from './JewelryStoreCart.module.scss'
import pharmacyCart from './PharmacyCart.module.scss'
import deleteProductIcon from '@/shared/assets/images/vectors/Store/Cart/deleteProductPic.svg'

const stylesObject: Record<string, { readonly [key: string]: string }> = {
    //Cart styles
    supermarket: supermarketCart,
    pharmacy: pharmacyCart,
    electronicsStore: electronicsStoreCart,
    furnitureStore: furnitureStoreCart,
    jewelryStore: jewelryStoreCart,
    buildingStore: buildingStoreCart,
    clothesStore: clothesStoreCart,
}

const Cart = (props: Props) => {
    const mainStyles = stylesObject[props.storeType]

    return (
        <div className={mainStyles.mainShape}>
            {/* -------------header------------- */}
            <div className={mainStyles.header}>Корзина</div>

            {/* -------------main---------------- */}

            <div className={mainStyles.goods}>
                {props.goods.map((product) => (
                    <div className={mainStyles.product} key={product.productId}>
                        <img
                            className={mainStyles.productIcon}
                            src={product.productIcon}
                        />
                        <div className={mainStyles.description}>
                            {product.productDescription}
                        </div>
                        <div
                            className={mainStyles.valueButton}
                            onClick={() => props.onPlusOrMinus(product, false)}
                        >
                            -
                        </div>
                        <div className={mainStyles.value}>
                            {product.productValue}
                        </div>
                        <div
                            className={mainStyles.valueButton}
                            onClick={() => props.onPlusOrMinus(product, true)}
                        >
                            +
                        </div>
                        <div className={mainStyles.productPrice}>
                            {product.productPrice} $
                        </div>
                        <div
                            className={mainStyles.deleteProduct}
                            onClick={() => props.onDelete(product.productId)}
                        >
                            <img
                                className={mainStyles.deleteProductIcon}
                                src={deleteProductIcon}
                            />
                        </div>
                    </div>
                ))}
            </div>

            {/* ---------------footer----------------- */}
            <div className={mainStyles.footer}>
                <div className={mainStyles.saleInfo}>
                    <span>Sales Tax:</span>
                    <span>{props.tax.toLocaleString()} $</span>
                </div>
                <div className={mainStyles.saleInfo}>
                    <span>Итого:</span>
                    <span>{props.total.toLocaleString()} $</span>
                </div>
                <div
                    className={mainStyles.buyButton}
                    onClick={() => props.onBuy(true, props.total)}
                >
                    Купить
                </div>
            </div>
        </div>
    )
}

export default Cart
