import { Props } from './SidebarCategories.typings'
import classNames from 'classnames'
import { useState } from 'react'

import supermarket from './SidebarCategoriesSupermarket.module.scss'
import pharmacy from './SidebarCategoriesPharmacy.module.scss'
import electronicsStore from './SidebarCategoriesElectronicsStore.module.scss'
import furnitureStore from './SidebarCategoriesFurnitureStore.module.scss'
import jewelryStore from './SidebarCategoriesJewelryStore.module.scss'
import buildingStore from './SidebarCategoriesBuildingStore.module.scss'
import clothesStore from './SidebarCategoriesClothesStore.module.scss'
import tattooShop from './SidebarCategoriesTattooShop.module.scss'

const stylesObject: Record<string, { [key: string]: string }> = {
    //Стили основного компонента
    supermarket: supermarket,
    pharmacy: pharmacy,
    electronicsStore: electronicsStore,
    furnitureStore: furnitureStore,
    jewelryStore: jewelryStore,
    buildingStore: buildingStore,
    clothesStore: clothesStore,
    tattooShop: tattooShop,
}

const SidebarCategories = (props: Props) => {
    const mainStyles = stylesObject[props.storeType]

    const [activeCategory, setActiveCategory] = useState<string>(
        props.categories[0].key
    )

    const setSidebarButtonCheck = (category: string) => {
        props.onSelect(category)
        setActiveCategory(category)
    }

    return (
        <div className={mainStyles.sidebar}>
            {props.categories.map(({ key, icon }) => {
                return (
                    <div
                        key={key}
                        className={classNames(
                            mainStyles.sidebarButton,
                            activeCategory == key &&
                                mainStyles.sidebarButtonSelected
                        )}
                        onClick={() => setSidebarButtonCheck(key)}
                    >
                        <img
                            src={icon}
                            className={classNames(
                                mainStyles.sidebarButtonPicDefault,
                                activeCategory !== key &&
                                    mainStyles.sidebarButtonPic
                            )}
                        />
                    </div>
                )
            })}
        </div>
    )
}

export default SidebarCategories
