import React, { SVGProps } from 'react'
import styles from './CircleItem.module.scss'
import classNames from 'classnames'

interface Props {
    Icon: (props: SVGProps<SVGSVGElement>) => JSX.Element
    isActive: boolean
    onClick: () => void
}

export const CircleItem: React.FC<Props> = ({ Icon, isActive, onClick }) => {
    return (
        <div
            className={classNames(styles.container, isActive && styles.active)}
            onClick={onClick}
        >
            <Icon className={styles.icon} />
        </div>
    )
}
