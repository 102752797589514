import { FC } from 'react'
import cls from './index.module.scss'
import { TClassName } from '@/shared/types/shared'
import classNames from 'classnames'
import { COLORS } from '../../constants/colors'
import { useAppDispatch, useAppSelector } from '@/shared/hooks/redux-ts'
import { EnFamilyGenes } from '../../constants/slides'
import { changeLeadingColor } from '@/shared/store/slices/personalization/geneticSlice'

interface Props extends TClassName {}
const ParameterLeadingColor: FC<Props> = ({ className }) => {
    const dispatch = useAppDispatch()
    const selectedLeadingColor = useAppSelector(
        (state) => state.personalization.genetic.selectedLeadingColor
    )
    const handleSelectColor = (id: EnFamilyGenes) => {
        dispatch(changeLeadingColor(id))
    }

    return (
        <div className={classNames(cls.wrapper, className)}>
            <h2 className={cls.title}>Цвет кожи</h2>
            <ul className={cls.group}>
                {COLORS.map(({ colorImage, id }) => (
                    <li
                        key={id}
                        className={classNames(cls.item, {
                            [cls.active]: selectedLeadingColor === id,
                        })}
                        onClick={() => {
                            handleSelectColor(id)
                        }}
                    >
                        <img src={colorImage} alt="leading color image" />
                    </li>
                ))}
            </ul>
        </div>
    )
}

export { ParameterLeadingColor }
