import { call911 } from './modules/call911'
import { loseConsciousness } from './modules/loseConsciousness'

export const buttons = [
    {
        title: 'Вызвать 911',
        function: call911,
    },
    {
        title: 'Потерять сознание',
        function: loseConsciousness,
    },
]
