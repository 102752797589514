import { ChangeEventHandler, FC } from 'react'
import cls from './index.module.scss'
import { TClassName } from '@/shared/types/shared'
import { CharacterPropertyContent } from '@/widgets/CharacterPropertyContent'
import classNames from 'classnames'
import { SliderInput } from '@/shared/ui/SliderInput'
import { useAppDispatch, useAppSelector } from '@/shared/hooks/redux-ts'
import { pxToRem } from '@/shared/lib/pxToRem'
import {
    changeNoseWidth,
    changeNoseHeight,
    changeNoseLength,
    changeTipNose,
    changeBridgeNose,
    changeDisplacementBridgeNose,
} from '@/shared/store/slices/personalization/head/noseSlice'

interface Props extends TClassName {}
const CharacterNoseContent: FC<Props> = ({ className }) => {
    const dispatch = useAppDispatch()
    const noseWidth = useAppSelector(
        (state) => state.personalization.head.nose.noseWidth
    )
    const noseHeight = useAppSelector(
        (state) => state.personalization.head.nose.noseHeight
    )
    const bridgeNose = useAppSelector(
        (state) => state.personalization.head.nose.bridgeNose
    )
    const tipNose = useAppSelector(
        (state) => state.personalization.head.nose.tipNose
    )
    const displacementBridgeNose = useAppSelector(
        (state) => state.personalization.head.nose.displacementBridgeNose
    )
    const noseLength = useAppSelector(
        (state) => state.personalization.head.nose.noseLength
    )
    const handleNoseWidth: ChangeEventHandler<HTMLInputElement> = (event) => {
        dispatch(changeNoseWidth(+event.target.value / 100))
    }
    const handleNoseHeight: ChangeEventHandler<HTMLInputElement> = (event) => {
        dispatch(changeNoseHeight(+event.target.value / 100))
    }
    const handleNoseLength: ChangeEventHandler<HTMLInputElement> = (event) => {
        dispatch(changeNoseLength(+event.target.value / 100))
    }
    const handleTipNose: ChangeEventHandler<HTMLInputElement> = (event) => {
        dispatch(changeTipNose(+event.target.value / 100))
    }
    const handleBridgeNose: ChangeEventHandler<HTMLInputElement> = (event) => {
        dispatch(changeBridgeNose(+event.target.value / 100))
    }
    const handleDisplacementBridgeNose: ChangeEventHandler<HTMLInputElement> = (
        event
    ) => {
        dispatch(changeDisplacementBridgeNose(+event.target.value / 100))
    }

    return (
        <CharacterPropertyContent
            items={[
                {
                    content: (
                        <SliderInput
                            thumbSize={pxToRem(20)}
                            min={0}
                            max={100}
                            step={10}
                            defaultValue={noseWidth * 100}
                            onChange={handleNoseWidth}
                        />
                    ),
                    title: 'Ширина носа',
                },
                {
                    content: (
                        <SliderInput
                            thumbSize={pxToRem(20)}
                            min={0}
                            max={100}
                            step={10}
                            defaultValue={noseHeight * 100}
                            onChange={handleNoseHeight}
                        />
                    ),
                    title: 'Высота носа',
                },
                {
                    content: (
                        <SliderInput
                            thumbSize={pxToRem(20)}
                            min={0}
                            max={100}
                            step={10}
                            defaultValue={noseLength * 100}
                            onChange={handleNoseLength}
                        />
                    ),
                    title: 'Длина носа',
                },
                {
                    content: (
                        <SliderInput
                            thumbSize={pxToRem(20)}
                            min={0}
                            max={100}
                            step={10}
                            defaultValue={tipNose * 100}
                            onChange={handleTipNose}
                        />
                    ),
                    title: 'Длина кончика носа',
                },
                {
                    content: (
                        <SliderInput
                            thumbSize={pxToRem(20)}
                            min={0}
                            max={100}
                            step={10}
                            defaultValue={bridgeNose * 100}
                            onChange={handleBridgeNose}
                        />
                    ),
                    title: 'Переносица',
                },
                {
                    content: (
                        <SliderInput
                            thumbSize={pxToRem(20)}
                            min={0}
                            max={100}
                            step={10}
                            defaultValue={displacementBridgeNose * 100}
                            onChange={handleDisplacementBridgeNose}
                        />
                    ),
                    title: 'Дефекты носа',
                },
            ]}
            title="Нос"
            className={classNames(cls.wrapper, className)}
        />
    )
}

export { CharacterNoseContent }
