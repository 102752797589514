import * as React from 'react'
import { SVGProps } from 'react'

export const Hairline = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0, 0, 400,400"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M267.216 8.279 C 259.456 21.442,247.331 44.882,240.380 60.156 C 235.855 70.100,234.098 74.063,232.417 78.125 C 220.870 106.017,217.310 115.210,210.331 135.156 C 186.770 202.503,167.128 284.087,166.880 315.633 C 166.642 345.873,198.262 360.478,218.815 339.623 C 228.475 329.822,229.277 320.791,224.518 275.391 C 216.490 198.786,225.759 141.249,264.956 24.391 C 273.849 -2.121,274.006 -3.239,267.216 8.279 M0.000 318.359 L 0.000 394.531 197.266 394.531 L 394.531 394.531 394.531 318.359 L 394.531 242.188 330.942 242.188 C 259.696 242.188,262.008 242.016,253.392 247.925 C 241.503 256.078,236.537 269.383,239.349 285.547 C 247.340 331.482,234.007 360.123,203.186 363.230 C 162.530 367.328,143.671 335.336,156.720 284.406 C 161.066 267.442,150.237 249.113,132.629 243.633 C 128.271 242.276,124.059 242.188,63.993 242.188 L 0.000 242.188 0.000 318.359 "
            stroke="none"
            fill={props.color}
            fillRule="evenodd"
        />
    </svg>
)
