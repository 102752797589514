import { FC, MouseEventHandler } from 'react'
import { createPortal } from 'react-dom'
import { useAppDispatch, useAppSelector } from '@/shared/hooks/redux-ts'
import {
    hideModal,
    modalsStates,
    TModalSlug,
} from '@/shared/store/slices/modalsSlice'
import cls from './index.module.scss'
import { TClassName, TChildren } from '@/shared/types/shared'
import classNames from 'classnames'

interface Props extends TClassName, TChildren {
    slug: TModalSlug
    handleClose?: MouseEventHandler
    contentCls?: string
}
const UiModal: FC<Props> = ({
    className,
    children,
    slug,
    handleClose,
    contentCls,
}) => {
    const dispatch = useAppDispatch()
    const modalsNode = document.querySelector('#modals')
    const modalProps = useAppSelector(modalsStates)[slug]
    const handleModalClose: MouseEventHandler = (e) => {
        dispatch(hideModal({ slug }))
        handleClose && handleClose(e)
    }
    return (
        <>
            {!modalProps ||
                (modalProps.state &&
                    modalsNode &&
                    createPortal(
                        <section
                            className={classNames(cls.wrapper, className)}
                            onClick={handleModalClose}
                        >
                            <div className={cls.content_wrapper}>
                                <div
                                    onClick={(e) => {
                                        e.stopPropagation()
                                    }}
                                    className={classNames(
                                        cls.content,
                                        contentCls
                                    )}
                                >
                                    {children}
                                </div>
                            </div>
                        </section>,
                        modalsNode
                    ))}
        </>
    )
}

export { UiModal }
