import { Input } from '../Input/Input'
import Password from '@/shared/icons/generated/Password'
import { FC, ChangeEventHandler } from 'react'
import { useKeyboard } from '@/shared/hooks/useKeyboard'
import styles from './PasswordInput.module.scss'

interface PasswordInputProps {
    value?: string
    name: string
    onChange: ChangeEventHandler<HTMLInputElement>
    className?: string
    placeholder?: string
    error?: any
}

export const PasswordInput: FC<PasswordInputProps> = ({
    value,
    onChange,
    name,
    className,
    placeholder = 'Пароль',
    error,
}) => {
    const { capsLockEnabled } = useKeyboard()

    return (
        <div className={styles.input}>
            <Input
                Icon={Password}
                placeholder={placeholder}
                type="password"
                onChange={onChange}
                value={value}
                name={name}
                className={className}
                error={error}
            />
            {capsLockEnabled ? (
                <div className={styles.capsBlock}>Caps Lock</div>
            ) : null}
        </div>
    )
}
