export const countriesArr = [
    {
        name: 'USA',
        cities: [
            'New York',
            'Los Angeles',
            'Chicago',
            'Houston',
            'Phoenix',
            'Philadelphia',
            'San Antonio',
            'San Diego',
            'Dallas',
            'San Jose',
        ],
    },
    {
        name: 'Canada',
        cities: [
            'Toronto',
            'Montreal',
            'Vancouver',
            'Calgary',
            'Edmonton',
            'Ottawa',
            'Winnipeg',
            'Quebec City',
            'Hamilton',
            'London',
        ],
    },
    {
        name: 'United Kingdom',
        cities: [
            'London',
            'Birmingham',
            'Manchester',
            'Glasgow',
            'Liverpool',
            'Newcastle',
            'Sheffield',
            'Bristol',
            'Leeds',
            'Cardiff',
        ],
    },
    {
        name: 'Germany',
        cities: [
            'Berlin',
            'Hamburg',
            'Munich',
            'Cologne',
            'Frankfurt',
            'Stuttgart',
            'Dusseldorf',
            'Dortmund',
            'Essen',
            'Bremen',
        ],
    },
    {
        name: 'France',
        cities: [
            'Paris',
            'Marseille',
            'Lyon',
            'Toulouse',
            'Nice',
            'Nantes',
            'Strasbourg',
            'Montpellier',
            'Bordeaux',
            'Lille',
        ],
    },
    {
        name: 'Australia',
        cities: [
            'Sydney',
            'Melbourne',
            'Brisbane',
            'Perth',
            'Adelaide',
            'Gold Coast',
            'Canberra',
            'Newcastle',
            'Sunshine Coast',
            'Wollongong',
        ],
    },
    {
        name: 'Japan',
        cities: [
            'Tokyo',
            'Osaka',
            'Yokohama',
            'Nagoya',
            'Sapporo',
            'Fukuoka',
            'Kawasaki',
            'Kobe',
            'Kyoto',
            'Saitama',
        ],
    },
    {
        name: 'Brazil',
        cities: [
            'Sao Paulo',
            'Rio de Janeiro',
            'Brasilia',
            'Salvador',
            'Fortaleza',
            'Belo Horizonte',
            'Manaus',
            'Curitiba',
            'Recife',
            'Porto Alegre',
        ],
    },
    {
        name: 'India',
        cities: [
            'Mumbai',
            'Delhi',
            'Bangalore',
            'Hyderabad',
            'Chennai',
            'Kolkata',
            'Ahmedabad',
            'Pune',
            'Surat',
            'Jaipur',
        ],
    },
    {
        name: 'South Africa',
        cities: [
            'Johannesburg',
            'Cape Town',
            'Durban',
            'Pretoria',
            'Port Elizabeth',
            'Bloemfontein',
            'Nelspruit',
            'Polokwane',
            'East London',
            'Kimberley',
        ],
    },
]
