import { useState } from 'react'
import classNames from 'classnames'
import selectArrowPic from './assets/selectArrowPic.svg'

import supermarketSortSelect from './SupermarketSortSelect.module.scss'
import buildingStoreSortSelect from './BuildingStoreSortSelect.module.scss'
import clothesStoreSortSelect from './ClothesStoreSortSelect.module.scss'
import electronicsStoreSortSelect from './ElectronicsStoreSortSelect.module.scss'
import furnitureStoreSortSelect from './FurnitureStoreSortSelect.module.scss'
import jewelryStoreSortSelect from './JewelryStoreInputElements.module.scss'
import pharmacySortSelect from './PharmacyInputElements.module.scss'
import tattooShopSortSelect from './TattooShopSortSelect.module.scss'

const stylesObject: Record<string, { [key: string]: string }> = {
    //InputElements styles
    supermarket: supermarketSortSelect,
    pharmacy: pharmacySortSelect,
    electronicsStore: electronicsStoreSortSelect,
    furnitureStore: furnitureStoreSortSelect,
    jewelryStore: jewelryStoreSortSelect,
    buildingStore: buildingStoreSortSelect,
    clothesStore: clothesStoreSortSelect,
    tattooShop: tattooShopSortSelect,
}

interface Select {
    key: string
    title: string
}

interface Props {
    storeType: string
    select: Select[]
    onApplySelect: (param: string) => void
}

const SortSelect = (props: Props) => {
    const [openSelect, setOpenSelect] = useState<boolean>(false)
    const [sort, setSort] = useState<string>(props.select[0].title)
    const [sortId, setSortId] = useState<string>(props.select[0].key)

    const mainStyles = stylesObject[props.storeType]

    const openSortSelect = () => {
        setOpenSelect((prevOpenSelect) => !prevOpenSelect)
    }

    const applySelect = (key: string, title: string) => {
        setSort(title)
        setOpenSelect(false)
        setSortId(key)
        props.onApplySelect(key)
    }

    return (
        <div className={mainStyles.selectWrapper}>
            <div className={mainStyles.select} onClick={openSortSelect}>
                {sort}
                <img className={mainStyles.arrow} src={selectArrowPic} />
            </div>
            {openSelect ? (
                <div className={mainStyles.openSelect}>
                    {props.select.map((option) => (
                        <div
                            key={option.key}
                            className={classNames(
                                mainStyles.selectOption,
                                sortId == option.key &&
                                    mainStyles.selectOptionSelected
                            )}
                            onClick={() =>
                                applySelect(option.key, option.title)
                            }
                        >
                            {option.title}
                        </div>
                    ))}
                </div>
            ) : null}
        </div>
    )
}

export default SortSelect
