import {
    passwordValidate,
    confirmationPasswordValidate,
} from '@/shared/utils/validateMethods'
import * as Yup from 'yup'

export const passwordConfirmationSchema = Yup.object().shape({
    password: passwordValidate,
    confirmationPassword: confirmationPasswordValidate('password'),
})
