import cls from './index.module.scss'
import { FC } from 'react'
import { TClassName } from '@/shared/types/shared'
import classNames from 'classnames'
import { ParameterLeadingColor } from '../ParameterLeadingColor'
import { ParameterSimilarLeading } from '../ParameterSimilarLeading'
import { ParameterLeadingMix } from '../ParameterLeadingMix'

interface Props extends TClassName {}
const Parameters: FC<Props> = ({ className }) => {
    return (
        <div className={classNames(cls.wrapper, className)}>
            <ParameterLeadingColor />
            <ParameterSimilarLeading />
            <ParameterLeadingMix />
        </div>
    )
}

export { Parameters }
