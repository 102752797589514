import styles from './CharacterPersonalization.module.scss'
import CameraRotation from './commonComponents/CameraRotation/CameraRotation'
import ScreenTitle from './commonComponents/ScreenTitle/ScreenTitle'
import { AboutScreen } from './screens/About'
import { useAppSelector } from '@/shared/hooks/redux-ts'
import { ViewScreen } from './screens/View'
import { CharacterPersonalizationSteps } from '@/app/layouts'
import { CharacterPersonalizationScreens } from '@/shared/store/slices/personalization/personalizationSlice'

const CharacterPersonalization = () => {
    const activeScreen = useAppSelector(
        (state) => state.personalization.personalization.activeScreen
    )

    return (
        <div className={styles.wrapper}>
            <ScreenTitle />
            <CameraRotation />
            {activeScreen === CharacterPersonalizationScreens.about ? (
                <AboutScreen />
            ) : activeScreen === CharacterPersonalizationScreens.view ? (
                <ViewScreen />
            ) : null}
            <CharacterPersonalizationSteps
                actionsPos={
                    activeScreen === CharacterPersonalizationScreens.about
                        ? 'center'
                        : 'left'
                }
            />
        </div>
    )
}

export default CharacterPersonalization
