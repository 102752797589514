import styles from './Message.module.scss'
import { motion } from 'framer-motion'

const Message = ({ text }: { text: string }) => {
    return (
        <motion.div
            className={styles.message}
            initial={{ opacity: 0 }}
            animate={{ opacity: [0, 1, 1, 0] }}
            transition={{ duration: 5, times: [0, 0.2, 0.8, 1] }}
        >
            {text}
        </motion.div>
    )
}

export default Message
