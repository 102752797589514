import { FC } from 'react'
import cls from './index.module.scss'
import { TClassName } from '@/shared/types/shared'
import classNames from 'classnames'
import { CharacterVariantsWrapper } from '@/widgets/CharacterVariantsWrapper'
import { VARIANTS_ITEMS } from '../constants/variants'
import { COLORS_ITEMS } from '../constants/colors'
import { EnColors } from '@/shared/constants/character/hairsColors'
import {
    changeColor,
    changeVariant,
} from '@/shared/store/slices/personalization/hair/torsSlice'
import { useAppDispatch } from '@/shared/hooks/redux-ts'

interface Props extends TClassName {}
const CharacterHairTorsContent: FC<Props> = ({ className }) => {
    const dispatch = useAppDispatch()
    const handleColorSelect = (id: EnColors) => {
        dispatch(changeColor(id))
    }
    const handleVariantSelect = (id: number) => {
        dispatch(changeVariant(id))
    }

    return (
        <CharacterVariantsWrapper
            className={classNames(cls.wrapper, className)}
            colors={{
                items: COLORS_ITEMS(handleColorSelect, cls.color_item),
                title: 'Цвет волос',
                activeCls: cls.color_active,
            }}
            variants={{
                items: VARIANTS_ITEMS(handleVariantSelect, cls.variants_item),
                title: 'Волосы на груди',
                activeCls: cls.variant_active,
            }}
        />
    )
}

export { CharacterHairTorsContent }
