import { useState } from 'react'
import styles from './GoalSelectItem.module.scss'
import { type TSubGoals } from './GoalSelectItem.typings'
import SubGoals from './SubGoals/SubGoals'
import cn from 'classnames'
import { useAppSelector } from '@/shared/hooks/redux-ts'
import { EnCharacterPurpose } from '../../screens/About/components/LifeGoalSelect/LifeGoalSelect.typings'
import { EnGoals } from '@/shared/store/slices/personalization/baseSlice'

interface Props {
    id: number
    img: string
    title: string
    checkedGoal?: number | EnCharacterPurpose
    onSelect: any
    subGoals: TSubGoals[]
}

const GoalSelectItem = ({
    id,
    img,
    onSelect,
    subGoals,
    title,
    checkedGoal,
}: Props) => {
    const lifeGoals = useAppSelector(
        (state) => state.personalization.base.lifeGoals
    )
    const [isSubgoalsVisible, setSubgoalsVisible] = useState<boolean>(false)

    const handleClick = () => {
        setSubgoalsVisible(true)
        onSelect(EnGoals.mainGoal, id)
    }

    return (
        <div
            className={cn(styles.wrapper, checkedGoal !== id && styles.hover)}
            onClick={() => handleClick()}
        >
            {lifeGoals.mainGoalId === id
                ? subGoals &&
                  isSubgoalsVisible && (
                      <SubGoals
                          subGoals={subGoals}
                          onSelect={onSelect}
                          lifeGoals={lifeGoals}
                          closeTiles={setSubgoalsVisible}
                      />
                  )
                : null}
            <div className={styles.imgContainer}>
                <img src={img} className={styles.icon} />
            </div>
            <div
                className={cn(
                    styles.title,
                    checkedGoal === id && styles.checked
                )}
            >
                {title}
            </div>
        </div>
    )
}

export default GoalSelectItem
