import { useAppDispatch, useAppSelector } from '@/shared/hooks/redux-ts'
import styles from './TraitSelect.module.scss'
import { Props } from './TraitSelect.typings'
import cn from 'classnames'
import {
    changeCharacterTraits,
    EnTraitsSlot,
    setSelectedTraitSlot,
} from '@/shared/store/slices/personalization/baseSlice'

const TraitSelect = (props: Props) => {
    const isCharacterTraitsVisible = useAppSelector(
        (state) => state.personalization.base.isCharacterTraitsVisible
    )
    const selectedTraitSlot = useAppSelector(
        (state) => state.personalization.base.selectedTraitSlot
    )
    const dispatch = useAppDispatch()
    const handleSelectTrait = () => {
        dispatch(changeCharacterTraits(props.item))
        if (isCharacterTraitsVisible) {
            if (selectedTraitSlot === EnTraitsSlot.trait1) {
                dispatch(setSelectedTraitSlot(EnTraitsSlot.trait2))
            } else if (selectedTraitSlot === EnTraitsSlot.trait2) {
                dispatch(setSelectedTraitSlot(EnTraitsSlot.trait3))
            }
        }
    }

    return (
        <div
            className={cn(styles.wrapper, styles.hover)}
            onClick={() => handleSelectTrait()}
        >
            <div className={styles.imgContainer}>
                <img src={props.item.icon} className={styles.icon} />
            </div>
            <div className={cn(styles.title, styles.checked)}>
                {props.item.title}
            </div>
        </div>
    )
}

export default TraitSelect
