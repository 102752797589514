import img0 from '@/shared/assets/images/illustrations/Character/Genetic/0.png'
import img1 from '@/shared/assets/images/illustrations/Character/Genetic/1.png'
import img2 from '@/shared/assets/images/illustrations/Character/Genetic/2.png'
import img3 from '@/shared/assets/images/illustrations/Character/Genetic/3.png'
import img4 from '@/shared/assets/images/illustrations/Character/Genetic/4.png'
import img5 from '@/shared/assets/images/illustrations/Character/Genetic/5.png'
import img6 from '@/shared/assets/images/illustrations/Character/Genetic/6.png'
import img7 from '@/shared/assets/images/illustrations/Character/Genetic/7.png'
import img8 from '@/shared/assets/images/illustrations/Character/Genetic/8.png'
import img9 from '@/shared/assets/images/illustrations/Character/Genetic/9.png'
import img10 from '@/shared/assets/images/illustrations/Character/Genetic/10.png'
import img11 from '@/shared/assets/images/illustrations/Character/Genetic/11.png'
import img12 from '@/shared/assets/images/illustrations/Character/Genetic/12.png'
import img13 from '@/shared/assets/images/illustrations/Character/Genetic/13.png'
import img14 from '@/shared/assets/images/illustrations/Character/Genetic/14.png'
import img15 from '@/shared/assets/images/illustrations/Character/Genetic/15.png'
import img16 from '@/shared/assets/images/illustrations/Character/Genetic/16.png'
import img17 from '@/shared/assets/images/illustrations/Character/Genetic/17.png'
import img18 from '@/shared/assets/images/illustrations/Character/Genetic/18.png'
import img19 from '@/shared/assets/images/illustrations/Character/Genetic/19.png'
import img20 from '@/shared/assets/images/illustrations/Character/Genetic/20.png'
import img21 from '@/shared/assets/images/illustrations/Character/Genetic/21.png'
import img22 from '@/shared/assets/images/illustrations/Character/Genetic/22.png'
import img23 from '@/shared/assets/images/illustrations/Character/Genetic/23.png'
import img24 from '@/shared/assets/images/illustrations/Character/Genetic/24.png'
import img25 from '@/shared/assets/images/illustrations/Character/Genetic/25.png'
import img26 from '@/shared/assets/images/illustrations/Character/Genetic/26.png'
import img27 from '@/shared/assets/images/illustrations/Character/Genetic/27.png'
import img28 from '@/shared/assets/images/illustrations/Character/Genetic/28.png'
import img29 from '@/shared/assets/images/illustrations/Character/Genetic/29.png'
import img30 from '@/shared/assets/images/illustrations/Character/Genetic/30.png'
import img31 from '@/shared/assets/images/illustrations/Character/Genetic/31.png'
import img32 from '@/shared/assets/images/illustrations/Character/Genetic/32.png'
import img33 from '@/shared/assets/images/illustrations/Character/Genetic/33.png'
import img34 from '@/shared/assets/images/illustrations/Character/Genetic/34.png'
import img35 from '@/shared/assets/images/illustrations/Character/Genetic/35.png'
import img36 from '@/shared/assets/images/illustrations/Character/Genetic/36.png'
import img37 from '@/shared/assets/images/illustrations/Character/Genetic/37.png'
import img38 from '@/shared/assets/images/illustrations/Character/Genetic/38.png'
import img39 from '@/shared/assets/images/illustrations/Character/Genetic/39.png'
import img40 from '@/shared/assets/images/illustrations/Character/Genetic/40.png'
import img41 from '@/shared/assets/images/illustrations/Character/Genetic/41.png'
import img42 from '@/shared/assets/images/illustrations/Character/Genetic/42.png'
import img43 from '@/shared/assets/images/illustrations/Character/Genetic/43.png'
import img44 from '@/shared/assets/images/illustrations/Character/Genetic/44.png'
import img45 from '@/shared/assets/images/illustrations/Character/Genetic/45.png'

enum EnFamilyGenes {
    Benjamin = 0,
    Daniel = 1,
    Joshua = 2,
    Noah = 3,
    Andrew = 4,
    Juan = 5,
    Alex = 6,
    Isaac = 7,
    Evan = 8,
    Ethan = 9,
    Vincent = 10,
    Angel = 11,
    Diego = 12,
    Adrian = 13,
    Gabriel = 14,
    Michael = 15,
    Santiago = 16,
    Kevin = 17,
    Louis = 18,
    Samuel = 19,
    Anthony = 20,
    Hannah = 21,
    Audrey = 22,
    Jasmine = 23,
    Giselle = 24,
    Amelia = 25,
    Isabella = 26,
    Zoe = 27,
    Ava = 28,
    Camila = 29,
    Violet = 30,
    Sophia = 31,
    Evelyn = 32,
    Nicole = 33,
    Ashley = 34,
    Grace = 35,
    Brianna = 36,
    Natalie = 37,
    Olivia = 38,
    Elizabeth = 39,
    Charlotte = 40,
    Emma = 41,
    Claude = 42,
    Niko = 43,
    John = 44,
    Misty = 45,
}

const FATHER_SLIDES = [
    {
        id: EnFamilyGenes.Benjamin,
        image: img0,
    },
    {
        id: EnFamilyGenes.Daniel,
        image: img1,
    },
    {
        id: EnFamilyGenes.Joshua,
        image: img2,
    },
    {
        id: EnFamilyGenes.Noah,
        image: img3,
    },
    {
        id: EnFamilyGenes.Andrew,
        image: img4,
    },
    {
        id: EnFamilyGenes.Juan,
        image: img5,
    },
    {
        id: EnFamilyGenes.Alex,
        image: img6,
    },
    {
        id: EnFamilyGenes.Isaac,
        image: img7,
    },
    {
        id: EnFamilyGenes.Evan,
        image: img8,
    },
    {
        id: EnFamilyGenes.Ethan,
        image: img9,
    },
    {
        id: EnFamilyGenes.Vincent,
        image: img10,
    },
    {
        id: EnFamilyGenes.Angel,
        image: img11,
    },
    {
        id: EnFamilyGenes.Diego,
        image: img12,
    },
    {
        id: EnFamilyGenes.Adrian,
        image: img13,
    },
    {
        id: EnFamilyGenes.Gabriel,
        image: img14,
    },
    {
        id: EnFamilyGenes.Michael,
        image: img15,
    },
    {
        id: EnFamilyGenes.Santiago,
        image: img16,
    },
    {
        id: EnFamilyGenes.Kevin,
        image: img17,
    },
    {
        id: EnFamilyGenes.Louis,
        image: img18,
    },
    {
        id: EnFamilyGenes.Samuel,
        image: img19,
    },
    {
        id: EnFamilyGenes.Anthony,
        image: img20,
    },
    {
        id: EnFamilyGenes.Hannah,
        image: img21,
    },
    {
        id: EnFamilyGenes.Audrey,
        image: img22,
    },
] as const
const MOTHER_SLIDES = [
    {
        id: EnFamilyGenes.Jasmine,
        image: img23,
    },
    {
        id: EnFamilyGenes.Giselle,
        image: img24,
    },
    {
        id: EnFamilyGenes.Amelia,
        image: img25,
    },
    {
        id: EnFamilyGenes.Isabella,
        image: img26,
    },
    {
        id: EnFamilyGenes.Zoe,
        image: img27,
    },
    {
        id: EnFamilyGenes.Ava,
        image: img28,
    },
    {
        id: EnFamilyGenes.Camila,
        image: img29,
    },
    {
        id: EnFamilyGenes.Violet,
        image: img30,
    },
    {
        id: EnFamilyGenes.Sophia,
        image: img31,
    },
    {
        id: EnFamilyGenes.Evelyn,
        image: img32,
    },
    {
        id: EnFamilyGenes.Nicole,
        image: img33,
    },
    {
        id: EnFamilyGenes.Ashley,
        image: img34,
    },
    {
        id: EnFamilyGenes.Grace,
        image: img35,
    },
    {
        id: EnFamilyGenes.Brianna,
        image: img36,
    },
    {
        id: EnFamilyGenes.Natalie,
        image: img37,
    },
    {
        id: EnFamilyGenes.Olivia,
        image: img38,
    },
    {
        id: EnFamilyGenes.Elizabeth,
        image: img39,
    },
    {
        id: EnFamilyGenes.Charlotte,
        image: img40,
    },
    {
        id: EnFamilyGenes.Emma,
        image: img41,
    },
    {
        id: EnFamilyGenes.Claude,
        image: img42,
    },
    {
        id: EnFamilyGenes.Niko,
        image: img43,
    },
    {
        id: EnFamilyGenes.John,
        image: img44,
    },
    {
        id: EnFamilyGenes.Misty,
        image: img45,
    },
] as const

export { FATHER_SLIDES, MOTHER_SLIDES, EnFamilyGenes }
