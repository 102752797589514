import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../..'

export const MAX_STEPS = 3 as const
type TCurrentStep = 1 | 2 | 3
export enum CharacterPersonalizationScreens {
    about,
    view,
}

interface IInitialState {
    currentStep: TCurrentStep
    activeScreen: CharacterPersonalizationScreens
    mainContentVisible: boolean
}
const initialState: IInitialState = {
    currentStep: 1,
    mainContentVisible: true,
    activeScreen: CharacterPersonalizationScreens.about,
}

const personalizationSlice = createSlice({
    name: 'personalizationSlice',
    initialState,
    reducers: {
        changeMainContentVisible(state, { payload }: PayloadAction<boolean>) {
            state.mainContentVisible = payload
        },
        nextStep(state) {
            const currentStep = state.currentStep

            if (currentStep < MAX_STEPS) {
                state.currentStep = (currentStep + 1) as TCurrentStep
                if (currentStep + 1 === 1) {
                    state.activeScreen = CharacterPersonalizationScreens.about
                } else if (currentStep + 1 === 2) {
                    state.activeScreen = CharacterPersonalizationScreens.view
                }
            }
        },
        prevStep(state) {
            const currentStep = state.currentStep

            if (currentStep > 0) {
                state.currentStep = (currentStep - 1) as TCurrentStep
                if (currentStep - 1 === 1) {
                    state.activeScreen = CharacterPersonalizationScreens.about
                } else if (currentStep - 1 === 2) {
                    state.activeScreen = CharacterPersonalizationScreens.view
                }
            }
        },
    },
})

export const currentStepSelector = (state: RootState) =>
    state.personalization.personalization.currentStep

export const { nextStep, prevStep, changeMainContentVisible } =
    personalizationSlice.actions
export default personalizationSlice.reducer
