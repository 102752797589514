import { VARIANTS } from '@/shared/constants/character/hairsVariants'
import { TCharacterVariantItem } from '@/widgets/CharacterVariantsWrapper/ui'

const VARIANTS_ITEMS = (
    handleVariantSelect: (id: number) => void,
    itemClassName?: string
) =>
    VARIANTS.map<TCharacterVariantItem<number>>((id) => ({
        content: <div className={itemClassName}>{id}</div>,
        id,
        onSelect: () => {
            handleVariantSelect(id)
        },
        isActive: false,
    }))

export { VARIANTS_ITEMS }
