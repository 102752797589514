import { useState } from 'react'
import { ProductCards, Props } from './TattooShop.typings'
import styles from './TattooShop.module.scss'
import classNames from 'classnames'
import ProductCard from '../Store/components/ProductCard/ProductCard'
import SortSelect from '@/shared/ui/SortSelect/SortSelect'

import { makeAPurchase } from './modules/makeAPurchase'
import { categories } from './TattooShop.config'

import lockIcon from '@/shared/assets/images/vectors/TattooShop/_/lockIcon.svg'
import checkIcon from '@/shared/assets/images/vectors/TattooShop/_/checkIcon.svg'

import SidebarCategories from '@/shared/ui/SidebarCategories/SidebarCategories'
import PaymentSelect from '../Store/components/PaymentSelect/PaymentSelect'
export enum SubCategories {
    main,
    special,
    delete,
}
export enum Customer {
    toMyself,
    toBusiness,
}

interface Sale {
    isSale: boolean
    oldPrice?: number
    saleText?: string
}
interface SelectedTattoo {
    id: string
    productPrice: number
    image: string
    description: string
    sale: Sale
    color?: string
}

const TattooShop = (props: Props) => {
    const [activeCategory, setActiveCategory] = useState<string>(
        props.categories[0].key
    )
    const [mainGoods, setMainGoods] = useState<ProductCards[]>(props.head)
    const [subCategory, setSubCategory] = useState<SubCategories>(
        SubCategories.main
    )
    const [isPaymentModalVisible, setIsPaymentModalVisible] =
        useState<boolean>(false)
    const [selectedTattoo, setSelectedTattoo] = useState<SelectedTattoo | null>(
        null
    )
    const subCategoriesButtons = [
        { category: SubCategories.main, title: 'Основные' },
        { category: SubCategories.special, title: 'Специальные' },
        { category: SubCategories.delete, title: 'Сведение тату' },
    ]

    const changeSubCategory = (subCategory: SubCategories) => {
        subCategory == SubCategories.delete &&
            setMainGoods(
                props[activeCategory].filter((item: any) => item.bought == true)
            )
        subCategory == SubCategories.special &&
            setMainGoods(
                props[activeCategory].filter((item: any) => item.special)
            )
        subCategory == SubCategories.main && setMainGoods(props[activeCategory])
        setSubCategory(subCategory)
    }

    const setSidebarButtonCheck = (category: string) => {
        setMainGoods(props[category])
        setActiveCategory(category)
    }

    const changeSortSelect = (key: string) => {
        const sortComplete: ProductCards[] = [...mainGoods]
        switch (key) {
            case 'fromLowerToUpper': {
                setMainGoods(sortComplete.sort((a, b) => a.price - b.price))
                break
            }
            case 'fromUpperToLower': {
                setMainGoods(sortComplete.sort((a, b) => b.price - a.price))
                break
            }
        }
    }

    const buyTattoo = (tattoo: SelectedTattoo) => {
        setSelectedTattoo(tattoo)
        setIsPaymentModalVisible(true)
    }

    const viewPaymentOptions = (value: boolean) => {
        setIsPaymentModalVisible(value)
    }

    const finishShopping = (
        customer: Customer,
        businessId: string,
        payWithCash: boolean
    ) => {
        if (selectedTattoo) {
            makeAPurchase(
                selectedTattoo.id,
                customer,
                businessId,
                payWithCash,
                selectedTattoo.productPrice
            )
        }
    }

    const buyButtonTitle = (bought: boolean | undefined) => {
        if (!bought) return 'Купить'
        else return 'Свести'
    }

    return (
        <div className={styles.container}>
            {isPaymentModalVisible ? (
                <PaymentSelect
                    business={[
                        { id: '1', title: 'Наркоторговля' },
                        { id: '2', title: 'Проституция' },
                    ]}
                    onClose={viewPaymentOptions}
                    total={selectedTattoo?.productPrice}
                    playerBalance={props.playerBalance}
                    onSelect={finishShopping}
                    storeType={props.storeType}
                />
            ) : null}

            <div className={styles.header}>
                <div className={styles.logo} />
            </div>

            <SidebarCategories
                storeType="tattooShop"
                activeCategory={activeCategory}
                onSelect={setSidebarButtonCheck}
                categories={categories}
            />

            <div className={styles.main}>
                <div className={styles.mainHeader}>
                    <div className={styles.subCategories}>
                        {subCategoriesButtons.map((button) => {
                            return (
                                <div
                                    key={button.category}
                                    className={classNames(
                                        styles.subCategorie,
                                        subCategory == button.category &&
                                            styles.subCategorieSelected
                                    )}
                                    onClick={() =>
                                        changeSubCategory(button.category)
                                    }
                                >
                                    {button.title}
                                </div>
                            )
                        })}
                    </div>
                    <SortSelect
                        storeType="tattooShop"
                        select={props.select}
                        onApplySelect={changeSortSelect}
                    />
                </div>
                <div className={styles.goodsWindow}>
                    {mainGoods?.map((item) => {
                        return (
                            <ProductCard
                                key={item.id}
                                id={item.id}
                                price={item.price}
                                image={item.image}
                                description={item.description}
                                sale={item.sale}
                                // @ts-expect-error: Конфликтующие стили которые противоречат друг другу
                                onSelect={buyTattoo}
                                value={0}
                                storeType={props.storeType}
                                unavailable={item.unavailable}
                                bought={item.bought}
                                icon={
                                    item.bought
                                        ? checkIcon
                                        : item.unavailable
                                        ? lockIcon
                                        : undefined
                                }
                                subCategory={subCategory}
                                buyButtonTitle={buyButtonTitle(item.bought)}
                            />
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default TattooShop
